import { EditFormProps, ValidationErrors, Validator } from '@monorepo/common/component/modal/EditModal';
import { EditRowLeftRight } from '@monorepo/common/component/modal/EditRowLeftRight';
import { integerNormalizer } from '@monorepo/common/util/Normalizer';
import { validateStringField } from '@monorepo/common/util/Validation';
import React from 'react';
import { Form } from 'reactstrap';

export const validate: Validator<EditFormData> = (data, touched) => {
  const errors: ValidationErrors<EditFormData> = {};

  validateStringField('ageFrom', 'Věková hranice od', data, errors, touched, {
    required: true,
    number: { range: { min: 0 } }
  });

  validateStringField('price', 'Cena', data, errors, touched, {
    required: true,
    number: { range: { min: 0 } }
  });

  return errors;
};

export interface EditFormData {
  ageFrom: number;
  price: number;
  disciplineConfigId: string;
}

export interface DisciplineConfigPriceNewProps extends EditFormProps<EditFormData> {}

export default function DisciplineConfigPriceNew({
  validation,
  onPropChange,
  onPropTouch,
  ...data
}: DisciplineConfigPriceNewProps) {
  return (
    <Form>
      <EditRowLeftRight
        label="Věková hranice od"
        placeholder="Zadejte věk od"
        labelProps={{ md: 5 }}
        dataColProps={{ md: 7 }}
        property="ageFrom"
        data={data}
        validation={validation}
        onPropTouch={onPropTouch}
        onPropChange={onPropChange}
      />

      {/* Cena */}
      <EditRowLeftRight
        label="Cena"
        placeholder="Zadejte cenu"
        labelProps={{ md: 5 }}
        dataColProps={{ md: 7 }}
        property="price"
        data={data}
        currency="Kč"
        normalizer={integerNormalizer}
        validation={validation}
        onPropTouch={onPropTouch}
        onPropChange={onPropChange}
      />
    </Form>
  );
}
