import React from "react";
import {
  FormFeedback,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import { InputType } from "reactstrap/es/Input";
import { PartialNull, Validation } from "../../type/Types";
import {
  identityNormalizer,
  identityNormalizer2,
  Normalizer,
  Normalizer2,
} from "../../util/Normalizer";
import { valueOrEmpty } from "../../util/Text";
import { PropChangedHandler, PropTouchedHandler } from "../modal/EditModal";

interface ValidatedInputGroupProps<T, K extends keyof T> {
  property: K;
  data: T;
  type?: InputType;
  placeholder?: string;
  currency?: string;
  alignRight?: boolean;
  normalizer?: Normalizer;
  normalizer2?: Normalizer2<T[K]>;
  disabled?: boolean;
  validation: Validation<T>;
  onPropTouch: PropTouchedHandler<T>;
  onPropChange: PropChangedHandler<T, K>;
}

export default function ValidatedInputGroup<
  T extends PartialNull<Record<K, string | number>>,
  K extends keyof T
>({
  property,
  data,
  type = "text",
  placeholder,
  currency,
  alignRight = false,
  normalizer = identityNormalizer,
  // @ts-ignore
  normalizer2 = identityNormalizer2,
  disabled = false,
  validation,
  onPropTouch,
  onPropChange,
}: ValidatedInputGroupProps<T, K>) {
  const error = validation.errors && validation.errors[property];
  const warning = validation.warnings && validation.warnings[property];

  const warningClass = warning && !error ? "invalid-as-warning" : "";

  return (
    <InputGroup>
      <Input
        id={`${property}`}
        type={type}
        value={valueOrEmpty(data[property])}
        disabled={disabled}
        placeholder={placeholder}
        invalid={!!error || !!warning}
        onChange={(e) => {
          if (normalizer2) {
            normalizer2(
              (value, transformed) => onPropChange(property, transformed),
              e.target.value
            );
          } else if (normalizer) {
            normalizer(
              // @ts-ignore
              (value) => onPropChange(property, value),
              e.target.value
            );
          }
        }}
        onBlur={() => onPropTouch(property)}
        className={[
          alignRight || currency ? "text-right" : "",
          warningClass,
        ].join(" ")}
      />

      {currency && (
        <InputGroupAddon addonType="append">
          <InputGroupText>{currency}</InputGroupText>
        </InputGroupAddon>
      )}
      {error && <FormFeedback>{error}</FormFeedback>}
      {warning && (
        <FormFeedback className="text-warning">{warning}</FormFeedback>
      )}
    </InputGroup>
  );
}
