import CommonButtonGroup from '@monorepo/common/component/form/CommonButtonGroup';
import { EditFormProps, Validator } from '@monorepo/common/component/modal/EditModal';
import PeriodLabel from '@monorepo/common/component/PeriodLabel';
import { validateAnyField, validateStringField } from '@monorepo/common/util/Validation';
import React from 'react';
import { Col, Form, FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import { FullPeriodFragment, SokolRoleEnum } from '../../graphql-operations';
import SokolRoleLabel, { roles } from './SokolRoleLabel';

export const validate: Validator<EditFormData> = (data, touched) => {
  const errors = {};

  validateAnyField('period', 'Období', data, errors, touched, { required: true });
  validateStringField('role', 'Role', data, errors, touched, {
    required: true,
    text: {
      oneOfTransformed: roles
    }
  });

  return errors;
};

export interface EditFormData {
  periods: FullPeriodFragment[];
  period: FullPeriodFragment;
  role: SokolRoleEnum;
}

interface SokolRoleDetailEditProps extends EditFormProps<EditFormData> {}

export default function SokolRoleDetailEdit({
  periods,
  period,
  role,
  validation,
  onPropTouch,
  onPropChange
}: SokolRoleDetailEditProps) {
  return (
    <Form>
      {/*Period*/}
      <FormGroup row>
        <Label sm={12} md={3}>
          Období
        </Label>
        <Col sm={12} md={9} className="text-md-right">
          <CommonButtonGroup
            items={periods}
            selectedItem={period}
            item2label={p => <PeriodLabel period={p} />}
            item2id={p => p.id}
            error={validation.errors.period}
            onChange={p => onPropChange('period', p)}
            onBlur={() => onPropTouch('period')}
          />
        </Col>
      </FormGroup>

      {/* role */}
      <FormGroup row>
        <Label for="role" sm={12} md={3} check>
          Role
        </Label>
        <Col sm={12} md={9}>
          {roles.map((r, idx) => (
            <FormGroup check key={r.value}>
              <Label check>
                <Input
                  type="radio"
                  name="role"
                  checked={!!role && r.value === role}
                  invalid={!!validation.errors.role}
                  onChange={e => onPropChange('role', r.value)}
                  onBlur={() => onPropTouch('role')}
                />
                <SokolRoleLabel role={r.value} />
                {idx === roles.length - 1 && validation.errors.role && (
                  <FormFeedback>{validation.errors.role}</FormFeedback>
                )}
              </Label>
            </FormGroup>
          ))}
        </Col>
      </FormGroup>
    </Form>
  );
}
