import React, { ReactNode } from "react";
import { Toast, ToastBody, ToastHeader } from "reactstrap";

export const NOTIFICATION_TYPE = {
  INFO: {
    bg: "bg-secondary",
    text: "text-white",
  },
  WARN: {
    bg: "bg-warning",
    text: "",
  },
  ERROR: {
    bg: "bg-danger",
    text: "text-white",
  },
};

export interface NotificationType extends PublicNotificationType {
  id: number;
  visible: boolean;
}

export interface PublicNotificationType {
  header: ReactNode;
  body: ReactNode;
  type: {
    bg: string;
    text: string;
  };
}

interface NotificationProps {
  notification: NotificationType;
  onClose: (notification: NotificationType) => void;
}

export default function Notification({
  notification,
  onClose,
}: NotificationProps) {
  return (
    <Toast isOpen={notification.visible} className={notification.type.bg}>
      <ToastHeader toggle={() => onClose(notification)}>
        {notification.header}
      </ToastHeader>
      <ToastBody className={notification.type.text}>
        {notification.body}
      </ToastBody>
    </Toast>
  );
}
