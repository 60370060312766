import React from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import DisciplineDetail from '../component/discipline/DisciplineDetail';
import DisciplineFilterableList from '../component/discipline/DisciplineFilterableList';

interface DisciplinesPageProps {
  match: RouteComponentProps;
}

export default function DisciplinesPage({ match: { match } }: DisciplinesPageProps) {
  return (
    <Container>
      <Row>
        <Col xs={3}>
          <div className="position-fixed-scrollable">
            <Route
              path={`${match.path}/:disciplineId?`}
              render={props => (
                <DisciplineFilterableList disciplineId={props.match.params.disciplineId} url={match.url} />
              )}
            />
          </div>
        </Col>

        <Col xs={9}>
          <Route path={`${match.path}/:disciplineId`} component={DisciplineDetail} />
          <Route exact path={match.path} component={DisciplineDetail} />
        </Col>
      </Row>
    </Container>
  );
}
