import AgeLabel from '@monorepo/common/component/AgeLabel';
import { AppConfigContext } from '@monorepo/common/component/AppConfigProvider';
import ClientNameLabel from '@monorepo/common/component/client/ClientNameLabel';
import DateLabel from '@monorepo/common/component/DateLabel';
import {
  IconRegularEdit,
  IconSolidPlus,
  IconSolidPrint,
  IconSolidTrashAlt,
  IconSolidUserPlus
} from '@monorepo/common/component/Icons';
import Secured, { ROLE_ADMIN_CLIENT_DELETE } from '@monorepo/common/component/Secured';
import SexLabel from '@monorepo/common/component/SexLabel';
import VerificationIcon from '@monorepo/common/component/VerificationIcon';
import React, { ReactNode, useContext } from 'react';
import { Col, ColProps, Container, CustomInput, Row } from 'reactstrap';
import { AccompanistFragment, OrderTotalStatusEnum, SokolRoleEnum, StampTypeEnum } from '../../graphql-operations';
import { getServerBasename } from '../../util/Url';
import ClientAccompanistList from '../accompanist/ClientAccompanistList';
import CovidAccountBalance from '../covid/CovidAccountBalance';
import InsuranceReceiptList from '../insuranceReceipt/InsuranceReceiptList';
import LegalRepresentationList, { LegalRepresentationType } from '../legalRepresentation/LegalRepresentationList';
import PaymentDisciplineForClient from '../payment/discipline/PaymentDisciplineForClient';
import PaymentStampList from '../payment/stamp/PaymentStampList';
import PaymentTokenList from '../payment/token/PaymentTokenList';
import PersonSwitchNameLink from '../person/switch/PersonSwitchNameLink';
import SokolRoleList from '../sokolRole/SokolRoleList';
import ClientDetailDeleteModal from './ClientDetailDeleteModal';
import ClientDetailEditModal from './ClientDetailEditModal';
import ClientGdprAgreementNewModal from './ClientGdprAgreementNewModal';

interface ClientRowProps {
  label: string;
  value?: ReactNode;
  children?: ReactNode;
}

function ClientRow({ label, value, children }: ClientRowProps) {
  return (
    <Row>
      <Col sm={12} md={5}>
        <span className="text-muted">{label}</span>
      </Col>
      <Col sm={12} md={7}>
        {children || value}
      </Col>
    </Row>
  );
}

export function NewClientIcon() {
  return (
    <ClientDetailEditModal title="Nový člen">
      {(handleModalOpen, title) => (
        <h2 className="text-right">
          <IconSolidUserPlus onClick={handleModalOpen} title={title} className="action-icon text-primary" />
        </h2>
      )}
    </ClientDetailEditModal>
  );
}

interface ModuleColProps extends ColProps {
  children: ReactNode;
}

/** Adds margin-top */
export function ModuleCol({ children, ...props }: ModuleColProps) {
  return (
    <Col {...props} className="mt-4">
      {children}
    </Col>
  );
}

export interface ClientType {
  __typename?: 'Client';
  id: string;
  personData: {
    __typename?: 'PersonData';
    id: string;
    degreeBefore?: string | null;
    degreeAfter?: string | null;
    firstName: string;
    lastName: string;
    sex?: string | null;
    phone?: number | null;
    phone2?: number | null;
    email?: string | null;
    note?: string | null;
    personalDataVerified: boolean;
    legalRepresentationsChilds: LegalRepresentationType[];
  };
  clientData: {
    __typename?: 'ClientData';
    id: string;
    birthNumber: string;
    age: number;
    street: string;
    streetNumberO?: string | null;
    streetNumberP?: string | null;
    streetNumberJoined: string;
    city: string;
    zip: number;
    mcp4: boolean;
    birthday: string;
    ccp?: string | null;
    cardId?: string | null;
    gdprAgreements: Array<{ __typename?: 'ClientGdprAgreement'; id: string; signDateTime: string }>;
    paymentsStamp: Array<{
      __typename?: 'PaymentStamp';
      id: string;
      year: number;
      type: StampTypeEnum;
      orderStamp: {
        __typename?: 'OrderStamp';
        id: string;
        priceDiscounted: number;
        orderTotal: { __typename?: 'OrderTotal'; id: string; orderNumber: string };
      };
    }>;
    paymentsDiscipline: Array<{
      __typename?: 'PaymentDiscipline';
      id: string;
      disciplineConfig: {
        __typename?: 'DisciplineConfig';
        id: string;
        discipline: { __typename?: 'Discipline'; id: string; name: string; insuranceReceiptPurpose?: string | null };
        period: { __typename?: 'Period'; id: string; year: number; number: number };
      };
      orderDiscipline: {
        __typename?: 'OrderDiscipline';
        id: string;
        priceDiscounted: number;
        orderTotal: { __typename?: 'OrderTotal'; id: string; orderNumber: string };
      };
      insuranceReceipt?: { __typename?: 'InsuranceReceipt'; id: string } | null;
    }>;
    paymentsToken: Array<{
      __typename?: 'PaymentToken';
      id: string;
      token: {
        __typename?: 'Token';
        id: string;
        name: string;
        period: { __typename?: 'Period'; id: string; year: number; number: number };
      };
      orderToken: {
        __typename?: 'OrderToken';
        id: string;
        price: number;
        orderTotal: {
          __typename?: 'OrderTotal';
          id: string;
          orderNumber: string;
        };
      };
    }>;
    sokolRoles: Array<{
      __typename?: 'SokolRole';
      id: string;
      role: SokolRoleEnum;
      period: { __typename?: 'Period'; id: string; year: number; number: number };
    }>;
    accompanists: AccompanistFragment[];
    accompanistChilds: AccompanistFragment[];
    insuranceReceipts: Array<{
      __typename?: 'InsuranceReceipt';
      id: string;
      receiptId: string;
      created: string;
      purpose: string;
      amount: number;
      period: { __typename?: 'Period'; id: string; year: number; number: number };
      paymentDiscipline?: {
        __typename?: 'PaymentDiscipline';
        id: string;
        disciplineConfig: {
          __typename?: 'DisciplineConfig';
          id: string;
          discipline: { __typename?: 'Discipline'; id: string; name: string };
          period: { __typename?: 'Period'; id: string; year: number; number: number };
        };
        orderDiscipline: {
          __typename?: 'OrderDiscipline';
          id: string;
          priceDiscounted: number;
          orderTotal: { __typename?: 'OrderTotal'; id: string; orderNumber: string };
        };
      } | null;
    }>;
    legalRepresentationsRepresentatives: LegalRepresentationType[];
    covidAccountBalanceInfo: {
      __typename?: 'CovidAccountBalanceInfo';
      covidAccountBalance: number;
      ordersDiscipline: Array<{
        __typename?: 'OrderDiscipline';
        id: string;
        priceOrig: number;
        priceDiscounted: number;
        covidAccount: number;
        orderTotal: { __typename?: 'OrderTotal'; id: string; orderNumber: string; status: OrderTotalStatusEnum };
        disciplineConfig: {
          __typename?: 'DisciplineConfig';
          id: string;
          period: { __typename?: 'Period'; id: string; year: number; number: number };
          discipline: { __typename?: 'Discipline'; id: string; name: string };
        };
      }>;
    };
  };
}

interface ClientDetailViewProps {
  client: ClientType;
}

export default function ClientDetailView({ client }: ClientDetailViewProps) {
  const { personData, clientData } = client;
  const { currentPeriod } = useContext(AppConfigContext);

  if (!currentPeriod) {
    return null;
  }

  return (
    <>
      <Container>
        {/* header with name and New icon */}
        <Row>
          <Col sm="auto">
            <ClientDetailEditModal key={client.id} title="Změna člena" client={client}>
              {(handleModalOpen, title) => (
                <h2>
                  <IconRegularEdit onClick={handleModalOpen} title={title} className="action-icon text-primary" />
                  <Secured authority={ROLE_ADMIN_CLIENT_DELETE}>
                    <ClientDetailDeleteModal key={client.id} client={client}>
                      {(handleModalOpen, title) => (
                        <IconSolidTrashAlt
                          onClick={handleModalOpen}
                          title={title}
                          className="action-icon text-danger"
                        />
                      )}
                    </ClientDetailDeleteModal>
                  </Secured>
                  <ClientNameLabel client={client} /> (<AgeLabel age={clientData.age} />){' '}
                  <VerificationIcon verified={personData.personalDataVerified} />
                </h2>
              )}
            </ClientDetailEditModal>
          </Col>
          <Col />
          <Col sm={2}>
            <NewClientIcon />
          </Col>
        </Row>

        {/* client info */}
        <Row>
          <Col sm={6}>
            <ClientRow label="Rodné číslo" value={clientData.birthNumber} />
            <ClientRow label="Ulice" value={`${clientData.street} ${clientData.streetNumberJoined}`} />
            <ClientRow label="Město" value={clientData.city} />
            <ClientRow label="PSČ" value={clientData.zip} />
            <ClientRow label="Občan MČP 4">
              <CustomInput id="mcp4" type="switch" disabled checked={clientData.mcp4} />
            </ClientRow>
            {/*@ts-ignore*/}
            <ClientRow label="Pohlaví">{personData.sex && <SexLabel sex={personData.sex} />}</ClientRow>
            <ClientRow label="Datum narození">
              <DateLabel dateString={clientData.birthday} />
            </ClientRow>
            <ClientRow label="Číslo cvičitelského průkazu" value={clientData.ccp} />
            <ClientRow label="Číslo členského průkazu" value={clientData.cardId} />
          </Col>
          <Col sm={6}>
            <ClientRow label="Telefon" value={personData.phone} />
            <ClientRow label="Telefon2" value={personData.phone2} />
            <ClientRow label="E-mail" value={personData.email} />
            <ClientRow label="Souhlasy GDPR">
              <>
                {clientData.gdprAgreements.map(gdprAgreement => (
                  <div key={gdprAgreement.id}>
                    <DateLabel dateString={gdprAgreement.signDateTime} />
                    &nbsp;
                    <a
                      href={getServerBasename() + `/rest/export/client/card-gdpr-adult/${gdprAgreement.id}`}
                      title="Tisk karty člena"
                      download
                    >
                      <IconSolidPrint />
                    </a>
                  </div>
                ))}
                {clientData.legalRepresentationsRepresentatives.map(legalRepresentation =>
                  legalRepresentation.gdprAgreements.map(gdprAgreement => (
                    <div key={gdprAgreement.id}>
                      <DateLabel dateString={gdprAgreement.signDateTime} />
                      &nbsp;
                      <a
                        href={
                          getServerBasename() + `/rest/export/client/card-gdpr-legal-representation/${gdprAgreement.id}`
                        }
                        title="Tisk karty člena"
                        download
                      >
                        <IconSolidPrint />
                      </a>{' '}
                      (<PersonSwitchNameLink person={legalRepresentation.representative} />)
                    </div>
                  ))
                )}
                {clientData.age >= 18 && (
                  <div>
                    <ClientGdprAgreementNewModal key={client.id} title="Udělení GDPR souhlasu" clientId={client.id}>
                      {(handleModalOpen, title) => (
                        <IconSolidPlus onClick={handleModalOpen} title={title} className="action-icon text-primary" />
                      )}
                    </ClientGdprAgreementNewModal>
                  </div>
                )}
              </>
            </ClientRow>
            <ClientRow label="Poznámka" value={personData.note} />
          </Col>
        </Row>

        {/* Modules */}
        <Row className="mb-3">
          <ModuleCol sm={12} md={8}>
            <PaymentDisciplineForClient
              key={client.id}
              clientId={client.id}
              paymentsDiscipline={clientData.paymentsDiscipline}
            />
          </ModuleCol>

          <ModuleCol sm={4}>
            <PaymentStampList paymentsStamp={clientData.paymentsStamp} clientId={client.id} />
          </ModuleCol>

          <ModuleCol sm={12}>
            <CovidAccountBalance key={client.id} covidAccountBalanceInfo={clientData.covidAccountBalanceInfo} />
          </ModuleCol>

          <ModuleCol sm={8}>
            <LegalRepresentationList
              child={clientData.age < 18 ? client : undefined}
              representative={clientData.age >= 18 ? client : undefined}
              legalRepresentationsChilds={personData.legalRepresentationsChilds}
              legalRepresentationsRepresentatives={clientData.legalRepresentationsRepresentatives}
            />
          </ModuleCol>

          <ModuleCol sm={4}>
            <SokolRoleList sokolRoles={clientData.sokolRoles} clientId={client.id} />
          </ModuleCol>

          <ModuleCol sm={6}>
            <ClientAccompanistList
              accompanists={clientData.accompanists}
              accompanistChilds={clientData.accompanistChilds}
              clientId={client.id}
              allowNew={clientData.age < 17}
            />
          </ModuleCol>

          <ModuleCol sm={6}>
            <InsuranceReceiptList clientId={client.id} insuranceReceipts={clientData.insuranceReceipts} />
          </ModuleCol>

          <ModuleCol sm={6}>
            <PaymentTokenList paymentsToken={clientData.paymentsToken} clientId={client.id} />
          </ModuleCol>
        </Row>
      </Container>
    </>
  );
}
