import React from "react";

interface CenteredHVProps {
  children: React.ReactNode;
}

/**
 * Centers content both horizontally and vertically.
 */
export default function CenteredHV({ children }: CenteredHVProps) {
  return (
    <div className="d-flex align-items-center h-100">
      <span className="mx-auto text-muted">{children}</span>
    </div>
  );
}
