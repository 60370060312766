import ClientNameLabel from '@monorepo/common/component/client/ClientNameLabel';
import DeleteModal from '@monorepo/common/component/modal/DeleteModal';
import { ChildrenRenderProp } from '@monorepo/common/component/modal/EditModal';
import PersonNameLabel from '@monorepo/common/component/person/PersonNameLabel';
import React, { useCallback } from 'react';
import { ClientDocument, DeleteLegalRepresentationDocument, PersonDocument } from '../../graphql-operations';
import { LegalRepresentationType } from './LegalRepresentationList';

function data2text(legalRepresentation: LegalRepresentationType) {
  return (
    <>
      <div>
        Dítě: <ClientNameLabel client={legalRepresentation.child} />
      </div>
      <div>
        Zákonný zástupce: <PersonNameLabel person={legalRepresentation.representative} />
      </div>
      <div>Role: {legalRepresentation.role}</div>
    </>
  );
}

function data2variables(legalRepresentation: LegalRepresentationType) {
  return {
    id: legalRepresentation.id
  };
}

interface LegalRepresentationDetailDeleteModalProps {
  legalRepresentation: LegalRepresentationType;
  children: ChildrenRenderProp;
}

export default function LegalRepresentationDetailDeleteModal({
  legalRepresentation,
  children
}: LegalRepresentationDetailDeleteModalProps) {
  const refetchQueries = useCallback(() => {
    return [
      {
        query: ClientDocument,
        variables: { id: legalRepresentation.child.id }
      },
      {
        query: PersonDocument,
        variables: { id: legalRepresentation.representative.id }
      }
    ];
  }, [legalRepresentation.child.id, legalRepresentation.representative.id]);

  return (
    <DeleteModal
      title="Smazání vazby zákonného zástupce"
      data={legalRepresentation}
      data2text={data2text}
      data2variables={data2variables}
      mutation={DeleteLegalRepresentationDocument}
      refetchQueries={refetchQueries}
      children={children}
    />
  );
}
