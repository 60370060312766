import React from 'react';
import Header from './Header';

interface WithHeaderProps {
  children: React.ReactNode;
}

export default function WithHeader({ children }: WithHeaderProps) {
  return (
    <>
      <Header />
      <div className="main">{children}</div>
    </>
  );
}
