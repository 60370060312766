import NoData from '@monorepo/common/component/NoData';
import QueryWithLoader from '@monorepo/common/component/QueryWithLoader';
import React from 'react';
import { match, Redirect } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import { PersonDocument } from '../../../graphql-operations';
import ClientDetailView, { ClientType } from '../../client/ClientDetailView';
import PersonDetailView, { NewPersonIcon, PersonType } from '../PersonDetailView';

export type PersonSwitch = ClientType | PersonType;

interface PersonDetailUrlParams {
  personId?: string;
}

interface PersonDetailProps {
  match: match<PersonDetailUrlParams>;
}

export default function PersonSwitchDetail({ match }: PersonDetailProps) {
  const { personId } = match.params;
  return (
    <>
      {!!personId && (
        <QueryWithLoader query={PersonDocument} variables={{ id: personId }}>
          {({ data }) => {
            const person = data.person;

            if (!person) {
              return <Redirect to="/page-not-found" />;
            }

            if (person.clientData) {
              const personAsClient: ClientType = {
                ...person,
                clientData: person.clientData,
                __typename: 'Client'
              };

              return <ClientDetailView client={personAsClient} />;
            }

            return <PersonDetailView person={person} />;
          }}
        </QueryWithLoader>
      )}

      {!personId && (
        <>
          <Container>
            <Row>
              <Col sm={12}>
                <NewPersonIcon />
              </Col>
            </Row>
          </Container>
          <NoData text="Vyberte osobu" />
        </>
      )}
    </>
  );
}
