import { IconSolidTrashAlt } from '@monorepo/common/component/Icons';
import { NoDataWithMarginBottom } from '@monorepo/common/component/NoData';
import React from 'react';
import { Button, Card, CardBody, CardHeader, Table } from 'reactstrap';
import { AccompanistFragment } from '../../graphql-operations';
import ClientNameLink from '../client/ClientNameLink';
import AccompanistNameLabel from './AccompanistNameLabel';
import ClientAccompanistDetailDeleteModal from './ClientAccompanistDetailDeleteModal';
import ClientAccompanistDetailNewModal from './ClientAccompanistDetailNewModal';

interface ClientAccompanistRowsProps {
  showChild: boolean;
  showAccompanist: boolean;
  accompanists: AccompanistFragment[];
}

function ClientAccompanistRows({ showChild, showAccompanist, accompanists }: ClientAccompanistRowsProps) {
  if (!accompanists.length) {
    return null;
  }
  return (
    <>
      <thead>
        <tr>
          {showChild && <th>Dítě</th>}
          {showAccompanist && <th>Doprovod</th>}
          <th>Role</th>
          <th>Akce</th>
        </tr>
      </thead>
      <tbody>
        {accompanists.map(accompanist => (
          <tr key={accompanist.id}>
            {showChild && (
              <td>
                <ClientNameLink client={accompanist.child} />
              </td>
            )}

            {showAccompanist && (
              <td>
                <AccompanistNameLabel accompanist={accompanist} link={true} />
              </td>
            )}

            <td>{accompanist.role}</td>
            <td>
              <ClientAccompanistDetailDeleteModal accompanist={accompanist}>
                {(handleModalOpen, title) => (
                  <IconSolidTrashAlt onClick={handleModalOpen} title={title} className="action-icon text-danger" />
                )}
              </ClientAccompanistDetailDeleteModal>
            </td>
          </tr>
        ))}
      </tbody>
    </>
  );
}

interface ClientAccompanistListProps {
  accompanists: AccompanistFragment[];
  accompanistChilds: AccompanistFragment[];
  clientId: string;
  allowNew: boolean;
}

export default function ClientAccompanistList({
  accompanists,
  accompanistChilds,
  clientId,
  allowNew
}: ClientAccompanistListProps) {
  const totalRowsCount = accompanists.length + accompanistChilds.length;

  return (
    <Card>
      <CardHeader tag="h4" className="text-center">
        Doprovod dítěte
      </CardHeader>
      <CardBody>
        {totalRowsCount > 0 && (
          <Table size="sm" striped>
            <ClientAccompanistRows showChild={false} showAccompanist={true} accompanists={accompanists} />
            <ClientAccompanistRows showChild={true} showAccompanist={false} accompanists={accompanistChilds} />
          </Table>
        )}

        {totalRowsCount === 0 && <NoDataWithMarginBottom text="Žádné doprovody dítěte" />}

        {allowNew && (
          <ClientAccompanistDetailNewModal key={clientId} title="Přidání doprovodu dítěte" clientId={clientId}>
            {(handleModalOpen, title) => (
              <Button block size="sm" onClick={handleModalOpen} title={title}>
                Přidat
              </Button>
            )}
          </ClientAccompanistDetailNewModal>
        )}
      </CardBody>
    </Card>
  );
}
