import React, { MouseEventHandler, ReactNode } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

interface StatelessConfirmModalProps {
  isOpen: boolean;
  title: string;
  size?: string;
  cancelButtonText?: string;
  confirmButtonText?: string;
  confirmButtonColor?: string;
  onConfirmClick: MouseEventHandler;
  onCancelClick: MouseEventHandler;
  children: ReactNode;
}

export default function StatelessConfirmModal({
  isOpen,
  title,
  size = "sm",
  cancelButtonText = "Zrušit",
  confirmButtonColor = "primary",
  confirmButtonText = "Potvrdit",
  onConfirmClick,
  onCancelClick,
  children,
}: StatelessConfirmModalProps) {
  return (
    <>
      <Modal isOpen={isOpen} size={size} toggle={onCancelClick} centered>
        <ModalHeader toggle={onCancelClick}>{title}</ModalHeader>
        <ModalBody>{children}</ModalBody>
        <ModalFooter>
          <Button onClick={onCancelClick} className="mr-auto">
            {cancelButtonText}
          </Button>
          <Button onClick={onConfirmClick} color={confirmButtonColor}>
            {confirmButtonText}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}
