import {
  ApolloClient,
  MutationFunction,
  useApolloClient,
  useMutation,
} from "@apollo/client";
import { InternalRefetchQueriesInclude } from "@apollo/client/core";
import { DocumentNode } from "graphql";
import React, {
  EventHandler,
  ReactNode,
  SyntheticEvent,
  useCallback,
} from "react";
import ConfirmModal from "./ConfirmModal";
import { ChildrenRenderProp } from "./EditModal";

interface DeleteModalProps<T, MutVariables, MutResult> {
  title: string;
  size?: string;
  data: T;
  data2text: (data: T) => ReactNode;
  data2variables: (data: T) => MutVariables;
  mutation: DocumentNode;
  refetchQueries?: (data: T) => InternalRefetchQueriesInclude;
  onCompleted?: (
    data: T,
    mutationResult: MutResult,
    close: EventHandler<SyntheticEvent>,
    apolloClient: ApolloClient<any>
  ) => void;
  children: ChildrenRenderProp;
}

export default function DeleteModal<T, MutVariables, MutResult>({
  title,
  size,
  data,
  data2text,
  data2variables,
  mutation,
  refetchQueries,
  onCompleted,
  children,
}: DeleteModalProps<T, MutVariables, MutResult | null | undefined>) {
  const apolloClient = useApolloClient();

  const handleCompleted = useCallback(
    (
      data: T,
      mutationResult: MutResult | null | undefined,
      close: () => void
    ) => {
      if (onCompleted) {
        onCompleted(data, mutationResult, close, apolloClient);
        return;
      }
      close();
    },
    [onCompleted, apolloClient]
  );

  const handleDeleteClick = useCallback(
    (
      del: MutationFunction<MutResult, MutVariables>,
      data: T,
      close: () => void
    ) => {
      del({
        variables: {
          ...data2variables(data),
        },
      }).then((result) => handleCompleted(data, result.data, close));
    },
    [data2variables, handleCompleted]
  );

  const [del] = useMutation<MutResult, MutVariables>(mutation, {
    refetchQueries: refetchQueries ? () => refetchQueries(data) : undefined,
  });

  const handleConfirm = useCallback(
    (close: () => void) => handleDeleteClick(del, data, close),
    [del, data]
  );

  return (
    <ConfirmModal
      title={title}
      cancelButtonText="Zrušit"
      confirmButtonText="Smazat"
      size={size}
      children={children}
      body={() => (
        <>
          <span>Opravdu smazat?</span>
          {data2text(data)}
        </>
      )}
      onConfirm={handleConfirm}
    />
  );
}
