import EditModal, { BaseEditModalProps, VariablesExtractor } from '@monorepo/common/component/modal/EditModal';
import { failOnMissing } from '@monorepo/common/util/Validation';
import React, { useCallback, useMemo } from 'react';
import {
  AddClientGdprAgreementDocument,
  AddClientGdprAgreementMutationVariables,
  ClientDocument
} from '../../graphql-operations';
import GdprAgreementNew, { EditFormData, validate } from '../gdpr/GdprAgreementNew';

interface ClientGdprAgreementNewModalProps extends BaseEditModalProps {
  title: string;
  clientId: string;
}

export default function ClientGdprAgreementNewModal({ clientId, title, children }: ClientGdprAgreementNewModalProps) {
  const refetchQueries = useCallback(() => {
    return [
      {
        query: ClientDocument,
        variables: { id: clientId }
      }
    ];
  }, [clientId]);

  const data2variables: VariablesExtractor<EditFormData, AddClientGdprAgreementMutationVariables> = data => {
    return {
      clientId: failOnMissing(data, 'clientId'),
      gdprSignDate: failOnMissing(data, 'gdprSignDate')
    };
  };

  const initialData = useMemo<Partial<EditFormData>>(
    () => ({
      clientId
    }),
    [clientId]
  );

  return (
    <EditModal
      title={title}
      tag={GdprAgreementNew}
      initialData={initialData}
      validate={validate}
      data2variables={data2variables}
      mutation={AddClientGdprAgreementDocument}
      refetchQueries={refetchQueries}
      children={children}
    />
  );
}
