import CommonHeader from '@monorepo/common/layout/CommonHeader';
import React from 'react';

export default function Header() {
  const menuItems = [
    { url: '/clients', label: 'Členové' },
    { url: '/persons', label: 'Všichni' },
    { url: '/disciplines', label: 'Oddíly' },
    { url: '/orders', label: 'Objednávky' },
    { url: '/tokens', label: 'Známky' },
    { url: '/stats', label: 'Statistiky a exporty' },
    { url: '/csob-cross-check-report', label: 'Křížová kontrola transakcí' },
    { url: '/app-config', label: 'Nastavení' }
  ];
  return <CommonHeader menuItems={menuItems} />;
}
