import { useMutation } from '@apollo/client';
import { NotificationContext, NOTIFICATION_TYPE } from '@monorepo/common/component/notification';
import React, { useContext } from 'react';
import { Col, Container, Row } from 'reactstrap';
import CsobCrossCheckReportUploadForm from '../component/csobCrossCheckReport/CsobCrossCheckReportUploadForm';
import CsobCrossCheckReportView from '../component/csobCrossCheckReport/CsobCrossCheckReportView';
import { CsobCrossCheckReportDocument, UploadCsobCrossCheckReportDocument } from '../graphql-operations';

function refetchQueries() {
  return [
    {
      query: CsobCrossCheckReportDocument
    }
  ];
}

export default function CsobCrossCheckReportPage() {
  const [uploadCsobCrossCheckReport] = useMutation(UploadCsobCrossCheckReportDocument, { refetchQueries });
  const { showNotification } = useContext(NotificationContext);

  function uploadFile({ file }: { file: string }) {
    return uploadCsobCrossCheckReport({
      variables: {
        file: file
      }
    }).catch(() =>
      showNotification({
        type: NOTIFICATION_TYPE.ERROR,
        header: 'Chyba',
        body: 'Při nahrávání souboru došlo k neznámé chybě'
      })
    );
  }

  return (
    <Container>
      <Row>
        <Col>
          <CsobCrossCheckReportUploadForm onSubmit={uploadFile} />
        </Col>
      </Row>
      <Row>
        <Col>
          <CsobCrossCheckReportView />
        </Col>
      </Row>
    </Container>
  );
}
