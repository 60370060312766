import DateLabel from '@monorepo/common/component/DateLabel';
import { IconRegularEdit, IconSolidPlus } from '@monorepo/common/component/Icons';
import MoneyLabel from '@monorepo/common/component/MoneyLabel';
import NoData from '@monorepo/common/component/NoData';
import PeriodLabel from '@monorepo/common/component/PeriodLabel';
import React from 'react';
import { NavLink as RouterNavLink, Route, useRouteMatch } from 'react-router-dom';
import { Card, CardBody, Col, Nav, NavItem, NavLink, Row } from 'reactstrap';
import PeriodAppConfigDetailEditModal from './PeriodAppConfigDetailEditModal';

interface PeriodAppConfigListProps {
  periodAppConfigs: {
    id: string;
    disciplinePaymentScheduleRecurringDate: string;
    disciplinePaymentScheduleOtherDate: string;
    disciplinePaymentScheduleSignUpDate: string;
    disciplinePaymentScheduleEndDate: string;
    stampPriceChild: number;
    stampPriceAdult: number;
    stampPriceSenior: number;
    stampPaymentEndDate: string;
    period: {
      id: string;
      year: number;
      number: number;
    };
    tokenPaymentEndDate: string;
  }[];
}

export default function PeriodAppConfigList({ periodAppConfigs }: PeriodAppConfigListProps) {
  const { url, path } = useRouteMatch();

  return (
    <Route path={`${path}/:periodId?`}>
      {({ match }) => {
        const periodId = match?.params.periodId;

        let selectedPac = periodAppConfigs.find(pac => pac.period.id === periodId);
        if (!selectedPac && periodAppConfigs.length) {
          selectedPac = periodAppConfigs[0];
        }

        return (
          <>
            <Nav tabs>
              {periodAppConfigs.map(pac => (
                <NavItem key={pac.period.id}>
                  <NavLink tag={RouterNavLink} active={pac === selectedPac} to={`${url}/${pac.period.id}`}>
                    <PeriodLabel period={pac.period} />
                  </NavLink>
                </NavItem>
              ))}
              <PeriodAppConfigDetailEditModal title="Nové nastavení aplikace">
                {(handleModalOpen, title) => (
                  <NavItem key={-1}>
                    <NavLink active={false} onClick={handleModalOpen}>
                      <span>
                        <IconSolidPlus title={title} className="action-icon text-primary" />
                      </span>
                    </NavLink>
                  </NavItem>
                )}
              </PeriodAppConfigDetailEditModal>
            </Nav>
            <Card>
              <CardBody>
                {selectedPac ? (
                  <>
                    <PeriodAppConfigDetailEditModal
                      key={selectedPac.id}
                      title="Změna nastavení aplikace"
                      periodAppConfig={selectedPac}
                    >
                      {(handleModalOpen, title) => (
                        <h4>
                          <IconRegularEdit
                            onClick={handleModalOpen}
                            title={title}
                            className="action-icon text-primary"
                          />
                          Nastavení aplikace
                        </h4>
                      )}
                    </PeriodAppConfigDetailEditModal>

                    {/* PeriodAppConfig info */}
                    {/*Discipline config offer waves*/}
                    <Row>
                      <Col sm={12}>
                        <h5>Oddíly</h5>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={12} className="text-muted">
                        Datum spuštění 1. vlny (členové z předchozího období)
                      </Col>
                      <Col sm={12}>
                        <DateLabel dateString={selectedPac.disciplinePaymentScheduleRecurringDate} />
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={12} className="text-muted">
                        Datum spuštění 2. vlny (ostatní členové)
                      </Col>
                      <Col sm={12}>
                        <DateLabel dateString={selectedPac.disciplinePaymentScheduleOtherDate} />
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={12} className="text-muted">
                        Datum spuštění 3. vlny (online registrace)
                      </Col>
                      <Col sm={12}>
                        <DateLabel dateString={selectedPac.disciplinePaymentScheduleSignUpDate} />
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={12} className="text-muted">
                        Datum ukončení online plateb (ke konci tohoto dne)
                      </Col>
                      <Col sm={12}>
                        <DateLabel dateString={selectedPac.disciplinePaymentScheduleEndDate} />
                      </Col>
                    </Row>

                    {/*Stamp prices*/}
                    <Row className="mt-3">
                      <Col sm={12}>
                        <h5>Ceny známek</h5>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={6} className="text-muted">
                        Děti (do 18 let)
                      </Col>
                      <Col sm={6} className="text-md-right">
                        <MoneyLabel amount={selectedPac.stampPriceChild} />
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={6} className="text-muted">
                        Dospělí (18 - 65 let)
                      </Col>
                      <Col sm={6} className="text-md-right">
                        <MoneyLabel amount={selectedPac.stampPriceAdult} />
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={6} className="text-muted">
                        Senioři (od 65 let)
                      </Col>
                      <Col sm={6} className="text-md-right">
                        <MoneyLabel amount={selectedPac.stampPriceSenior} />
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={12} className="text-muted">
                        Datum ukončení online plateb (ke konci tohoto dne)
                      </Col>
                      <Col sm={12}>
                        <DateLabel dateString={selectedPac.stampPaymentEndDate} />
                      </Col>
                    </Row>

                    {/* Tokens */}
                    <Row className="mt-3">
                      <Col sm={12}>
                        <h5>Mimořádné známky</h5>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={12} className="text-muted">
                        Datum ukončení online plateb (ke konci tohoto dne)
                      </Col>
                      <Col sm={12}>
                        <DateLabel dateString={selectedPac.tokenPaymentEndDate} />
                      </Col>
                    </Row>
                  </>
                ) : (
                  <>
                    <NoData text="Vyberte období" />
                  </>
                )}
              </CardBody>
            </Card>
          </>
        );
      }}
    </Route>
  );
}
