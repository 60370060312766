import HorizontalSeparator from '@monorepo/common/component/HorizontalSeparator';
import { EditFormProps, Validator } from '@monorepo/common/component/modal/EditModal';
import { EditRow } from '@monorepo/common/component/modal/EditRow';
import { validateAnyField, validateStringField } from '@monorepo/common/util/Validation';
import React from 'react';
import { Col, Form, FormFeedback, FormGroup, Input, Label, Row } from 'reactstrap';
import ClientAutocompleteInput, { ClientType } from '../client/ClientAutocompleteInput';

export const validate: Validator<EditFormData> = (data, touched) => {
  const errors = {};

  if (!data.accompanist) {
    if (!data.firstName && !data.lastName) {
      validateAnyField('accompanist', 'Z databáze', data, errors, touched, { required: true });
    }
    validateStringField('firstName', 'Jméno', data, errors, touched, { required: true, text: { length: { max: 50 } } });
    validateStringField('lastName', 'Příjmení', data, errors, touched, {
      required: true,
      text: { length: { max: 50 } }
    });
  }

  validateStringField('role', 'Role', data, errors, touched, { required: true, text: { length: { max: 50 } } });

  return errors;
};

export interface EditFormData {
  childId: string;
  accompanist: ClientType;
  firstName: string;
  lastName: string;
  role: string;
}

interface ClientAccompanistDetailNewProps extends EditFormProps<EditFormData> {}

export default function ClientAccompanistDetailNew({
  validation,
  onPropChange,
  onPropTouch,
  ...accompanist
}: ClientAccompanistDetailNewProps) {
  return (
    <Form>
      {/* accompanist client */}
      <FormGroup row>
        <Label sm={12}>Z databáze</Label>
        <Col sm={12}>
          <ClientAutocompleteInput
            selected={accompanist.accompanist}
            disabled={!!accompanist.firstName || !!accompanist.lastName}
            onSelect={a => onPropChange('accompanist', a)}
            onBlur={() => onPropTouch('accompanist')}
          />
          <Input type="hidden" invalid={!!validation.errors.accompanist} />
          {validation.errors.accompanist && <FormFeedback>{validation.errors.accompanist}</FormFeedback>}
        </Col>
      </FormGroup>

      <HorizontalSeparator text="Nebo ručně" />

      {/* accompanist firstName + lastName */}
      <Row>
        <Col sm={6}>
          <EditRow
            label="Jméno"
            property="firstName"
            data={accompanist}
            disabled={!!accompanist.accompanist}
            validation={validation}
            onPropTouch={onPropTouch}
            onPropChange={onPropChange}
          />
        </Col>
        <Col sm={6}>
          <EditRow
            label="Příjmení"
            property="lastName"
            data={accompanist}
            disabled={!!accompanist.accompanist}
            validation={validation}
            onPropTouch={onPropTouch}
            onPropChange={onPropChange}
          />
        </Col>
      </Row>

      {/* role */}
      <EditRow
        label="Role"
        property="role"
        data={accompanist}
        validation={validation}
        onPropTouch={onPropTouch}
        onPropChange={onPropChange}
      />
    </Form>
  );
}
