import React, { ReactNode, useCallback, useContext, useState } from "react";
import { NavLink } from "react-router-dom";
import { Button, Col, Form, FormGroup, Input, Label } from "reactstrap";
import { AuthenticationContext } from "../component/AuthenticationContext";
import LoginLayout from "../layout/LoginLayout";
import { preventedDefault } from "../util/Input";
import { valueOrEmpty } from "../util/Text";

const INITIAL_DATA =
  process.env.NODE_ENV !== "production"
    ? {
        username: "user@fakemail.cz",
        password: "password",
      }
    : {
        username: "",
        password: "",
      };
interface LoginPageProps {
  extensionRow?: () => ReactNode;
}

export default function LoginPage({ extensionRow }: LoginPageProps) {
  const [username, setUsername] = useState(INITIAL_DATA.username);
  const [password, setPassword] = useState(INITIAL_DATA.password);
  const [error, setError] = useState<boolean | undefined>(undefined);

  const { login, maintenance } = useContext(AuthenticationContext);

  const redirect = useCallback(() => {
    // TODO redirect after login
    // https://reacttraining.com/react-router/web/example/auth-workflow
  }, []);

  const handleWrongCredentials = useCallback(
    (response) => {
      if (response.status === 502) {
        maintenance();
      } else {
        setError(true);
      }
    },
    [setError, maintenance]
  );

  const handleLogin = useCallback(() => {
    setError(false);
    login({ username, password }).then(redirect).catch(handleWrongCredentials);
  }, [setError, login, username, password, redirect, handleWrongCredentials]);

  return (
    <LoginLayout>
      <Form onSubmit={preventedDefault(handleLogin)}>
        {error && (
          <span className="text-danger">Špatné přihlašovací údaje</span>
        )}
        <FormGroup>
          <Label for="username">Email</Label>
          <Input
            type="text"
            name="username"
            id="username"
            value={valueOrEmpty(username)}
            onChange={(e) => setUsername(e.target.value)}
          />
        </FormGroup>
        <FormGroup>
          <Label for="password">Heslo</Label>
          <Input
            type="password"
            name="password"
            id="password"
            value={valueOrEmpty(password)}
            onChange={(e) => setPassword(e.target.value)}
          />
        </FormGroup>
        <FormGroup row>
          <Col xs="auto">
            <Button type="submit" color="primary">
              Přihlásit
            </Button>
          </Col>
          <Col className="text-right">
            <NavLink to={`/forgotten-password/${username}`}>
              Zapomenuté heslo
            </NavLink>
          </Col>
        </FormGroup>
        {extensionRow && extensionRow()}
      </Form>
    </LoginLayout>
  );
}
