import ConfirmModal, { ConfirmModalProps } from '@monorepo/common/component/modal/ConfirmModal';
import React from 'react';

interface StampOfferExternalConfirmModalProps extends Pick<ConfirmModalProps, 'onConfirm' | 'children'> {}

export default function StampOfferExternalConfirmModal({ onConfirm, children }: StampOfferExternalConfirmModalProps) {
  return (
    <ConfirmModal
      title="Hostující známka"
      size="md"
      cancelButtonText="Zrušit"
      confirmButtonText="Přidat"
      confirmButtonColor="primary"
      children={children}
      body={() => <span>Opravdu přidat hostující známku zadarmo?</span>}
      onConfirm={onConfirm}
    />
  );
}
