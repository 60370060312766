import DateLabel from '@monorepo/common/component/DateLabel';
import DateTimeLabel from '@monorepo/common/component/DateTimeLabel';
import { IconSolidFileDownload } from '@monorepo/common/component/Icons';
import MoneyLabel, { DecimalMoneyLabel } from '@monorepo/common/component/MoneyLabel';
import NoData from '@monorepo/common/component/NoData';
import OrderLink from '@monorepo/common/component/order/OrderLink';
import QueryWithLoader from '@monorepo/common/component/QueryWithLoader';
import React, { ReactNode } from 'react';
import { Col, Container, Row, Table } from 'reactstrap';
import { CsobCrossCheckReportDocument, OrderTotalStatusEnum } from '../../graphql-operations';
import { getServerBasename } from '../../util/Url';
import { textColorForStatus } from '../order/OrderDetailView';

interface CsobTransaction {
  __typename?: 'CsobReportTransaction';
  authCode: string;
  dateTime: string;
  variableSymbol: string;
  cardNumber: string;
  amountBrutto: number;
  fee: number;
  amountNetto: number;
}

interface OrderTotal {
  __typename?: 'OrderTotal';
  id: string;
  orderNumber: string;
  status: OrderTotalStatusEnum;
  totalPriceDiscounted: number;
}

interface Transaction {
  __typename?: 'CsobCrossCheckTransaction';
  orderTotal?: OrderTotal | null;
  csobReportTransaction: CsobTransaction;
}

interface OrderStatusProps {
  status: OrderTotalStatusEnum;
}

function OrderStatus({ status }: OrderStatusProps) {
  const color = textColorForStatus(status);

  switch (status) {
    case 'UNPAID':
      return <span className={color}>Nezaplacena</span>;
    case 'PAYING':
      return <span className={color}>Právě platí</span>;
    case 'PAID':
      return <span className={color}>Zaplacena</span>;
    case 'REFUNDING':
      return <span className={color}>Ve stornu</span>;
    case 'REFUNDED':
      return <span className={color}>Stornována</span>;
    case 'EXPIRED':
      return <span className={color}>Vypršel čas</span>;
    default:
      return null;
  }
}

interface ReportTableRowProps {
  index: number;
  orderTotal?: OrderTotal | null;
  csobReportTransaction: CsobTransaction;
}

function ReportTableRow({ index, orderTotal, csobReportTransaction }: ReportTableRowProps) {
  const { authCode, dateTime, variableSymbol, cardNumber, amountBrutto, fee, amountNetto } = csobReportTransaction;

  const error = !orderTotal || orderTotal.totalPriceDiscounted !== amountBrutto || orderTotal.status !== 'PAID';

  return (
    <tr className={error ? 'table-danger' : ''}>
      <th scope="row">{index + 1}</th>
      <td>{authCode}</td>
      <td>
        <DateTimeLabel dateTimeString={dateTime} />
      </td>
      <td>{variableSymbol}</td>
      <td>{cardNumber}</td>
      <td className="text-right">
        <DecimalMoneyLabel amount={amountBrutto} />
      </td>
      <td className="text-right">
        <DecimalMoneyLabel amount={fee} />
      </td>
      <td className="text-right">
        <DecimalMoneyLabel amount={amountNetto} />
      </td>
      <td>{orderTotal && <OrderStatus status={orderTotal.status} />}</td>
      <td className="text-right">
        {orderTotal && (
          <OrderLink orderNumber={orderTotal.orderNumber}>
            <MoneyLabel amount={orderTotal.totalPriceDiscounted} />
          </OrderLink>
        )}
      </td>
    </tr>
  );
}

interface ReportTableProps {
  transactions: Transaction[];
  summary: {
    __typename?: 'CsobReportSummary';
    count: number;
    amountBruttoSum: number;
    feeSum: number;
    amountNettoSum: number;
  };
}

function ReportTable({ transactions, summary }: ReportTableProps) {
  return (
    <Table size="sm" striped hover>
      <thead>
        <tr>
          <th>#</th>
          <th>Autorizační kód</th>
          <th>Datum a čas transakce</th>
          <th>Variabilní symbol</th>
          <th>Číslo karty</th>
          <th>Hrubá částka transakce</th>
          <th>Smluvní poplatek</th>
          <th>Čistá částka transakce</th>
          <th>Status objednávky</th>
          <th>Částka objednávky</th>
        </tr>
      </thead>
      <tbody>
        {transactions.map((tx, idx) => (
          <ReportTableRow
            key={tx.csobReportTransaction.authCode}
            index={idx}
            orderTotal={tx.orderTotal}
            csobReportTransaction={tx.csobReportTransaction}
          />
        ))}
      </tbody>
      <tfoot>
        <tr>
          <th colSpan={4}>Celkem</th>
          <th>Počet: {summary.count}</th>
          <th className="text-right">
            <MoneyLabel amount={summary.amountBruttoSum} />
          </th>
          <th className="text-right">
            <MoneyLabel amount={summary.feeSum} />
          </th>
          <th className="text-right">
            <MoneyLabel amount={summary.amountNettoSum} />
          </th>
          <th colSpan={2} />
        </tr>
      </tfoot>
    </Table>
  );
}

interface ReportInfoRowProps {
  label: string;
  value?: ReactNode;
  children?: ReactNode;
}

const ReportInfoRow = ({ label, value, children }: ReportInfoRowProps) => {
  return (
    <Row>
      <Col sm={5}>
        <span className="text-muted">{label}</span>
      </Col>
      <Col sm={7}>{children || value}</Col>
    </Row>
  );
};

interface ReportHeaderProps {
  header: {
    number: number;
    date: string;
  };
}

function ReportHeader({ header }: ReportHeaderProps) {
  return (
    <>
      <Row>
        <Col>
          <ReportInfoRow label="Výpis číslo" value={header.number} />
          <ReportInfoRow label="Výpis ke dni">
            <DateLabel dateString={header.date} />
          </ReportInfoRow>
        </Col>
        <Col className="text-right">
          <h1>
            <a
              href={getServerBasename() + `/rest/export/csob-cross-check-report`}
              title="Stažení ve formátu MS Excel"
              download
            >
              <IconSolidFileDownload className="action-icon color-primary" />
            </a>
          </h1>
        </Col>
      </Row>
    </>
  );
}

export default function CsobCrossCheckReportView() {
  return (
    <Container>
      <QueryWithLoader query={CsobCrossCheckReportDocument}>
        {({ data }) => {
          const csobCrossCheckReport = data.csobCrossCheckReport;

          return csobCrossCheckReport ? (
            <>
              <Row>
                <Col className="text-center">
                  <h1>{csobCrossCheckReport.header.firmName}</h1>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col>
                  <ReportHeader header={csobCrossCheckReport.header} />
                </Col>
              </Row>
              <Row>
                <Col>
                  <ReportTable
                    transactions={csobCrossCheckReport.transactions}
                    summary={csobCrossCheckReport.summary}
                  />
                </Col>
              </Row>
            </>
          ) : (
            <NoData text="Nahrajte soubor reportu" />
          );
        }}
      </QueryWithLoader>
    </Container>
  );
}
