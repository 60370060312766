import React from "react";

interface MoneyLabelProps {
  amount: number;
  signed?: boolean;
}

// see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/NumberFormat
const integerFormatter = new Intl.NumberFormat("cs-CZ", {
  style: "currency",
  currency: "CZK",
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

const decimalFormatter = new Intl.NumberFormat("cs-CZ", {
  style: "currency",
  currency: "CZK",
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

export function formatMoney(amount: number) {
  return integerFormatter.format(amount);
}

function formatDecimalMoney(amount: number) {
  return decimalFormatter.format(amount);
}

export function DecimalMoneyLabel({ amount }: MoneyLabelProps) {
  return <span>{formatDecimalMoney(amount)}</span>;
}

export default function MoneyLabel({ amount, signed }: MoneyLabelProps) {
  const plusPrefix = signed && amount > 0 ? "+" : "";
  return <span>{plusPrefix + formatMoney(amount)}</span>;
}
