import React from "react";

interface DateTimeLabelProps {
  dateTimeString: string;
  className?: string;
}

const formatDateTimeFromString = function (dateString: string) {
  return formatDateTimeFromDate(new Date(dateString));
};

export const formatDateTimeFromDate = function (date: Date) {
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  const hour = date.getHours().toString().padStart(2, "0");
  const minute = date.getMinutes().toString().padStart(2, "0");
  const seconds = date.getSeconds().toString().padStart(2, "0");

  return `${day}.${month}.${year} ${hour}:${minute}:${seconds}`;
};

export const formatDateNoYear = function (dateString: string) {
  const date = new Date(dateString);

  const day = date.getDate();
  const month = date.getMonth() + 1;

  return `${day}.${month}`;
};

export default function DateTimeLabel({
  dateTimeString,
  className,
}: DateTimeLabelProps) {
  return (
    <span className={className}>
      {formatDateTimeFromString(dateTimeString)}
    </span>
  );
}
