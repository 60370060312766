import SexButtonGroup from '@monorepo/common/component/form/SexButtonGroup';
import { EditFormProps, PropChangedHandler, Validator } from '@monorepo/common/component/modal/EditModal';
import { EditRow } from '@monorepo/common/component/modal/EditRow';
import { PartialNull } from '@monorepo/common/type/Types';
import Sequence from '@monorepo/common/util/Sequence';
import { date2ymd, getAge } from '@monorepo/common/util/Time';
import { parseBirthNumber, validateAnyField, validateStringField } from '@monorepo/common/util/Validation';
import React, { useCallback } from 'react';
import { Col, CustomInput, Form, FormFeedback, FormGroup, Input, Label, Row } from 'reactstrap';

function birthNumberValidator(data: PartialNull<EditFormData>) {
  return function validateBirthNumber(value: string | number | null | undefined, label: string): string | undefined {
    const birthday = data['birthday'];
    const sex = data['sex'];

    if (birthday && sex && typeof value === 'string') {
      const expected = parseBirthNumber(value);

      if (birthday !== expected?.birthDateString || sex !== expected.sex) {
        return `Nebylo zadáno správné RČ, pokud RČ zadáváte správně, obraťte se na praha-podoli@sokol.eu.`;
      }
    }

    return undefined;
  };
}

export const validateWarnings: Validator<EditFormData> = (data, touched) => {
  const warnings = {};
  validateStringField('birthNumber', 'Rodné číslo', data, warnings, touched, {
    generic: birthNumberValidator(data)
  });
  return warnings;
};

export const validate: Validator<EditFormData> = (data, touched) => {
  const errors = {};
  validateStringField('degreeBefore', 'Titul', data, errors, touched, { text: { length: { max: 50 } } });
  validateStringField('degreeAfter', 'Titul', data, errors, touched, { text: { length: { max: 50 } } });
  validateStringField('firstName', 'Jméno', data, errors, touched, { required: true, text: { length: { max: 50 } } });
  validateStringField('lastName', 'Příjmení', data, errors, touched, { required: true, text: { length: { max: 50 } } });
  validateStringField('birthNumber', 'Rodné číslo', data, errors, touched, {
    required: true,
    text: {
      length: { max: 50 },
      regex: {
        pattern: /^\d+$/,
        handler: label => `Pole ${label} musí obsahovat pouze číslice`
      }
    }
  });
  validateStringField('street', 'Ulice', data, errors, touched, { required: true, text: { length: { max: 50 } } });

  validateStringField('streetNumberP', 'Číslo popisné', data, errors, touched, {
    text: { length: { max: 30 } }
  });
  validateStringField('streetNumberO', 'Číslo orientační', data, errors, touched, {
    text: { length: { max: 30 } }
  });

  if (!data.streetNumberP && !data.streetNumberO) {
    validateStringField('streetNumberP', 'Číslo popisné', data, errors, touched, {
      required: true
    });
    validateStringField('streetNumberO', 'Číslo orientační', data, errors, touched, {
      required: true
    });
  }

  validateStringField('city', 'Město', data, errors, touched, { required: true, text: { length: { max: 50 } } });
  validateStringField('zip', 'PSČ', data, errors, touched, { required: true, number: { range: { min: 0 } } });
  validateAnyField('mcp4', 'Občan městské části Praha 4', data, errors, touched, { required: true });
  validateStringField('sex', 'Pohlaví', data, errors, touched, {
    required: true,
    text: {
      oneOfTransformed: [
        {
          name: 'Muž',
          value: 'm'
        },
        {
          name: 'Žena',
          value: 'f'
        }
      ]
    }
  });
  validateStringField('birthday', 'Datum narození', data, errors, touched, { required: true });
  validateStringField('ccp', 'Číslo cvičitelského průkazu', data, errors, touched, { text: { length: { max: 12 } } });
  validateStringField('cardId', 'Číslo členského průkazu', data, errors, touched, {
    text: {
      regex: {
        pattern: /^[\da-zA-Z]+$/,
        handler: label => `Pole ${label} musí obsahovat pouze číslice a písmenka`
      }
    }
  });
  validateStringField('phone', 'Telefon', data, errors, touched, {
    text: {
      regex: { pattern: /^\d{9}$/, handler: label => `Pole ${label} musí být číslo o 9 číslicích` }
    }
  });
  validateStringField('phone2', 'Telefon2', data, errors, touched, {
    text: {
      regex: { pattern: /^\d{9}$/, handler: label => `Pole ${label} musí být číslo o 9 číslicích` }
    }
  });
  validateStringField('email', 'E-mail', data, errors, touched, { text: { length: { max: 75 }, email: true } });
  validateStringField('note', 'Poznámka', data, errors, touched, { text: { length: { max: 500 } } });

  return errors;
};

export interface EditFormData {
  id: string;
  degreeBefore?: string;
  degreeAfter?: string;
  firstName: string;
  lastName: string;
  birthNumber: string;
  street: string;
  streetNumberP: string;
  streetNumberO: string;
  city: string;
  zip: number;
  mcp4: boolean;
  sex: string;
  birthday: string;
  ccp?: string;
  cardId: string;
  phone?: number;
  phone2?: number;
  email?: string;
  gdprSignDate?: string;
  note?: string;
}

interface ClientDetailEditProps extends EditFormProps<EditFormData> {}

export default function ClientDetailEdit({ validation, onPropChange, onPropTouch, ...client }: ClientDetailEditProps) {
  const onPropChangeBirthNumber: PropChangedHandler<PartialNull<EditFormData>, 'birthNumber'> = useCallback(
    (key, value) => {
      // fill birthday and sex from birth number
      if (value) {
        const birthNumberParseResult = parseBirthNumber(value);
        if (birthNumberParseResult) {
          onPropChange('birthday', birthNumberParseResult.birthDateString);
          onPropChange('sex', birthNumberParseResult.sex);
        } else {
          onPropChange('birthday', undefined);
          onPropChange('sex', undefined);
        }
        onPropTouch('birthday');
        onPropTouch('sex');
      } else {
        onPropChange('birthday', undefined);
        onPropChange('sex', undefined);
      }

      // continue
      onPropChange(key, value);
    },
    [onPropChange, onPropTouch]
  );

  const age = client.birthday ? getAge(client.birthday) : undefined;
  return (
    <Form>
      <Row form>
        <Col sm={12} lg={6}>
          <Row form>
            <Col sm={6}>
              <EditRow
                label="Titul před"
                property="degreeBefore"
                data={client}
                validation={validation}
                onPropTouch={onPropTouch}
                onPropChange={onPropChange}
              />
            </Col>
            <Col sm={6}>
              <EditRow
                label="Titul za"
                property="degreeAfter"
                data={client}
                validation={validation}
                onPropTouch={onPropTouch}
                onPropChange={onPropChange}
              />
            </Col>
          </Row>

          <Row form>
            <Col sm={6}>
              <EditRow
                label="Jméno"
                property="firstName"
                data={client}
                validation={validation}
                onPropTouch={onPropTouch}
                onPropChange={onPropChange}
              />
            </Col>
            <Col sm={6}>
              <EditRow
                label="Příjmení"
                property="lastName"
                data={client}
                validation={validation}
                onPropTouch={onPropTouch}
                onPropChange={onPropChange}
              />
            </Col>
          </Row>

          <Row form>
            <Col sm={6}>
              <EditRow
                label="Rodné číslo"
                property="birthNumber"
                data={client}
                validation={validation}
                onPropTouch={onPropTouch}
                onPropChange={onPropChangeBirthNumber}
              />
            </Col>
            <Col sm={6}>
              <EditRow
                label="Datum narození"
                property="birthday"
                data={client}
                type="date"
                validation={validation}
                onPropTouch={onPropTouch}
                onPropChange={onPropChange}
              />
            </Col>
          </Row>

          <Row form>
            <Col>
              <EditRow
                label="Ulice"
                property="street"
                data={client}
                validation={validation}
                onPropTouch={onPropTouch}
                onPropChange={onPropChange}
              />
            </Col>
          </Row>

          <Row form>
            <Col sm={6}>
              <EditRow
                label="Číslo popisné"
                property="streetNumberP"
                data={client}
                validation={validation}
                onPropTouch={onPropTouch}
                onPropChange={onPropChange}
              />
            </Col>
            <Col sm={6}>
              <EditRow
                label="Číslo orientační"
                property="streetNumberO"
                data={client}
                validation={validation}
                onPropTouch={onPropTouch}
                onPropChange={onPropChange}
              />
            </Col>
          </Row>

          <Row form>
            <Col sm={9}>
              <EditRow
                label="Město"
                property="city"
                data={client}
                validation={validation}
                onPropTouch={onPropTouch}
                onPropChange={onPropChange}
              />
            </Col>
            <Col sm={3}>
              <EditRow
                label="PSČ"
                property="zip"
                data={client}
                validation={validation}
                onPropTouch={onPropTouch}
                onPropChange={onPropChange}
              />
            </Col>
          </Row>

          <FormGroup row>
            <Label for={Sequence.getNext('mcp4')} sm={12} check>
              Občan městské části Praha 4
            </Label>
            <Col sm={12} className="d-flex align-items-center">
              <CustomInput
                id={Sequence.getCurrent('mcp4')}
                type="switch"
                checked={!!client.mcp4}
                invalid={!!validation.errors.mcp4}
                onChange={e => onPropChange('mcp4', e.target.checked)}
                onBlur={() => onPropTouch('mcp4')}
                className="align-middle"
              />
              {validation.errors.mcp4 && <FormFeedback>{validation.errors.mcp4}</FormFeedback>}
            </Col>
          </FormGroup>
        </Col>

        <Col sm={12} lg={6}>
          <Row form>
            <Col sm={6}>
              <EditRow
                label="Telefon"
                property="phone"
                data={client}
                validation={validation}
                onPropTouch={onPropTouch}
                onPropChange={onPropChange}
              />
            </Col>
            <Col sm={6}>
              <EditRow
                label="Telefon2"
                property="phone2"
                data={client}
                validation={validation}
                onPropTouch={onPropTouch}
                onPropChange={onPropChange}
              />
            </Col>
          </Row>

          <EditRow
            label="E-mail"
            property="email"
            data={client}
            validation={validation}
            onPropTouch={onPropTouch}
            onPropChange={onPropChange}
          />

          <FormGroup row>
            <Label for="sex" sm={12}>
              Pohlaví
            </Label>
            <Col sm={12}>
              <SexButtonGroup
                // @ts-ignore
                sex={client.sex}
                onChange={sex => onPropChange('sex', sex)}
                onBlur={() => onPropTouch('sex')}
              />
              <Input type="hidden" invalid={!!validation.errors.sex} />
              {validation.errors.sex && <FormFeedback>{validation.errors.sex}</FormFeedback>}
            </Col>
          </FormGroup>

          <EditRow
            label="Číslo cvičitelského průkazu"
            property="ccp"
            data={client}
            validation={validation}
            onPropTouch={onPropTouch}
            onPropChange={onPropChange}
          />

          <EditRow
            label="Číslo členského průkazu"
            property="cardId"
            data={client}
            validation={validation}
            onPropTouch={onPropTouch}
            onPropChange={onPropChange}
          />

          {client.id === undefined && (
            <EditRow
              label="Udělit GDPR souhlas"
              property="gdprSignDate"
              data={client}
              disabled={!age || age < 18}
              type="date"
              badges={[
                {
                  text: 'dnešní datum',
                  enabled: !!age && age >= 18 && !client.gdprSignDate,
                  onClick: changePropValue => changePropValue('gdprSignDate', date2ymd(new Date()))
                }
              ]}
              validation={validation}
              onPropTouch={onPropTouch}
              onPropChange={onPropChange}
            />
          )}

          <EditRow
            label="Poznámka"
            property="note"
            data={client}
            type="textarea"
            validation={validation}
            onPropTouch={onPropTouch}
            onPropChange={onPropChange}
          />
        </Col>
      </Row>
    </Form>
  );
}
