import React, { useContext } from "react";
import Notification from "./Notification";
import { NotificationContext, NotificationData } from "./NotificationProvider";

export default function NotificationList() {
  const { notifications, closeNotification } = useContext<NotificationData>(
    NotificationContext
  );

  return (
    <>
      {!!notifications.length && (
        <div className="notification">
          {notifications.map((notification) => (
            <Notification
              key={notification.id}
              notification={notification}
              onClose={closeNotification}
            />
          ))}
        </div>
      )}
    </>
  );
}
