import React from "react";
import { Discipline } from "../../type/Types";

interface DisciplineNameLabelProps {
  discipline: Pick<Discipline, "name">;
}

export default function DisciplineNameLabel({
  discipline,
}: DisciplineNameLabelProps) {
  return <span>{discipline.name}</span>;
}
