import React from "react";
import FormikConfirmModal from "../../modal/FormikConfirmModal";
import DisciplineAccompanistSelectForm, {
  createInitialValues,
  DisciplineAccompanistSelectFormType,
  DisciplineAccompanistsSelects,
  validationSchema,
} from "./DisciplineAccompanistSelectForm";

interface DisciplineAccompanistSelectModalProps<T, F> {
  isOpen: boolean;
  onCancel: () => Promise<any>;
  onConfirm: (value: F) => Promise<any>;
  onChange: (value: F) => void;
  title: string;
  confirmButtonText: string;
  accompanistSelects: T;
}

export default function DisciplineAccompanistSelectModal({
  isOpen,
  onCancel,
  onConfirm,
  title,
  confirmButtonText,
  accompanistSelects,
  onChange,
}: DisciplineAccompanistSelectModalProps<
  DisciplineAccompanistsSelects,
  DisciplineAccompanistSelectFormType
>) {
  return (
    <FormikConfirmModal
      isOpen={isOpen}
      title={title}
      size="md"
      confirmButtonText={confirmButtonText}
      initialValues={createInitialValues(accompanistSelects)}
      validationSchema={validationSchema}
      onCancel={onCancel}
      onConfirm={onConfirm}
      onChange={onChange}
    >
      <DisciplineAccompanistSelectForm
        accompanistSelects={accompanistSelects}
      />
    </FormikConfirmModal>
  );
}
