import { ApolloError } from "@apollo/client";
import React, { useEffect, useState } from "react";
import Spinner from "reactstrap/es/Spinner";
import CenteredHV from "../layout/CenteredHV";

const LOADER_SHOW_TIMEOUT = 1000;

interface LoaderProps {
  loading: boolean;
  error: ApolloError | undefined;
  children: React.ReactNode;
}

/**
 * Shows overlay over content immediately when loading = true.
 * Overlay is see-through and not click-through for LOADER_SHOW_TIMEOUT ms. After that it dims and shows spinner.
 */
export default function Loader({
  loading = false,
  error,
  children,
}: LoaderProps) {
  const [loaderVisible, setLoaderVisible] = useState(false);

  useEffect(() => {
    // delay loader visibility for LOADER_SHOW_TIMEOUT ms
    if (loading) {
      const timeout = setTimeout(() => {
        setLoaderVisible(loading);
      }, LOADER_SHOW_TIMEOUT);

      return () => clearTimeout(timeout);
    }

    setLoaderVisible(loading);
  }, [loading]);

  const overlayVisible = loading || error;
  const overlayOpaqueVisible = loaderVisible || error;

  return (
    <div className="overlay-wrapper">
      {overlayVisible && (
        <div className={`overlay ${overlayOpaqueVisible && "overlay-opaque"}`}>
          {error ? (
            <CenteredHV>
              <h2 className="text-danger">Neznámá chyba</h2>
            </CenteredHV>
          ) : (
            loaderVisible && (
              <CenteredHV>
                <Spinner className="text-secondary" />
              </CenteredHV>
            )
          )}
        </div>
      )}
      {children}
    </div>
  );
}
