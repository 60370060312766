import React from "react";

const sex2label = {
  m: "Muž",
  f: "Žena",
};

interface SexLabelProps {
  sex: "m" | "f";
}

export default function SexLabel({ sex }: SexLabelProps) {
  return <span>{sex2label[sex]}</span>;
}
