import {
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

type DispatchWithCallback<A, S> = (
  value: A,
  callback?: (state: S) => void
) => void;

export function useStateCallback<T>(
  initialState: T | (() => T)
): [T, DispatchWithCallback<SetStateAction<T>, T>] {
  const [state, setState] = useState(initialState);

  const callbacksRef = useRef<((state: T) => void)[]>([]);

  // call stored callbacks if any exist
  useEffect(() => {
    // call all stored callbacks
    callbacksRef.current.forEach((callback) => callback(state));

    // clear them
    callbacksRef.current = [];
  }, [state, callbacksRef.current]);

  // add 3rd argument to setState
  const setStateWithCallback = useCallback(
    (
      newStateAction: T | ((prevState: T) => T),
      callback?: (state: T) => void
    ) => {
      // call the original setter
      setState(newStateAction);

      // if defined, store the callback
      if (callback) {
        callbacksRef.current.push(callback);
      }
    },
    [setState, callbacksRef]
  );

  return [state, setStateWithCallback];
}
