import PeriodLabel from '@monorepo/common/component/PeriodLabel';
import QueryWithLoader from '@monorepo/common/component/QueryWithLoader';
import React from 'react';
import { NavLink as RouterNavLink, Route, RouteComponentProps } from 'react-router-dom';
import { Card, CardBody, CardHeader, Col, Container, Nav, NavItem, NavLink, Row, Table } from 'reactstrap';
import StatsAll from '../component/stats/StatsAll';
import StatsOrderTotal from '../component/stats/StatsOrderTotal';
import StatsPaymentDiscipline, { StatPaymentDisciplineType } from '../component/stats/StatsPaymentDiscipline';
import StatsPaymentStamp, { StatPaymentStampType } from '../component/stats/StatsPaymentStamp';
import StatsPaymentToken, { StatsPaymentTokenType } from '../component/stats/StatsPaymentToken';
import {
  LastPeriodsDocument,
  LastPeriodsQuery,
  StatsCosCountsDocument,
  StatsCosCountsQuery,
  StatsCosCountsQueryVariables,
  StatsDocument,
  StatsQuery,
  StatsQueryVariables,
  TransactionType
} from '../graphql-operations';
import { getServerBasename } from '../util/Url';

const TRANSACTION_TYPE_LABEL = {
  CASH: 'Hotově',
  GATEWAY_CSOB: 'Kartou',
  TOTAL: 'Celkem'
};

interface CosCounts {
  vSeniorMaleCount: number;
  vSeniorFemaleCount: number;
  vAdultMaleCount: number;
  vAdultFemaleCount: number;
  vTeenagerMaleCount: number;
  vTeenagerFemaleCount: number;
  vJuniorMaleCount: number;
  vJuniorFemaleCount: number;
  vPreschoolMaleCount: number;
  vPreschoolFemaleCount: number;
  vTotalCount: number;
  vTrainerCount: number;
  tSeniorMaleCount: number;
  tSeniorFemaleCount: number;
  tAdultMaleCount: number;
  tAdultFemaleCount: number;
  tTeenagerMaleCount: number;
  tTeenagerFemaleCount: number;
  tJuniorMaleCount: number;
  tJuniorFemaleCount: number;
  tTotalCount: number;
  tTrainerCount: number;
}

interface StatsForTxTypeProps {
  transactionType?: TransactionType | null;
  statsPaymentStamp: StatPaymentStampType[];
  maxStampRows: number;
  statsPaymentDiscipline: StatPaymentDisciplineType[];
  maxDisciplineRows: number;
  statsPaymentToken: StatsPaymentTokenType[];
  maxTokenRows: number;
}

function StatsForTxType({
  transactionType,
  statsPaymentStamp,
  maxStampRows,
  statsPaymentDiscipline,
  maxDisciplineRows,
  statsPaymentToken,
  maxTokenRows
}: StatsForTxTypeProps) {
  return (
    <Row>
      <Col sm={12}>
        <h3>{TRANSACTION_TYPE_LABEL[transactionType || 'TOTAL']}</h3>
      </Col>
      <Col md={12} lg={6} xl={12} className="mb-3">
        <StatsAll
          statsPaymentStamp={statsPaymentStamp}
          statsPaymentDiscipline={statsPaymentDiscipline}
          statsPaymentToken={statsPaymentToken}
        />
      </Col>
      <Col md={12} lg={6} xl={12} className="mb-3">
        <StatsPaymentStamp statsPaymentStamp={statsPaymentStamp} maxRows={maxStampRows} />
      </Col>
      <Col md={12} className="mb-3">
        <StatsPaymentDiscipline statsPaymentDiscipline={statsPaymentDiscipline} maxRows={maxDisciplineRows} />
      </Col>
      <Col md={12}>
        <StatsPaymentToken statsPaymentToken={statsPaymentToken} maxRows={maxTokenRows} />
      </Col>
    </Row>
  );
}

interface StatsCosCountsVsestrannostProps {
  statsCosCounts: CosCounts;
}

function StatsCosCountsVsestrannost({ statsCosCounts }: StatsCosCountsVsestrannostProps) {
  return (
    <Table size="sm" striped>
      <thead>
        <tr>
          <th>Senioři</th>
          <th>Muži</th>
          <th>Seniorky</th>
          <th>Ženy</th>
          <th>Dorci</th>
          <th>Dorky</th>
          <th>Žáci</th>
          <th>Žákyně</th>
          <th>PD hoši</th>
          <th>PD dívky</th>
          <th>Celkem</th>
          <th>Cvičitelů</th>
        </tr>
      </thead>
      <tbody>
        <tr className="font-weight-bold">
          <td>{statsCosCounts.vSeniorMaleCount}</td>
          <td>{statsCosCounts.vAdultMaleCount}</td>
          <td>{statsCosCounts.vSeniorFemaleCount}</td>
          <td>{statsCosCounts.vAdultFemaleCount}</td>
          <td>{statsCosCounts.vTeenagerMaleCount}</td>
          <td>{statsCosCounts.vTeenagerFemaleCount}</td>
          <td>{statsCosCounts.vJuniorMaleCount}</td>
          <td>{statsCosCounts.vJuniorFemaleCount}</td>
          <td>{statsCosCounts.vPreschoolMaleCount}</td>
          <td>{statsCosCounts.vPreschoolFemaleCount}</td>
          <td>{statsCosCounts.vTotalCount}</td>
          <td>{statsCosCounts.vTrainerCount}</td>
        </tr>
      </tbody>
    </Table>
  );
}

interface StatsCosCountsTotalProps {
  statsCosCounts: CosCounts;
}

function StatsCosCountsTotal({ statsCosCounts }: StatsCosCountsTotalProps) {
  return (
    <Table size="sm" striped>
      <thead>
        <tr>
          <th>Senioři</th>
          <th>Muži</th>
          <th>Seniorky</th>
          <th>Ženy</th>
          <th>Dorci</th>
          <th>Dorky</th>
          <th>Žáci</th>
          <th>Žákyně</th>
          <th>Celkem</th>
          <th>Cvičitelů</th>
        </tr>
      </thead>
      <tbody>
        <tr className="font-weight-bold">
          <td>{statsCosCounts.tSeniorMaleCount}</td>
          <td>{statsCosCounts.tAdultMaleCount}</td>
          <td>{statsCosCounts.tSeniorFemaleCount}</td>
          <td>{statsCosCounts.tAdultFemaleCount}</td>
          <td>{statsCosCounts.tTeenagerMaleCount}</td>
          <td>{statsCosCounts.tTeenagerFemaleCount}</td>
          <td>{statsCosCounts.tJuniorMaleCount}</td>
          <td>{statsCosCounts.tJuniorFemaleCount}</td>
          <td>{statsCosCounts.tTotalCount}</td>
          <td>{statsCosCounts.tTrainerCount}</td>
        </tr>
      </tbody>
    </Table>
  );
}

interface StatsPageProps {
  match: RouteComponentProps;
}

export default function StatsPage({ match }: StatsPageProps) {
  const { path } = match.match;
  const years = [0, 1, 2].map(offset => new Date().getFullYear() - offset);
  return (
    <Container>
      <Row>
        {/*Finance stats*/}
        <Col className="mb-3">
          <Route
            path={`${path}/:year?`}
            render={({ match }) => {
              const selectedYear = match.params.year || new Date().getFullYear();
              return (
                <>
                  <Nav tabs>
                    {years.map(year => (
                      <NavItem key={year}>
                        <NavLink tag={RouterNavLink} active={year === selectedYear} to={`${path}/${year}`}>
                          {year}
                        </NavLink>
                      </NavItem>
                    ))}
                  </Nav>

                  {/* Finance */}
                  <Card className="mb-3">
                    <CardBody>
                      <QueryWithLoader<StatsQuery, StatsQueryVariables>
                        query={StatsDocument}
                        variables={{ year: selectedYear }}
                        fetchPolicy="network-only"
                      >
                        {({ data }) => {
                          const maxStampRows = data.stats.reduce(
                            (prev, curr) => Math.max(prev, curr.statsPaymentStamp.length),
                            0
                          );

                          const maxDisciplineRows = data.stats.reduce(
                            (prev, curr) => Math.max(prev, curr.statsPaymentDiscipline.length),
                            0
                          );

                          const maxTokenRows = data.stats.reduce(
                            (prev, curr) => Math.max(prev, curr.statsPaymentToken.length),
                            0
                          );

                          return (
                            <>
                              <Row>{/*<StatsTable statsAll={data.stats} />*/}</Row>
                              <Row>
                                {data.stats.map(
                                  (
                                    { transactionType, statsPaymentStamp, statsPaymentDiscipline, statsPaymentToken },
                                    idx
                                  ) => (
                                    <Col sm={4} key={(transactionType || 'total') + idx}>
                                      <StatsForTxType
                                        transactionType={transactionType}
                                        statsPaymentStamp={statsPaymentStamp}
                                        maxStampRows={maxStampRows}
                                        statsPaymentDiscipline={statsPaymentDiscipline}
                                        maxDisciplineRows={maxDisciplineRows}
                                        statsPaymentToken={statsPaymentToken}
                                        maxTokenRows={maxTokenRows}
                                      />
                                    </Col>
                                  )
                                )}
                              </Row>
                            </>
                          );
                        }}
                      </QueryWithLoader>
                    </CardBody>
                  </Card>

                  {/* COS stats */}
                  <Card>
                    <CardHeader tag="h4" className="text-center">
                      Statistika ČOS
                    </CardHeader>
                    <CardBody>
                      <QueryWithLoader<StatsCosCountsQuery, StatsCosCountsQueryVariables>
                        query={StatsCosCountsDocument}
                        variables={{ year: selectedYear }}
                        fetchPolicy="network-only"
                      >
                        {({ data }) => {
                          return (
                            <>
                              <Row>
                                <Col>
                                  <h3>Celá jednota</h3>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <StatsCosCountsTotal statsCosCounts={data.statsCosCounts} />
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <h3>Všestrannost</h3>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <StatsCosCountsVsestrannost statsCosCounts={data.statsCosCounts} />
                                </Col>
                              </Row>
                            </>
                          );
                        }}
                      </QueryWithLoader>
                      <Row>
                        <Col>
                          <h3>Export dat</h3>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <QueryWithLoader<LastPeriodsQuery> query={LastPeriodsDocument}>
                            {({ data }) => {
                              return (
                                <>
                                  {data.lastPeriods.map(period => (
                                    <Row key={period.id}>
                                      <Col>
                                        <a
                                          href={`${getServerBasename()}/rest/export/cos/${period.id}`}
                                          title={'Export dat pro ČOS'}
                                          download
                                        >
                                          <PeriodLabel period={period} />
                                        </a>
                                      </Col>
                                    </Row>
                                  ))}
                                </>
                              );
                            }}
                          </QueryWithLoader>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </>
              );
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          {/* Order total stats */}
          <Card className="mb-3">
            <CardHeader tag="h4" className="text-center">
              Objednávky
            </CardHeader>
            <CardBody>
              <StatsOrderTotal />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
