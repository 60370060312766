import React from "react";
import { NavLink } from "react-router-dom";
import TokenNameLabel from "./TokenNameLabel";

interface TokenNameLinkProps {
  token: {
    id: string;
    name: string;
  };
}

export default function TokenNameLink({ token }: TokenNameLinkProps) {
  return (
    <NavLink to={`/tokens/${token.id}`}>
      <TokenNameLabel token={token} />
    </NavLink>
  );
}
