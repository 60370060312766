import EditModal, {
  BaseEditModalProps,
  SaveCompletedHandler,
  VariablesExtractor
} from '@monorepo/common/component/modal/EditModal';
import QueryWithLoader from '@monorepo/common/component/QueryWithLoader';
import { failOnMissing } from '@monorepo/common/util/Validation';
import React, { useCallback, useMemo } from 'react';
import {
  FullPeriodAppConfigFragment,
  FullPeriodFragment,
  LastPeriodsDocument,
  LastPeriodsQuery,
  PeriodAppConfigsDocument,
  SavePeriodAppConfigDocument,
  SavePeriodAppConfigMutation,
  SavePeriodAppConfigMutationVariables
} from '../../graphql-operations';
import PeriodAppConfigDetailEdit, { EditFormData, validate } from './PeriodAppConfigDetailEdit';

interface PurePeriodAppConfigDetailEditModalProps extends PeriodAppConfigDetailEditModalProps {
  lastPeriods: FullPeriodFragment[];
}

function PurePeriodAppConfigDetailEditModal({
  title,
  periodAppConfig,
  lastPeriods,
  children
}: PurePeriodAppConfigDetailEditModalProps) {
  const data2variables: VariablesExtractor<EditFormData, SavePeriodAppConfigMutationVariables> = data => {
    const { id } = data;

    return {
      id,
      periodId: failOnMissing(data, 'period').id,
      disciplinePaymentScheduleRecurringDate: failOnMissing(data, 'disciplinePaymentScheduleRecurringDate'),
      disciplinePaymentScheduleOtherDate: failOnMissing(data, 'disciplinePaymentScheduleOtherDate'),
      disciplinePaymentScheduleSignUpDate: failOnMissing(data, 'disciplinePaymentScheduleSignUpDate'),
      disciplinePaymentScheduleEndDate: failOnMissing(data, 'disciplinePaymentScheduleEndDate'),
      stampPriceChild: failOnMissing(data, 'stampPriceChild'),
      stampPriceAdult: failOnMissing(data, 'stampPriceAdult'),
      stampPriceSenior: failOnMissing(data, 'stampPriceSenior'),
      stampPaymentEndDate: failOnMissing(data, 'stampPaymentEndDate'),
      tokenPaymentEndDate: failOnMissing(data, 'tokenPaymentEndDate')
    };
  };

  const handleCompleted: SaveCompletedHandler<SavePeriodAppConfigMutation, EditFormData> = useCallback(
    (data, apolloClient, history) => {
      // go to detail only when creating new item
      if (!periodAppConfig) {
        history.push('/app-config/' + data.savePeriodAppConfig.id);
      }
    },
    [periodAppConfig]
  );

  const refetchQueries = () => {
    return [
      {
        query: PeriodAppConfigsDocument
      }
    ];
  };

  const initialData = useMemo<Partial<EditFormData>>(
    () => ({
      periods: lastPeriods,
      ...periodAppConfig
    }),
    [lastPeriods, periodAppConfig]
  );
  //
  // useEffect(() => {
  //   console.log('changed lastPeriods', { lastPeriods });
  // }, [lastPeriods]);
  //
  // useEffect(() => {
  //   console.log('changed periodAppConfig', { periodAppConfig });
  // }, [periodAppConfig]);
  //
  // useEffect(() => {
  //   console.log('changed initialData', initialData.stampPriceChild);
  // }, [initialData]);

  return (
    <EditModal<EditFormData, SavePeriodAppConfigMutationVariables, SavePeriodAppConfigMutation>
      title={title}
      tag={PeriodAppConfigDetailEdit}
      initialData={initialData}
      data2variables={data2variables}
      validate={validate}
      mutation={SavePeriodAppConfigDocument}
      onCompleted={handleCompleted}
      refetchQueries={refetchQueries}
      children={children}
    />
  );
}

interface PeriodAppConfigDetailEditModalProps extends BaseEditModalProps {
  title: string;
  periodAppConfig?: FullPeriodAppConfigFragment;
}

export default function PeriodAppConfigDetailEditModal({
  title,
  periodAppConfig,
  children
}: PeriodAppConfigDetailEditModalProps) {
  return (
    <QueryWithLoader<LastPeriodsQuery> query={LastPeriodsDocument}>
      {({ data }) => {
        return (
          <PurePeriodAppConfigDetailEditModal
            lastPeriods={data.lastPeriods}
            title={title}
            periodAppConfig={periodAppConfig}
          >
            {children}
          </PurePeriodAppConfigDetailEditModal>
        );
      }}
    </QueryWithLoader>
  );
}
