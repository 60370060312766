import React, { Component } from "react";
import { NotificationType, PublicNotificationType } from "./Notification";

export interface NotificationData {
  notifications: NotificationType[];
  showNotification: (notification: PublicNotificationType) => void;
  closeNotification: (notification: NotificationType) => void;
}

export const NotificationContext = React.createContext<NotificationData>({
  notifications: [],
  showNotification: () => {},
  closeNotification: () => {},
});

export const NotificationConsumer = NotificationContext.Consumer;

let idCounter = 0;

interface NotificationProviderState extends NotificationData {}

export default class NotificationProvider extends Component<
  {},
  NotificationProviderState
> {
  constructor(props: {}) {
    super(props);

    this.showNotification = this.showNotification.bind(this);
    this.closeNotification = this.closeNotification.bind(this);
    this.hideNotification = this.hideNotification.bind(this);
    this.removeNotification = this.removeNotification.bind(this);

    this.state = {
      notifications: [
        // {
        //   id: this.idCounter++,
        //   type: NOTIFICATION_TYPE.INFO,
        //   header: 'Chyba',
        //   body: 'Došlo k neznámé chybě  Došlo k neznámé chybě  Došlo k neznámé chybě  Došlo k neznámé chybě  Došlo k neznámé chybě  Došlo k neznámé chybě  '
        // },{
        //   id: this.idCounter++,
        //   type: NOTIFICATION_TYPE.WARN,
        //   header: 'Chyba',
        //   body: 'Došlo k neznámé chybě'
        // },{
        //   id: this.idCounter++,
        //   type: NOTIFICATION_TYPE.ERROR,
        //   header: 'Chyba',
        //   body: 'Došlo '
        // }
      ],
      showNotification: this.showNotification,
      closeNotification: this.closeNotification,
    };
  }

  showNotification(notification: PublicNotificationType) {
    console.log("showNotification");
    this.setState((state) => ({
      notifications: [
        ...state.notifications,
        {
          ...notification,
          id: idCounter++,
          visible: true,
        },
      ],
    }));
  }

  closeNotification(notification: NotificationType) {
    console.log("closeNotification");
    this.hideNotification(notification);
    setTimeout(this.removeNotification, 500, notification);
  }

  hideNotification(notification: NotificationType) {
    console.log("hideNotification");
    this.setState((state) => ({
      notifications: state.notifications.map((n) => {
        if (notification === n) {
          return {
            ...n,
            visible: false,
          };
        }
        return n;
      }),
    }));
  }

  removeNotification(notification: NotificationType) {
    console.log("removeNotification");
    this.setState((state) => ({
      notifications: state.notifications.filter(
        (n) => n.id !== notification.id
      ),
    }));
  }

  render() {
    return (
      <NotificationContext.Provider value={this.state}>
        {this.props.children}
      </NotificationContext.Provider>
    );
  }
}
