import React from "react";
import { Col, ColProps, FormGroup, LabelProps } from "reactstrap";
import { InputType } from "reactstrap/es/Input";
import { Validation } from "../../type/Types";
import { Normalizer, Normalizer2 } from "../../util/Normalizer";
import ValidatedInputGroup from "../form/ValidatedInputGroup";
import MultiBadgeLabel, {
  BadgeType,
  BADGE_ALIGNMENT,
} from "../MultiBadgeLabel";
import { PropChangedHandler, PropTouchedHandler } from "./EditModal";

export interface EditRowProps<T, K extends keyof T> {
  label: string;
  labelProps?: LabelProps;
  placeholder?: string;

  disabled?: boolean;

  property: K;
  data: T;
  dataColProps?: ColProps;

  type?: InputType;
  currency?: string;

  badges?: BadgeType<T, K>[];
  badgesAlignment?: BADGE_ALIGNMENT;

  normalizer?: Normalizer;
  normalizer2?: Normalizer2<T[K]>;

  validation: Validation<T>;
  onPropTouch: PropTouchedHandler<T>;
  onPropChange: PropChangedHandler<T, K>;
}

export function EditRow<T, K extends keyof T>({
  label,
  labelProps, // = { sm: 12, md: 3 },
  placeholder,
  disabled,
  property,
  data,
  dataColProps,
  type = "text",
  currency,
  badges,
  badgesAlignment,
  normalizer,
  normalizer2,
  validation,
  onPropTouch,
  onPropChange,
}: EditRowProps<T, K>) {
  return (
    <FormGroup row>
      <MultiBadgeLabel
        {...labelProps}
        label={label}
        badges={badges}
        property={property}
        alignment={badgesAlignment}
        onPropTouch={onPropTouch}
        onPropChange={onPropChange}
      />
      <Col sm={12} {...dataColProps}>
        <ValidatedInputGroup
          property={property}
          placeholder={placeholder}
          data={data}
          disabled={disabled}
          type={type}
          currency={currency}
          normalizer={normalizer}
          normalizer2={normalizer2}
          validation={validation}
          onPropTouch={onPropTouch}
          onPropChange={onPropChange}
        />
      </Col>
    </FormGroup>
  );
}
