export type Normalizer = (ok: ValueHandler, value: string) => void;

type ValueHandler = (value: string) => void;

export function identityNormalizer(ok: ValueHandler, value: string) {
  ok(value);
}

export function integerNormalizer(ok: ValueHandler, value: string) {
  if (!Number.isNaN(value)) {
    // do not allow decimal places
    ok(String(Math.round(Number(value))));
  }
}

export type Normalizer2<T> = (ok: ValueHandler2<T>, value: string) => void;

type ValueHandler2<T> = (value: string, transformed: T) => void;

export function identityNormalizer2(
  ok: ValueHandler2<string | number | undefined | null>,
  // ok: ValueHandler2<string>,
  value: string
) {
  ok(value, value);
}

export function integerNormalizer2(ok: ValueHandler2<number>, value: string) {
  if (!Number.isNaN(value) && !isNaN(Number(value))) {
    // do not allow decimal places
    const num = Math.round(Number(value));
    ok(String(num), num);
    return;
  }
}
