import React from 'react';

interface ActivityLabelProps {
  activity: any;
}

export default function ActivityLabel({ activity }: ActivityLabelProps) {
  return (
    <span>
      {activity.name} ({activity.code})
    </span>
  );
}
