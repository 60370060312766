import {
  faCheckCircle,
  faEdit,
  faFolderOpen,
  faQuestionCircle,
  faXmarkCircle,
} from "@fortawesome/free-regular-svg-icons";
import {
  faAddressCard,
  faAt,
  faChartLine,
  faCloudUploadAlt,
  faDoorOpen,
  faEnvelope,
  faFileDownload,
  faMobileAlt,
  faPlus,
  faPrint,
  faSearch,
  faSignOutAlt,
  faSync,
  faTimes,
  faTrashAlt,
  faUndo,
  faUser,
  faUserPlus,
  faVolleyballBall,
} from "@fortawesome/free-solid-svg-icons";
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from "@fortawesome/react-fontawesome";
import React from "react";

export type IconProps = Omit<FontAwesomeIconProps, "icon">;

// see https://fontawesome.com/icons

/** REGULAR */

export const IconRegularEdit = (props: IconProps) => (
  <FontAwesomeIcon icon={faEdit} {...props} />
);

export const IconRegularFolderOpen = (props: IconProps) => (
  <FontAwesomeIcon icon={faFolderOpen} {...props} />
);

export const IconRegularQuestionCircle = (props: IconProps) => (
  <FontAwesomeIcon icon={faQuestionCircle} {...props} />
);

export const IconRegularXmarkCircle = (props: IconProps) => (
  <FontAwesomeIcon icon={faXmarkCircle} {...props} />
);

export const IconRegularCheckCircle = (props: IconProps) => (
  <FontAwesomeIcon icon={faCheckCircle} {...props} />
);

/** SOLID */

export const IconSolidAt = (props: IconProps) => (
  <FontAwesomeIcon icon={faAt} {...props} />
);

export const IconSolidAddressCard = (props: IconProps) => (
  <FontAwesomeIcon icon={faAddressCard} {...props} />
);

export const IconSolidCloudUploadAlt = (props: IconProps) => (
  <FontAwesomeIcon icon={faCloudUploadAlt} {...props} />
);

export const IconSolidChartLine = (props: IconProps) => (
  <FontAwesomeIcon icon={faChartLine} {...props} />
);

export const IconSolidDoorOpen = (props: IconProps) => (
  <FontAwesomeIcon icon={faDoorOpen} {...props} />
);

export const IconSolidEnvelope = (props: IconProps) => (
  <FontAwesomeIcon icon={faEnvelope} {...props} />
);

export const IconSolidFileDownload = (props: IconProps) => (
  <FontAwesomeIcon icon={faFileDownload} {...props} />
);

export const IconSolidMobileAlt = (props: IconProps) => (
  <FontAwesomeIcon icon={faMobileAlt} {...props} />
);

export const IconSolidPlus = (props: IconProps) => (
  <FontAwesomeIcon icon={faPlus} {...props} />
);

export const IconSolidPrint = (props: IconProps) => (
  <FontAwesomeIcon icon={faPrint} {...props} />
);

export const IconSolidSearch = (props: IconProps) => (
  <FontAwesomeIcon icon={faSearch} {...props} />
);

export const IconSolidSignOutAlt = (props: IconProps) => (
  <FontAwesomeIcon icon={faSignOutAlt} {...props} />
);

export const IconSolidSync = (props: IconProps) => (
  <FontAwesomeIcon icon={faSync} {...props} />
);

export const IconSolidTimes = (props: IconProps) => (
  <FontAwesomeIcon icon={faTimes} {...props} />
);

export const IconSolidTrashAlt = (props: IconProps) => (
  <FontAwesomeIcon icon={faTrashAlt} {...props} />
);

export const IconSolidUser = (props: IconProps) => (
  <FontAwesomeIcon icon={faUser} {...props} />
);

export const IconSolidUserPlus = (props: IconProps) => (
  <FontAwesomeIcon icon={faUserPlus} {...props} />
);

export const IconSolidUndo = (props: IconProps) => (
  <FontAwesomeIcon icon={faUndo} {...props} />
);

export const IconSolidVolleyballBall = (props: IconProps) => (
  <FontAwesomeIcon icon={faVolleyballBall} {...props} />
);
