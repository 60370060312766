import EditModal, { BaseEditModalProps, VariablesExtractor } from '@monorepo/common/component/modal/EditModal';
import { PartialNull } from '@monorepo/common/type/Types';
import { failOnMissing } from '@monorepo/common/util/Validation';
import React from 'react';
import {
  AddLegalRepresentationGdprAgreementDocument,
  AddLegalRepresentationGdprAgreementMutationVariables,
  ClientDocument,
  PersonDocument
} from '../../graphql-operations';
import GdprAgreementNew, { EditFormData, validate } from '../gdpr/GdprAgreementNew';
import { LegalRepresentationType } from './LegalRepresentationList';

const data2variables: VariablesExtractor<EditFormData, AddLegalRepresentationGdprAgreementMutationVariables> = data => {
  return {
    legalRepresentationId: failOnMissing(data, 'legalRepresentation').id,
    gdprSignDate: failOnMissing(data, 'gdprSignDate')
  };
};

function refetchQueries({ legalRepresentation }: PartialNull<EditFormData>) {
  if (legalRepresentation) {
    // representative is either client or person
    const representativeQuery = legalRepresentation.representative.clientData
      ? {
          query: ClientDocument,
          variables: { id: legalRepresentation.representative.id }
        }
      : {
          query: PersonDocument,
          variables: { id: legalRepresentation.representative.id }
        };

    return [
      {
        query: ClientDocument,
        variables: { id: legalRepresentation.child.id }
      },
      representativeQuery
    ];
  }

  return [];
}

interface LegalRepresentationGdprAgreementNewModalProps extends BaseEditModalProps {
  title: string;
  legalRepresentation: LegalRepresentationType;
}

export default function LegalRepresentationGdprAgreementNewModal({
  title,
  legalRepresentation,
  children
}: LegalRepresentationGdprAgreementNewModalProps) {
  return (
    <EditModal
      title={title}
      tag={GdprAgreementNew}
      initialData={{
        legalRepresentation
      }}
      data2variables={data2variables}
      validate={validate}
      mutation={AddLegalRepresentationGdprAgreementDocument}
      refetchQueries={refetchQueries}
      children={children}
    />
  );
}
