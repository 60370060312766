import React, { MouseEventHandler, useCallback, useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { ChildrenRenderProp } from "./EditModal";

export interface BaseSimpleModalProps {
  children: ChildrenRenderProp;
}

interface SimpleModalProps extends BaseSimpleModalProps {
  title: string;
  size?: string;
  body: () => React.ReactNode;
}

export default function SimpleModal({
  title,
  size = "sm",
  body,
  children,
}: SimpleModalProps) {
  const [isOpen, setIsOpen] = useState(false);

  const handleModalOpen: MouseEventHandler = useCallback(
    (event) => {
      event.preventDefault();
      setIsOpen(true);
    },
    [setIsOpen]
  );

  const handleModalClose: MouseEventHandler = useCallback(
    (event) => {
      setIsOpen(false);
    },
    [setIsOpen]
  );

  return (
    <>
      {children(handleModalOpen, title)}
      <Modal isOpen={isOpen} size={size} toggle={handleModalClose} centered>
        <ModalHeader toggle={handleModalClose}>{title}</ModalHeader>
        <ModalBody>{body()}</ModalBody>
      </Modal>
    </>
  );
}
