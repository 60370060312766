import React from "react";
import PersonNameLabel, { PersonDataType } from "../person/PersonNameLabel";

interface ClientNameLabelProps {
  client: {
    personData: PersonDataType;
  };
}

export default function ClientNameLabel({ client }: ClientNameLabelProps) {
  return <PersonNameLabel person={client} />;
}
