import EditModal, { BaseEditModalProps } from '@monorepo/common/component/modal/EditModal';
import QueryWithLoader from '@monorepo/common/component/QueryWithLoader';
import React, { useCallback } from 'react';
import {
  AddSokolRoleDocument,
  AddSokolRoleMutation,
  AddSokolRoleMutationVariables,
  ClientDocument,
  LastPeriodsDocument,
  LastPeriodsQuery
} from '../../graphql-operations';
import SokolRoleDetailEdit, { EditFormData, validate } from './SokolRoleDetailEdit';

interface PaymentStampDetailEditModalProps extends BaseEditModalProps {
  title: string;
  clientId: string;
}

export default function PaymentStampDetailEditModal({ title, clientId, children }: PaymentStampDetailEditModalProps) {
  const refetchQueries = useCallback(() => {
    return [
      {
        query: ClientDocument,
        variables: { id: clientId }
      }
    ];
  }, [clientId]);

  const data2variables = useCallback(
    ({ period, role }) => {
      return {
        clientId: clientId,
        periodId: period.id,
        role: role
      };
    },
    [clientId]
  );

  return (
    <QueryWithLoader<LastPeriodsQuery> query={LastPeriodsDocument}>
      {({ data }) => {
        return (
          <EditModal<EditFormData, AddSokolRoleMutationVariables, AddSokolRoleMutation>
            title={title}
            tag={SokolRoleDetailEdit}
            initialData={{
              periods: data.lastPeriods
            }}
            data2variables={data2variables}
            validate={validate}
            mutation={AddSokolRoleDocument}
            refetchQueries={refetchQueries}
            children={children}
          />
        );
      }}
    </QueryWithLoader>
  );
}
