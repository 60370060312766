import EditModal, { BaseEditModalProps, VariablesExtractor } from '@monorepo/common/component/modal/EditModal';
import { failOnMissing } from '@monorepo/common/util/Validation';
import React from 'react';
import {
  AddClientAccompanistDocument,
  AddClientAccompanistMutation,
  AddClientAccompanistMutationVariables,
  ClientDocument
} from '../../graphql-operations';
import { ClientType } from '../client/ClientAutocompleteInput';
import ClientAccompanistDetailNew, { EditFormData, validate } from './ClientAccompanistDetailNew';

interface refetchQueriesInput {
  childId?: string | null;
  accompanist?: ClientType | null;
}

function refetchQueries({ childId, accompanist }: refetchQueriesInput) {
  const childQuery = {
    query: ClientDocument,
    variables: { id: childId }
  };

  const accompanistQuery = accompanist
    ? {
        query: ClientDocument,
        variables: { id: accompanist.id }
      }
    : undefined;

  return accompanistQuery ? [childQuery, accompanistQuery] : [childQuery];
}

const data2variables: VariablesExtractor<EditFormData, AddClientAccompanistMutationVariables> = data => {
  const { accompanist, firstName, lastName } = data;

  return {
    childId: failOnMissing(data, 'childId'),
    accompanistId: accompanist ? accompanist.id : undefined,
    firstName,
    lastName,
    role: failOnMissing(data, 'role')
  };
};

interface ClientAccompanistDetailNewModalProps extends BaseEditModalProps {
  title: string;
  clientId: string;
}

export default function ClientAccompanistDetailNewModal({
  title,
  clientId,
  children
}: ClientAccompanistDetailNewModalProps) {
  return (
    <EditModal<EditFormData, AddClientAccompanistMutationVariables, AddClientAccompanistMutation>
      title={title}
      tag={ClientAccompanistDetailNew}
      initialData={{
        childId: clientId
      }}
      data2variables={data2variables}
      validate={validate}
      mutation={AddClientAccompanistDocument}
      refetchQueries={refetchQueries}
      children={children}
    />
  );
}
