import EditModal, {
  BaseEditModalProps,
  DiffExtractor,
  VariablesExtractor
} from '@monorepo/common/component/modal/EditModal';
import { failOnMissing } from '@monorepo/common/util/Validation';
import React, { useCallback } from 'react';
import {
  AddDisciplineTrainerDocument,
  AddDisciplineTrainerMutationVariables,
  DisciplineConfigDocument
} from '../../../graphql-operations';
import DisciplineTrainerDetailEdit, { EditFormData, validate } from './DisciplineTrainerDetailEdit';

const data2variables: VariablesExtractor<EditFormData, AddDisciplineTrainerMutationVariables> = data => {
  return {
    disciplineConfigId: failOnMissing(data, 'disciplineConfigId'),
    clientId: failOnMissing(data, 'client').id,
    role: failOnMissing(data, 'role')
  };
};

const change2diff: DiffExtractor<EditFormData> = (name, value, diff) => {
  // delete client on role change
  if (name === 'role') {
    diff.client = undefined;
  }
  return diff;
};

interface DisciplineTrainerDetailEditModalProps extends BaseEditModalProps {
  title: string;
  periodId: string;
  disciplineConfigId: string;
}

export default function DisciplineTrainerDetailEditModal({
  title,
  periodId,
  disciplineConfigId,
  children
}: DisciplineTrainerDetailEditModalProps) {
  const refetchQueries = useCallback(() => {
    return [
      {
        query: DisciplineConfigDocument,
        variables: {
          id: disciplineConfigId
        }
      }
    ];
  }, [disciplineConfigId]);

  return (
    <EditModal
      title={title}
      tag={DisciplineTrainerDetailEdit}
      initialData={{
        disciplineConfigId,
        periodId
      }}
      change2diff={change2diff}
      data2variables={data2variables}
      validate={validate}
      mutation={AddDisciplineTrainerDocument}
      refetchQueries={refetchQueries}
      children={children}
    />
  );
}
