import React from "react";
import { PersonData } from "../../type/Types";

export type PersonDataType = Pick<
  PersonData,
  "id" | "degreeBefore" | "degreeAfter" | "firstName" | "lastName"
>;

export interface PersonNameLabelProps {
  person: {
    personData: PersonDataType;
  };
}

export function formatPersonName(personData: PersonDataType) {
  return `${personData.degreeBefore ? personData.degreeBefore + " " : ""}${
    personData.lastName
  } ${personData.firstName}${
    personData.degreeAfter ? ", " + personData.degreeAfter : ""
  }`;
}

export default function PersonNameLabel({ person }: PersonNameLabelProps) {
  return <span>{formatPersonName(person.personData)}</span>;
}
