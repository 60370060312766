import React, { useContext, useState } from "react";
import { NavLink as RouterNavLink } from "react-router-dom";
import {
  Button,
  Collapse,
  Nav,
  Navbar,
  NavbarBrand,
  NavbarText,
  NavbarToggler,
  NavItem,
  NavLink,
} from "reactstrap";
import {
  AuthenticationContext,
  AuthenticationStatus,
} from "../component/AuthenticationContext";
import { IconSolidSignOutAlt } from "../component/Icons";
import { NotificationList } from "../component/notification";

interface MenuItem {
  url: string;
  label: string;
}

interface CommonHeaderProps {
  menuItems: MenuItem[];
}

export default function CommonHeader({ menuItems }: CommonHeaderProps) {
  const [isOpen, setIsOpen] = useState(true);
  const { status, user } = useContext(AuthenticationContext);
  const authenticated = status === AuthenticationStatus.AUTHENTICATED;

  const test =
    window.location.host === "bo.sokol.svetrikovi.cz" ||
    window.location.host === "muj.sokol.svetrikovi.cz";

  return (
    <>
      <Navbar color={test ? "danger" : "dark"} dark expand="sm" fixed="top">
        <NavbarBrand href="/">Sokol databáze{test ? " -TEST" : ""}</NavbarBrand>
        {authenticated && (
          <>
            <NavbarToggler
              onClick={() => setIsOpen((prevIsOpen) => !prevIsOpen)}
            />

            <Collapse isOpen={isOpen} navbar>
              <Nav navbar>
                {menuItems.map(({ url, label }) => (
                  <NavItem key={url}>
                    <NavLink
                      tag={RouterNavLink}
                      activeClassName="active"
                      to={url}
                    >
                      {label}
                    </NavLink>
                  </NavItem>
                ))}
              </Nav>
            </Collapse>
          </>
        )}

        <Nav className="ml-auto" navbar>
          {authenticated && user ? (
            <>
              <NavbarText>
                {user.lastName} {user.firstName}
              </NavbarText>
              <NavItem>
                <NavLink
                  tag={RouterNavLink}
                  activeClassName="active"
                  to="/logout"
                  title="Odhlásit"
                >
                  <IconSolidSignOutAlt />
                </NavLink>
              </NavItem>
            </>
          ) : (
            <Button color="primary" tag={RouterNavLink} to="/login">
              Přihlásit
            </Button>
          )}
        </Nav>
      </Navbar>
      <NotificationList />
    </>
  );
}
