import EditModal, { BaseEditModalProps, VariablesExtractor } from '@monorepo/common/component/modal/EditModal';
import { failOnMissing } from '@monorepo/common/util/Validation';
import React, { useCallback } from 'react';
import {
  AddDisciplineConfigPriceDocument,
  AddDisciplineConfigPriceMutationVariables,
  DisciplineConfigDocument
} from '../../../graphql-operations';
import DisciplineConfigPriceNew, { EditFormData, validate } from './DisciplineConfigPriceNew';

const data2variables: VariablesExtractor<EditFormData, AddDisciplineConfigPriceMutationVariables> = data => {
  return {
    disciplineConfigId: failOnMissing(data, 'disciplineConfigId'),
    ageFrom: failOnMissing(data, 'ageFrom'),
    price: failOnMissing(data, 'price')
  };
};

interface DisciplineConfigPriceNewModalProps extends BaseEditModalProps {
  title: string;
  disciplineConfigId: string;
}

export default function DisciplineConfigPriceNewModal({
  title,
  disciplineConfigId,
  children
}: DisciplineConfigPriceNewModalProps) {
  const refetchQueries = useCallback(() => {
    return [
      {
        query: DisciplineConfigDocument,
        variables: {
          id: disciplineConfigId
        }
      }
    ];
  }, [disciplineConfigId]);

  return (
    <EditModal
      title={title}
      tag={DisciplineConfigPriceNew}
      initialData={{
        disciplineConfigId
      }}
      data2variables={data2variables}
      validate={validate}
      mutation={AddDisciplineConfigPriceDocument}
      refetchQueries={refetchQueries}
      children={children}
    />
  );
}
