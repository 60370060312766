import React from 'react';
import { match, Route } from 'react-router-dom';
import DisciplineDetailView, { DisciplineType } from './DisciplineDetailView';

interface DisciplineDetailUrlParams {
  disciplineId?: string;
}

interface DisciplineDetailPeriodViewProps {
  match: match<DisciplineDetailUrlParams>;
  discipline: DisciplineType;
}

export default function DisciplineDetailPeriodView({ match, discipline }: DisciplineDetailPeriodViewProps) {
  return (
    <>
      <Route
        path={`${match.path}/:periodId?`}
        render={props => (
          <DisciplineDetailView discipline={discipline} urlPeriodId={props.match.params.periodId} url={match.url} />
        )}
      />
    </>
  );
}
