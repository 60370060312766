import ClientNameLabel from '@monorepo/common/component/client/ClientNameLabel';
import { PersonDataType } from '@monorepo/common/component/person/PersonNameLabel';
import React from 'react';
import ClientNameLink from '../client/ClientNameLink';

interface AccompanistNameLabelProps {
  accompanist: {
    accompanist?: {
      id: string;
      personData: PersonDataType;
    } | null;
    firstName?: string | null;
    lastName?: string | null;
  };
  link?: boolean;
}

export default function AccompanistNameLabel({ accompanist, link = false }: AccompanistNameLabelProps) {
  if (accompanist.accompanist) {
    if (link) {
      return <ClientNameLink client={accompanist.accompanist} />;
    }
    return <ClientNameLabel client={accompanist.accompanist} />;
  }

  return <span>{`${accompanist.lastName} ${accompanist.firstName}`}</span>;
}
