import { EditFormProps, ValidationErrors, Validator } from '@monorepo/common/component/modal/EditModal';
import { EditRowLeftRight } from '@monorepo/common/component/modal/EditRowLeftRight';
import { integerNormalizer } from '@monorepo/common/util/Normalizer';
import { validateStringField } from '@monorepo/common/util/Validation';
import React from 'react';
import { Form } from 'reactstrap';

export const validate: Validator<EditFormData> = (data, touched) => {
  const errors: ValidationErrors<EditFormData> = {};

  validateStringField('price', 'Cena', data, errors, touched, {
    required: true,
    number: { range: { min: 0 } }
  });

  if ((touched.all || touched.birthDateFrom || touched.birthDateTo) && !data.birthDateFrom && !data.birthDateTo) {
    errors.birthDateFrom = errors.birthDateFrom || 'Vyplňte alespoň jednu hranici';
    errors.birthDateTo = errors.birthDateTo || 'Vyplňte alespoň jednu hranici';
  }

  return errors;
};

export interface EditFormData {
  birthDateFrom?: string;
  birthDateTo?: string;
  price: number;
  disciplineId: string;
}

export interface DisciplineAccompanistDetailNewProps extends EditFormProps<EditFormData> {}

export default function DisciplineAccompanistDetailNew({
  validation,
  onPropChange,
  onPropTouch,
  ...data
}: DisciplineAccompanistDetailNewProps) {
  return (
    <Form>
      {/* Datum narozeni od*/}
      <EditRowLeftRight
        label="Datum narození od"
        placeholder="Zadejte datum narození od"
        labelProps={{ md: 5 }}
        dataColProps={{ md: 7 }}
        property="birthDateFrom"
        type="date"
        data={data}
        validation={validation}
        onPropTouch={onPropTouch}
        onPropChange={onPropChange}
      />

      {/* Datum narozeni do*/}
      <EditRowLeftRight
        label="Datum narození do"
        placeholder="Zadejte datum narození do"
        labelProps={{ md: 5 }}
        dataColProps={{ md: 7 }}
        property="birthDateTo"
        type="date"
        data={data}
        validation={validation}
        onPropTouch={onPropTouch}
        onPropChange={onPropChange}
      />

      {/* Cena */}
      <EditRowLeftRight
        label="Cena"
        placeholder="Zadejte cenu"
        labelProps={{ md: 5 }}
        dataColProps={{ md: 7 }}
        property="price"
        data={data}
        currency="Kč"
        normalizer={integerNormalizer}
        validation={validation}
        onPropTouch={onPropTouch}
        onPropChange={onPropChange}
      />
    </Form>
  );
}
