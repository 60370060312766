import React from 'react';
import { match, Route } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import TokenDetail from '../component/token/TokenDetail';
import TokenFilterableList from '../component/token/TokenFilterableList';

interface TokensPageProps {
  match: match;
}

export default function TokensPage({ match }: TokensPageProps) {
  return (
    <Container>
      <Row>
        <Col xs={3}>
          <div className="position-fixed-scrollable">
            <Route
              path={`${match.path}/:tokenId?`}
              render={props => <TokenFilterableList tokenId={props.match.params.tokenId} url={match.url} />}
            />
          </div>
        </Col>

        <Col xs={9}>
          <Route path={`${match.path}/:tokenId`} render={props => <TokenDetail match={props.match} />} />
          <Route exact path={match.path} render={props => <TokenDetail match={props.match} />} />
        </Col>
      </Row>
    </Container>
  );
}
