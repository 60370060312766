import AgeRangeLabel from '@monorepo/common/component/AgeRangeLabel';
import CapacityProgress from '@monorepo/common/component/CapacityProgress';
import DateRange from '@monorepo/common/component/DateRange';
import { IconRegularEdit, IconSolidTrashAlt } from '@monorepo/common/component/Icons';
import MoneyLabel from '@monorepo/common/component/MoneyLabel';
import NoData, { NoDataWithMarginBottom } from '@monorepo/common/component/NoData';
import OrderLink from '@monorepo/common/component/order/OrderLink';
import React from 'react';
import { Button, Card, CardBody, Col, FormGroup, Row, Table } from 'reactstrap';
import { SokolRoleEnum } from '../../../graphql-operations';
import { getServerBasename } from '../../../util/Url';
import ClientNameLink from '../../client/ClientNameLink';
import SokolRoleLabel from '../../sokolRole/SokolRoleLabel';
import DisciplineTrainerDetailDeleteModal from '../trainer/DisciplineTrainerDetailDeleteModal';
import DisciplineTrainerDetailEditModal from '../trainer/DisciplineTrainerDetailEditModal';
import DisciplineConfigDetailEditModal from './DisciplineConfigDetailEditModal';
import DisciplineConfigPriceDeleteModal from './DisciplineConfigPriceDeleteModal';
import DisciplineConfigPriceNewModal from './DisciplineConfigPriceNewModal';

export interface DisciplineConfigType {
  __typename?: 'DisciplineConfig';
  id: string;
  prices: {
    __typename?: 'DisciplineConfigPrice';
    id: string;
    ageFrom: number;
    price: number;
  }[];
  capacity: number;
  birthDateFrom?: string | null;
  birthDateTo?: string | null;
  period: {
    __typename?: 'Period';
    id: string;
    year: number;
    number: number;
  };
  discipline: {
    __typename?: 'Discipline';
    id: string;
  };
  leader?: {
    __typename?: 'Client';
    id: string;
    personData: {
      __typename?: 'PersonData';
      id: string;
      degreeBefore?: string | null;
      degreeAfter?: string | null;
      firstName: string;
      lastName: string;
    };
    clientData: {
      __typename?: 'ClientData';
      id: string;
      birthNumber: string;
      age: number;
    };
  } | null;
  trainers: Array<{
    __typename?: 'DisciplineTrainer';
    id: string;
    role: SokolRoleEnum;
    client: {
      __typename?: 'Client';
      id: string;
      personData: {
        __typename?: 'PersonData';
        id: string;
        degreeBefore?: string | null;
        degreeAfter?: string | null;
        firstName: string;
        lastName: string;
      };
    };
  }>;
  paymentsDiscipline: Array<{
    __typename?: 'PaymentDiscipline';
    id: string;
    client: {
      __typename?: 'Client';
      id: string;
      personData: {
        __typename?: 'PersonData';
        id: string;
        degreeBefore?: string | null;
        degreeAfter?: string | null;
        firstName: string;
        lastName: string;
      };
    };
    orderDiscipline: {
      __typename?: 'OrderDiscipline';
      id: string;
      priceDiscounted: number;
      orderTotal: {
        __typename?: 'OrderTotal';
        id: string;
        orderNumber: string;
      };
    };
  }>;
  occupancy: {
    __typename?: 'DisciplineConfigOccupancy';
    paidCount: number;
  };
}

interface DisciplineConfigDetailViewProps {
  disciplineConfig: DisciplineConfigType;
}

export default function DisciplineConfigDetailView({ disciplineConfig }: DisciplineConfigDetailViewProps) {
  const {
    id,
    period,
    capacity,
    birthDateFrom,
    birthDateTo,
    prices,
    leader,
    trainers,
    discipline,
    paymentsDiscipline,
    occupancy
  } = disciplineConfig;
  return (
    <Card>
      <CardBody>
        <Row>
          <Col xs={12} lg={6}>
            <DisciplineConfigDetailEditModal
              key={id}
              title="Změna nastavení oddílu"
              disciplineId={discipline.id}
              disciplineConfig={disciplineConfig}
            >
              {(handleModalOpen, title) => (
                <h4>
                  <IconRegularEdit onClick={handleModalOpen} title={title} className="action-icon text-primary" />
                  Nastavení oddílu
                </h4>
              )}
            </DisciplineConfigDetailEditModal>

            <Row>
              <Col sm={12} md={5} className="text-muted">
                Kapacita
              </Col>
              <Col sm={12} md={7} className="text-right">
                {capacity}
              </Col>
            </Row>

            <Row>
              <Col sm={12} md={5} className="text-muted">
                Datum narození
              </Col>
              <Col sm={12} md={7}>
                <DateRange dateFromString={birthDateFrom} dateToString={birthDateTo} emptyLabel="bez omezení" />
              </Col>
            </Row>

            <FormGroup row>
              <Col sm={12} md={5} className="text-muted">
                Vedoucí
              </Col>
              <Col sm={12} md={7}>
                {leader && <ClientNameLink client={leader} />}
              </Col>
            </FormGroup>

            <h4>Ceny</h4>
            <Row>
              <Col sm={12}>
                {!!prices.length && (
                  <Table size="sm" striped>
                    <thead>
                      <tr>
                        <th>Věk</th>
                        <th className="text-right">Cena</th>
                        <th>Akce</th>
                      </tr>
                    </thead>
                    <tbody>
                      {prices.map(price => (
                        <tr key={price.id}>
                          <td>
                            <AgeRangeLabel ageFrom={price.ageFrom} />
                          </td>
                          <td className="text-right">
                            <MoneyLabel amount={price.price} />
                          </td>
                          <td>
                            <DisciplineConfigPriceDeleteModal disciplineConfigId={id} price={price}>
                              {(handleModalOpen, title) => (
                                <IconSolidTrashAlt
                                  onClick={handleModalOpen}
                                  title={title}
                                  className="action-icon text-danger"
                                />
                              )}
                            </DisciplineConfigPriceDeleteModal>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                )}

                {!prices.length && <NoDataWithMarginBottom text="Žádné ceny" />}
              </Col>

              <Col sm={12} className="mb-3">
                <DisciplineConfigPriceNewModal key={id} title="Přidání ceny oddílu" disciplineConfigId={id}>
                  {(handleModalOpen, title) => (
                    <Button block size="sm" onClick={handleModalOpen} title={title}>
                      Přidat
                    </Button>
                  )}
                </DisciplineConfigPriceNewModal>
              </Col>
            </Row>

            <h4>Cvičitelé</h4>
            <Row>
              <Col sm={12}>
                {!!trainers.length && (
                  <Table size="sm" striped>
                    <thead>
                      <tr>
                        <th>Člen</th>
                        <th>Role</th>
                        <th>Akce</th>
                      </tr>
                    </thead>
                    <tbody>
                      {trainers.map(trainer => (
                        <tr key={trainer.id}>
                          <td>
                            <ClientNameLink client={trainer.client} />
                          </td>
                          <td>
                            <SokolRoleLabel role={trainer.role} />
                          </td>
                          <td>
                            <DisciplineTrainerDetailDeleteModal disciplineConfigId={id} trainer={trainer}>
                              {(handleModalOpen, title) => (
                                <IconSolidTrashAlt
                                  onClick={handleModalOpen}
                                  title={title}
                                  className="action-icon text-danger"
                                />
                              )}
                            </DisciplineTrainerDetailDeleteModal>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                )}

                {!trainers.length && <NoDataWithMarginBottom text="Žádní cvičitelé" />}
              </Col>

              <Col sm={12}>
                <DisciplineTrainerDetailEditModal
                  key={id}
                  title="Přidání role oddílu"
                  periodId={period.id}
                  disciplineConfigId={id}
                >
                  {(handleModalOpen, title) => (
                    <Button block size="sm" onClick={handleModalOpen} title={title}>
                      Přidat
                    </Button>
                  )}
                </DisciplineTrainerDetailEditModal>
              </Col>
            </Row>
          </Col>

          <Col xs={12} lg={6} className="mt-3 mt-lg-0">
            <h4>Platby členů</h4>
            {!!paymentsDiscipline.length && (
              <>
                <FormGroup row>
                  <Col sm={12} xl={4} className="text-muted">
                    Obsazenost
                  </Col>
                  <Col sm={12} xl={8}>
                    <CapacityProgress value={occupancy.paidCount} max={capacity} />
                  </Col>
                </FormGroup>

                <Table size="sm" striped>
                  <thead>
                    <tr>
                      <th>Člen</th>
                      <th className="text-right">Částka</th>
                    </tr>
                  </thead>
                  <tbody>
                    {paymentsDiscipline.map(paymentDiscipline => (
                      <tr key={paymentDiscipline.client.id}>
                        <td>
                          <ClientNameLink client={paymentDiscipline.client} />
                        </td>
                        <td className="text-right">
                          <OrderLink orderNumber={paymentDiscipline.orderDiscipline.orderTotal.orderNumber}>
                            <MoneyLabel amount={paymentDiscipline.orderDiscipline.priceDiscounted} />
                          </OrderLink>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>

                <a href={getServerBasename() + `/rest/export/attendance/${id}`} download>
                  Docházka
                </a>
              </>
            )}

            {!paymentsDiscipline.length && <NoData text="Žádné platby" />}
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}
