import { Query } from '@apollo/client/react/components';
import FilterableList from '@monorepo/common/component/FilterableList';
import React, { useState } from 'react';
import { PersonsDocument, PersonsQuery, PersonsQueryVariables } from '../../graphql-operations';
import PersonSwitchNameLabel from './switch/PersonSwitchNameLabel';

interface PersonFilterableListProps {
  personId: string;
  url: string;
}

export default function PersonFilterableList({ personId, url }: PersonFilterableListProps) {
  const [filter, setFilter] = useState('');
  return (
    <Query<PersonsQuery, PersonsQueryVariables> query={PersonsDocument} variables={{ filter }}>
      {({ data, loading, error, fetchMore }) => {
        const persons = (data || {}).persons;

        return (
          <FilterableList
            items={persons && persons.items}
            loading={loading}
            error={error}
            selectedId={personId}
            idExtractor={person => person.id}
            urlExtractor={person => `${url}/${person.id}`}
            filter={filter}
            hasNext={!!persons?.next}
            onFilterChange={setFilter}
            onMoreClick={() =>
              persons?.next &&
              fetchMore({
                variables: {
                  page: persons.next.page,
                  pageSize: persons.next.size
                },
                updateQuery: (prev, { fetchMoreResult, ...rest }) => {
                  if (!fetchMoreResult) {
                    return prev;
                  }
                  return {
                    ...fetchMoreResult,
                    persons: {
                      ...fetchMoreResult.persons,
                      items: [...prev.persons.items, ...fetchMoreResult.persons.items]
                    }
                  };
                }
              })
            }
          >
            {person => <PersonSwitchNameLabel person={person} />}
          </FilterableList>
        );
      }}
    </Query>
  );
}
