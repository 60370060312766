import DateLabel from '@monorepo/common/component/DateLabel';
import DisciplineNameLabel from '@monorepo/common/component/discipline/DisciplineNameLabel';
import ValidatedInputGroup from '@monorepo/common/component/form/ValidatedInputGroup';
import { EditFormProps, PropChangedHandler, Validator } from '@monorepo/common/component/modal/EditModal';
import MoneyLabel from '@monorepo/common/component/MoneyLabel';
import MultiBadgeLabel, { BADGE_ALIGNMENT } from '@monorepo/common/component/MultiBadgeLabel';
import OrderLink from '@monorepo/common/component/order/OrderLink';
import PeriodLabel from '@monorepo/common/component/PeriodLabel';
import { integerNormalizer2 } from '@monorepo/common/util/Normalizer';
import { validateStringField } from '@monorepo/common/util/Validation';
import React from 'react';
import { Col, Form, FormGroup, Label } from 'reactstrap';
import { FullPeriodFragment } from '../../graphql-operations';

export const validate: Validator<EditFormData> = (data, touched) => {
  const errors = {};
  validateStringField('purpose', 'Účel platby', data, errors, touched, {
    required: true,
    text: { length: { max: 500 } }
  });
  validateStringField('amount', 'Částka', data, errors, touched, { required: true, number: { range: { min: 0 } } });
  return errors;
};

export interface EditFormData {
  id: string;
  clientId: string;
  receiptId: string;
  purpose: string;
  amount: number;
  created: string;
  period: FullPeriodFragment;
  paymentDiscipline: {
    id: string;
    disciplineConfig: {
      id: string;
      discipline: {
        id: string;
        name: string;
      };
      period: {
        id: string;
        year: number;
        number: number;
      };
    };
    orderDiscipline: {
      id: string;
      priceDiscounted: number;
      orderTotal: {
        id: string;
        orderNumber: string;
      };
    };
  };
}

interface InsuranceReceiptDetailEditProps extends EditFormProps<EditFormData> {}

export default function InsuranceReceiptDetailEdit({
  validation,
  onPropTouch,
  onPropChange,
  ...data
}: InsuranceReceiptDetailEditProps) {
  const badges = [
    'Všestranné cvičení rodičů a dětí.',
    'Všestranné cvičení rodičů, dětí a předškolních dětí.',
    'Všestranné cvičení předškolních dětí.',
    'Všestranné cvičení žáků a žákyň.',
    'Rehabilitační cvičení pro seniory.',
    'Rehabilitační cvičení na balančních míčích.'
  ].map(template => ({
    text: template,
    enabled: template !== data.purpose,
    onClick: (callback: PropChangedHandler<EditFormData, 'purpose'>) => callback('purpose', template)
  }));

  return (
    <Form>
      {/* id */}
      {data.receiptId && (
        <FormGroup row>
          <Col sm={12}>Číslo dokladu</Col>
          <Col sm={12}>{data.receiptId}</Col>
        </FormGroup>
      )}

      {/* created */}
      {data.created && (
        <FormGroup row>
          <Col sm={12}>Datum vytvoření</Col>
          <Col sm={12}>
            <DateLabel dateString={data.created} />
          </Col>
        </FormGroup>
      )}

      {/* period */}
      <FormGroup row>
        <Col sm={12}>Období</Col>
        <Col sm={12}>{data.period && <PeriodLabel period={data.period} />}</Col>
      </FormGroup>

      {/* payment discipline */}
      {data.paymentDiscipline && (
        <FormGroup row>
          <Col sm={12}>Oddíl</Col>
          <Col sm={12}>
            <DisciplineNameLabel discipline={data.paymentDiscipline.disciplineConfig.discipline} /> (
            <OrderLink orderNumber={data.paymentDiscipline.orderDiscipline.orderTotal.orderNumber}>
              <MoneyLabel amount={data.paymentDiscipline.orderDiscipline.priceDiscounted} />
            </OrderLink>
            )
          </Col>
        </FormGroup>
      )}

      {/* purpose */}
      <FormGroup row>
        {/* purpose templates */}
        <MultiBadgeLabel
          label="Účel platby"
          property="purpose"
          badges={badges}
          alignment={BADGE_ALIGNMENT.LEFT}
          onPropTouch={onPropTouch}
          onPropChange={onPropChange}
        />

        <Col sm={12}>
          <ValidatedInputGroup
            property="purpose"
            type="textarea"
            data={data}
            validation={validation}
            onPropChange={onPropChange}
            onPropTouch={onPropTouch}
          />
        </Col>
      </FormGroup>

      {/* amount */}
      <FormGroup row>
        <Label sm={12}>Částka</Label>
        <Col sm={12}>
          <ValidatedInputGroup
            property="amount"
            currency="Kč"
            normalizer2={integerNormalizer2}
            data={data}
            validation={validation}
            onPropChange={onPropChange}
            onPropTouch={onPropTouch}
          />
        </Col>
      </FormGroup>
    </Form>
  );
}
