import { RefetchQueriesFunction, useMutation } from '@apollo/client';
import StatelessConfirmModal from '@monorepo/common/component/modal/StatelessConfirmModal';
import useConfirmModal, { ControlledConfirmModalProps } from '@monorepo/common/component/modal/useConfirmModal';
import MoneyLabel, { formatMoney } from '@monorepo/common/component/MoneyLabel';
import React, { MouseEvent } from 'react';
import {
  PayOrderByCashDocument,
  PayOrderByCashMutation,
  PayOrderByCashMutationVariables
} from '../../../../graphql-operations';

export function useOrderTransactionCashNewModal(orderId: string, refetchQueries: RefetchQueriesFunction) {
  const [payOrderByCash] = useMutation<PayOrderByCashMutation, PayOrderByCashMutationVariables>(
    PayOrderByCashDocument,
    {
      refetchQueries
    }
  );

  const { modalProps, confirm } = useConfirmModal();

  function openModal(e: MouseEvent) {
    e.preventDefault();

    confirm().then(
      // () => console.log('payOrderByCash', orderId, price)
      () =>
        payOrderByCash({
          variables: {
            orderId
          }
        })
    );
  }

  return { modalProps, openModal };
}

interface OrderTransactionCashNewModalProps extends ControlledConfirmModalProps {
  price: number;
}

export default function OrderTransactionCashNewModal({
  isOpen,
  onConfirm,
  onCancel,

  price
}: OrderTransactionCashNewModalProps) {
  return (
    <StatelessConfirmModal
      isOpen={isOpen}
      title="Zaplacení hotově"
      confirmButtonText={'Zaplatit ' + formatMoney(price)}
      onCancelClick={onCancel}
      onConfirmClick={onConfirm}
    >
      <span>
        Opravdu zaplatit <MoneyLabel amount={price} /> ?
      </span>
    </StatelessConfirmModal>
  );
}
