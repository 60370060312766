import { gql, useQuery } from "@apollo/client";
import { Period } from "../type/Types";

const GET_APP_CONFIG = gql`
  query appConfig {
    appConfig {
      id
      currentPeriod {
        id
        year
        number
      }
      signUpDisabledMessage
    }
  }
`;

export interface AppConfig {
  id: string;
  currentPeriod: Period;
}

interface AppConfigQuery {
  appConfig: AppConfig;
}

export function useAppConfig() {
  const { data, ...rest } = useQuery<AppConfigQuery>(GET_APP_CONFIG);
  return {
    appConfig: data?.appConfig,
    ...rest,
  };
}
