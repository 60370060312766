import QueryWithLoader from '@monorepo/common/component/QueryWithLoader';
import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import PeriodAppConfigList from '../component/appConfig/PeriodAppConfigList';
import { PeriodAppConfigsDocument, PeriodAppConfigsQuery, PeriodAppConfigsQueryVariables } from '../graphql-operations';

export default function AppConfigPage() {
  return (
    <Container>
      <Row>
        {/*Period app configs*/}
        <Col lg={6} xl={6}>
          <QueryWithLoader<PeriodAppConfigsQuery, PeriodAppConfigsQueryVariables> query={PeriodAppConfigsDocument}>
            {({ data }) => {
              const periodAppConfigs = data.periodAppConfigs.items;
              return <PeriodAppConfigList periodAppConfigs={periodAppConfigs} />;
            }}
          </QueryWithLoader>
        </Col>
      </Row>
    </Container>
  );
}
