const CONTENT_TYPE = {
  "X-WWW-FORM-URLENCODED": "application/x-www-form-urlencoded",
  JSON: "application/json",
  JSON_UTF8: "application/json;charset=UTF-8",
};

enum Method {
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
}

function createFormData(data: Record<string, any>) {
  return Object.keys(data)
    .map((key) => {
      return encodeURIComponent(key) + "=" + encodeURIComponent(data[key]);
    })
    .join("&");
}

export function xhrGet(url: string) {
  return xhrRest(url, Method.GET, undefined, undefined);
}

export function xhrPut(url: string, data?: Record<string, any>) {
  return xhrRest(url, Method.PUT, CONTENT_TYPE.JSON, data);
}

export function xhrPost(url: string, data?: Record<string, any>) {
  return xhrRest(url, Method.POST, CONTENT_TYPE.JSON, data);
}

export function xhrFormPost(url: string, data?: Record<string, any>) {
  return xhrRest(url, Method.POST, CONTENT_TYPE["X-WWW-FORM-URLENCODED"], data);
}

function xhrRest(
  url: string,
  method: Method,
  contentType: string | undefined,
  body?: Record<string, any>
) {
  const credentials: RequestCredentials =
    process.env.NODE_ENV === "production" ? "same-origin" : "include";

  const bodyTransformed =
    contentType === CONTENT_TYPE["X-WWW-FORM-URLENCODED"]
      ? body && createFormData(body)
      : body && JSON.stringify(body);

  const headers = new Headers({
    Accept: CONTENT_TYPE.JSON,
  });

  if (contentType) {
    headers.append("Content-Type", contentType);
  }

  return fetch(url, {
    method, // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    // mode: 'no-cors', // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials, // include, *same-origin, omit
    headers,
    // redirect: 'follow', // manual, *follow, error
    referrer: "no-referrer", // no-referrer, *client
    body: bodyTransformed,
  }).then(
    (response) =>
      new Promise<Response>((resolve, reject) => {
        if (!response.ok) {
          console.error(
            `Request failed, ${response.status}: ${response.statusText}`
          );
          reject(response);
        } else {
          resolve(response);
        }
      })
  );
}
