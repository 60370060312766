import React from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import OrderDetail from '../component/order/OrderDetail';
import OrderFilterableList from '../component/order/OrderFilterableList';

interface OrdersPageProps {
  match: RouteComponentProps;
}

export default function OrdersPage({ match: { match } }: OrdersPageProps) {
  return (
    <Container>
      <Row>
        <Col xs={3}>
          <div className="position-fixed-scrollable">
            <Route
              path={`${match.path}/:orderNumber?`}
              render={props => <OrderFilterableList orderNumber={props.match.params.orderNumber} url={match.url} />}
            />
          </div>
        </Col>

        <Col xs={9}>
          <Route
            path={`${match.path}/:orderNumber`}
            render={props => <OrderDetail orderNumber={props.match.params.orderNumber} />}
          />
          <Route exact path={match.path} component={OrderDetail} />
        </Col>
      </Row>
    </Container>
  );
}
