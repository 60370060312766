import SexButtonGroup from '@monorepo/common/component/form/SexButtonGroup';
import { EditFormProps, Validator } from '@monorepo/common/component/modal/EditModal';
import { EditRow } from '@monorepo/common/component/modal/EditRow';
import { validateStringField } from '@monorepo/common/util/Validation';
import React from 'react';
import { Col, Form, FormFeedback, FormGroup, Input, Label, Row } from 'reactstrap';

export const validate: Validator<EditFormData> = (data, touched) => {
  const errors = {};
  validateStringField('firstName', 'Jméno', data, errors, touched, { required: true, text: { length: { max: 50 } } });
  validateStringField('lastName', 'Příjmení', data, errors, touched, { required: true, text: { length: { max: 50 } } });
  validateStringField('phone', 'Telefon', data, errors, touched, { number: { range: { min: 0 } } });
  validateStringField('email', 'E-mail', data, errors, touched, { text: { length: { max: 75 }, email: true } });

  return errors;
};

export interface EditFormData {
  id: string;
  degreeBefore?: string;
  degreeAfter?: string;
  firstName: string;
  lastName: string;
  sex: string;
  phone?: number;
  phone2?: number;
  email?: string;
}

interface PersonDetailEditProps extends EditFormProps<EditFormData> {}

export default function PersonDetailEdit({ validation, onPropChange, onPropTouch, ...person }: PersonDetailEditProps) {
  return (
    <Form>
      <Row form>
        <Col sm={12} lg={6}>
          <Row form>
            <Col sm={6}>
              <EditRow
                label="Titul před"
                property="degreeBefore"
                data={person}
                validation={validation}
                onPropTouch={onPropTouch}
                onPropChange={onPropChange}
              />
            </Col>
            <Col sm={6}>
              <EditRow
                label="Titul za"
                property="degreeAfter"
                data={person}
                validation={validation}
                onPropTouch={onPropTouch}
                onPropChange={onPropChange}
              />
            </Col>
          </Row>

          <EditRow
            label="Jméno"
            property="firstName"
            data={person}
            validation={validation}
            onPropTouch={onPropTouch}
            onPropChange={onPropChange}
          />

          <EditRow
            label="Příjmení"
            property="lastName"
            data={person}
            validation={validation}
            onPropTouch={onPropTouch}
            onPropChange={onPropChange}
          />

          <FormGroup row>
            <Label for="sex" sm={12}>
              Pohlaví
            </Label>
            <Col sm={12}>
              <SexButtonGroup
                // @ts-ignore
                sex={person.sex}
                onChange={sex => onPropChange('sex', sex)}
                onBlur={() => onPropTouch('sex')}
              />
              <Input type="hidden" invalid={!!validation.errors.sex} />
              {validation.errors.sex && <FormFeedback>{validation.errors.sex}</FormFeedback>}
            </Col>
          </FormGroup>
        </Col>
        <Col sm={12} lg={6}>
          <Row form>
            <Col sm={6}>
              <EditRow
                label="Telefon"
                property="phone"
                data={person}
                validation={validation}
                onPropTouch={onPropTouch}
                onPropChange={onPropChange}
              />
            </Col>
            <Col sm={6}>
              <EditRow
                label="Telefon2"
                property="phone2"
                data={person}
                validation={validation}
                onPropTouch={onPropTouch}
                onPropChange={onPropChange}
              />
            </Col>
          </Row>

          <EditRow
            label="E-mail"
            property="email"
            data={person}
            validation={validation}
            onPropTouch={onPropTouch}
            onPropChange={onPropChange}
          />
        </Col>
      </Row>
    </Form>
  );
}
