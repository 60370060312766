import React from "react";
import { Col, Progress, ProgressProps, Row } from "reactstrap";

interface CapacityProgressProps extends ProgressProps {
  value: number;
  max: number;
}

export default function CapacityProgress({
  value,
  max,
  ...rest
}: CapacityProgressProps) {
  const maxTmp = max || value;
  const percent = value / maxTmp;
  let color;

  if (percent >= 1) {
    color = "danger";
  } else if (percent > 0.7) {
    color = "warning";
  } else {
    color = "primary";
  }

  return (
    <Row>
      <Col sm="auto">
        <span>
          {value} / {max}
        </span>
      </Col>
      <Col>
        <Progress
          value={value}
          max={maxTmp}
          color={color}
          style={{ height: 24 }}
          {...rest}
        />
      </Col>
    </Row>
  );
}
