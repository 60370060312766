import AutocompleteInput from '@monorepo/common/component/form/AutocompleteInput';
import { IconSolidEnvelope, IconSolidMobileAlt } from '@monorepo/common/component/Icons';
import PersonNameLabel, { PersonDataType } from '@monorepo/common/component/person/PersonNameLabel';
import React, { FocusEventHandler } from 'react';
import {
  PersonsAndClientsDocument,
  PersonsAndClientsQuery,
  PersonsAndClientsQueryVariables
} from '../../../graphql-operations';
import { ClientDropdownItem, ClientSelectedItem, ClientType } from '../../client/ClientAutocompleteInput';

interface ExtendedPersonDataType extends PersonDataType {
  phone?: number | null;
  email?: string | null;
}

interface PersonType {
  id: string;
  personData: ExtendedPersonDataType;
}

type PersonSwitch = ClientType | PersonType;

function isClientType(person: PersonSwitch): person is ClientType {
  return !!(person as ClientType).clientData;
}

interface PersonSwitchDropdownItemProps {
  person: PersonSwitch;
}

function PersonSwitchDropdownItem({ person }: PersonSwitchDropdownItemProps) {
  if (isClientType(person)) {
    return <ClientDropdownItem client={person} />;
  }
  return (
    <span>
      <PersonNameLabel person={person} />{' '}
      <span className="text-muted">
        (<IconSolidMobileAlt /> {person.personData.phone}, <IconSolidEnvelope /> {person.personData.email})
      </span>
    </span>
  );
}

interface PersonSwitchSelectedItemProps {
  person: PersonSwitch;
}

function PersonSwitchSelectedItem({ person }: PersonSwitchSelectedItemProps) {
  if (isClientType(person)) {
    return <ClientSelectedItem client={person} />;
  }
  return (
    <abbr title={`tel: ${person.personData.phone}, email: ${person.personData.email}`}>
      <PersonNameLabel person={person} />
    </abbr>
  );
}

interface PersonSwitchAutocompleteInputProps<T> {
  ageFrom?: number;
  ageTo?: number;
  selected?: T | null;

  disabled?: boolean;
  onSelect: (item: T | undefined) => void;
  onBlur: FocusEventHandler<HTMLElement>;
}

export default function PersonSwitchAutocompleteInput({
  ageFrom,
  ageTo,
  ...rest
}: PersonSwitchAutocompleteInputProps<PersonType>) {
  return (
    <AutocompleteInput<PersonType, PersonsAndClientsQuery, PersonsAndClientsQueryVariables>
      {...rest}
      placeholder="Vyberte osobu"
      query={PersonsAndClientsDocument}
      queryVariables={{
        ageFrom: ageFrom,
        ageTo: ageTo
      }}
      item2nameSelected={person => <PersonSwitchSelectedItem person={person} />}
      item2nameDropdown={person => <PersonSwitchDropdownItem person={person} />}
      result2items={data => data.persons.items}
    />
  );
}
