export function valueOrEmpty(value: string | number | undefined | null) {
  if (value === undefined || value === null) {
    return "";
  }
  return value;
}

export function isDef<T>(value: T | undefined | null): value is T {
  return value !== undefined && value !== null;
}

const NUMERIC_REGEX = /^-?\d+$/;

export function isNumeric(text: string | number) {
  return NUMERIC_REGEX.test(text.toString());
}
