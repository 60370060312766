import ClientNameLabel from '@monorepo/common/component/client/ClientNameLabel';
import DeleteModal from '@monorepo/common/component/modal/DeleteModal';
import { ChildrenRenderProp } from '@monorepo/common/component/modal/EditModal';
import { Client } from '@monorepo/common/type/Types';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { DeleteClientDocument } from '../../graphql-operations';

function data2text(client: Client) {
  return (
    <div>
      Člen: <ClientNameLabel client={client} />
    </div>
  );
}

function data2variables(client: Client) {
  return {
    id: client.id
  };
}

interface ClientDetailDeleteModalProps {
  client: Client;
  children: ChildrenRenderProp;
}

export default function ClientDetailDeleteModal({ client, children }: ClientDetailDeleteModalProps) {
  const history = useHistory();

  const handleCompleted = useCallback(
    (data, close, apolloClient) => {
      history.push('/clients');
      apolloClient.resetStore();
    },
    [history]
  );

  return (
    <DeleteModal
      title="Smazání člena"
      data={client}
      data2text={data2text}
      data2variables={data2variables}
      mutation={DeleteClientDocument}
      onCompleted={handleCompleted}
      children={children}
    />
  );
}
