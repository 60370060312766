import NoData from '@monorepo/common/component/NoData';
import QueryWithLoader from '@monorepo/common/component/QueryWithLoader';
import React from 'react';
import { match, Redirect } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import { ClientQueryVariables, TokenDocument, TokenQuery } from '../../graphql-operations';
import TokenDetailView, { NewTokenIcon } from './TokenDetailView';

interface TokenDetailProps {
  match: match<TokenDetailUrlParams>;
}

interface TokenDetailUrlParams {
  tokenId?: string;
}

export default function TokenDetail({ match }: TokenDetailProps) {
  const { tokenId } = match.params;
  return (
    <>
      {!!tokenId && (
        <QueryWithLoader<TokenQuery, ClientQueryVariables> query={TokenDocument} variables={{ id: tokenId }}>
          {({ data }) => {
            const token = data.token;

            if (!token) {
              return <Redirect to="/page-not-found" />;
            }

            return (
              <>
                <TokenDetailView token={token} />
              </>
            );
          }}
        </QueryWithLoader>
      )}

      {!tokenId && (
        <>
          <Container>
            <Row>
              <Col sm={12}>
                <NewTokenIcon />
              </Col>
            </Row>
          </Container>
          <NoData text="Vyberte známku" />
        </>
      )}
    </>
  );
}
