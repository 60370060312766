export function date2ymd(date: Date) {
  let month = "" + (date.getMonth() + 1);
  let day = "" + date.getDate();
  const year = date.getFullYear();

  if (month.length < 2) {
    month = "0" + month;
  }

  if (day.length < 2) {
    day = "0" + day;
  }

  return [year, month, day].join("-");
}

export function getAge(dateString: string) {
  const today = new Date();
  const birthDate = new Date(dateString);

  let yearDiff = today.getFullYear() - birthDate.getFullYear();
  const monthDiff = today.getMonth() - birthDate.getMonth();
  if (
    monthDiff < 0 ||
    (monthDiff === 0 && today.getDate() < birthDate.getDate())
  ) {
    yearDiff--;
  }
  return yearDiff;
}

export const DAYS_OF_WEEK = ["Pondělí", "Úterý", "Středa", "Čtvrtek", "Pátek"];
