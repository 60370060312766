import EditModal, {
  BaseEditModalProps,
  DiffExtractor,
  SaveCompletedHandler,
  VariablesExtractor
} from '@monorepo/common/component/modal/EditModal';
import QueryWithLoader from '@monorepo/common/component/QueryWithLoader';
import { failOnMissing } from '@monorepo/common/util/Validation';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import {
  LastPeriodsDocument,
  Period,
  SaveDisciplineConfigDocument,
  SaveDisciplineConfigMutation,
  SaveDisciplineConfigMutationVariables
} from '../../../graphql-operations';
import { ClientType } from '../../client/ClientAutocompleteInput';
import DisciplineConfigDetailEdit, { EditFormData, validate } from './DisciplineConfigDetailEdit';

const data2variables: VariablesExtractor<EditFormData, SaveDisciplineConfigMutationVariables> = data => {
  const { id, leader, birthDateFrom, birthDateTo } = data;

  return {
    id,
    disciplineId: failOnMissing(data, 'disciplineId'),
    periodId: failOnMissing(data, 'period').id,
    leaderId: leader && leader.id,
    price: id ? undefined : Number(failOnMissing(data, 'price')),
    capacity: Number(failOnMissing(data, 'capacity')),
    birthDateFrom: birthDateFrom,
    birthDateTo: birthDateTo
  };
};

const change2diff: DiffExtractor<EditFormData> = (name, value, diff) => {
  // delete leader on period change
  if (name === 'period') {
    diff.leader = undefined;
  }
  return diff;
};

interface DisciplineConfigDetailEditModalProps extends BaseEditModalProps {
  title: string;
  disciplineId: string;
  disciplineConfig?: {
    id: string;
    period: Period;
    leader?: ClientType | null;
    capacity: number;
    birthDateFrom?: string | null;
    birthDateTo?: string | null;
  };
}

export default function DisciplineConfigDetailEditModal({
  title,
  disciplineId,
  disciplineConfig,
  children
}: DisciplineConfigDetailEditModalProps) {
  const history = useHistory();

  const handleCompleted: SaveCompletedHandler<SaveDisciplineConfigMutation, EditFormData> = useCallback(
    (data, apolloClient) => {
      // go to detail only when creating new item
      if (!disciplineConfig) {
        history.push('/disciplines/' + disciplineId + '/' + data.saveDisciplineConfig.period.id);
        apolloClient.resetStore();
      }
    },
    [disciplineConfig, disciplineId, history]
  );

  return (
    <QueryWithLoader query={LastPeriodsDocument}>
      {({ data }) => {
        return (
          <EditModal
            title={title}
            tag={DisciplineConfigDetailEdit}
            initialData={{
              disciplineId,
              periods: data.lastPeriods,
              ...disciplineConfig
            }}
            change2diff={change2diff}
            data2variables={data2variables}
            validate={validate}
            mutation={SaveDisciplineConfigDocument}
            onCompleted={handleCompleted}
            // refetchQueries={this.refetchQueries}
            children={children}
          />
        );
      }}
    </QueryWithLoader>
  );
}
