import React from "react";
import { User } from "../type/Types";

export interface Credentials {
  username: string;
  password: string;
}

export enum AuthenticationStatus {
  AUTHENTICATED = "AUTHENTICATED",
  ANONYMOUS = "ANONYMOUS",
}

export interface Authentication {
  status: AuthenticationStatus | undefined;
  user: User | undefined;
  login: (credentials: Credentials) => Promise<any>;
  loginClient: (user: User, redirect: boolean) => void;
  logout: () => Promise<any>;
  maintenance: () => void;
  client: any;
}

export const AuthenticationContext = React.createContext<Authentication>({
  status: undefined,
  user: undefined,
  login: (credentials) => Promise.reject("Uninitialized"),
  loginClient: () => {},
  logout: () => Promise.reject("Uninitialized"),
  maintenance: () => {},
  client: null,
});

export const AuthenticationProvider = AuthenticationContext.Provider;

export default AuthenticationContext;
