import React from "react";
import { Col, FormGroup, Label } from "reactstrap";
import * as Yup from "yup";
import FormikInputGroup from "../../form/FormikInputGroup";
import MoneyLabel, { formatMoney } from "../../MoneyLabel";

export type TokenOfferPriceEditFormType = Yup.InferType<
  ReturnType<typeof validationSchemaFactory>
>;

export function createInitialValues(
  priceFrom: number
): TokenOfferPriceEditFormType {
  return {
    price: priceFrom,
  };
}

export function validationSchemaFactory(priceFrom: number) {
  return Yup.object({
    price: Yup.number()
      .typeError("Částka musí být celé číslo")
      .integer("Částka musí být celé číslo")
      .min(priceFrom, `Částka musí být alespoň ${formatMoney(priceFrom)}`)
      .max(5000, `Částka může být maximálně ${formatMoney(5000)}`)
      .required("Musíte vyplnit částku"),
  }).required();
}

interface TokenOfferPriceEditFormProps {
  priceFrom: number;
}

export default function TokenOfferPriceEditForm({
  priceFrom,
}: TokenOfferPriceEditFormProps) {
  return (
    <>
      <FormGroup>
        <Label sm={12}>
          Cena (min <MoneyLabel amount={priceFrom} />)
        </Label>
        <Col sm={12}>
          <FormikInputGroup name="price" currency="Kč" />
        </Col>
      </FormGroup>
    </>
  );
}
