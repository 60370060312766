import EditModal, {
  BaseEditModalProps,
  SaveCompletedHandler,
  VariablesExtractor
} from '@monorepo/common/component/modal/EditModal';
import QueryWithLoader from '@monorepo/common/component/QueryWithLoader';
import { failOnMissing } from '@monorepo/common/util/Validation';
import {
  LastPeriodsDocument,
  SaveTokenDocument,
  SaveTokenMutation,
  SaveTokenMutationVariables
} from 'graphql-operations';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import TokenDetailEdit, { EditFormData, validate } from './TokenDetailEdit';
import { TokenType } from './TokenDetailView';

interface TokenDetailEditModalProps extends BaseEditModalProps {
  title: string;
  token?: TokenType;
}

const data2variables: VariablesExtractor<EditFormData, SaveTokenMutationVariables> = data => {
  return {
    id: data.id,
    periodId: failOnMissing(data, 'period').id,
    name: failOnMissing(data, 'name'),
    priceFrom: failOnMissing(data, 'priceFrom')
  };
};

export default function TokenDetailEditModal({ title, token, children }: TokenDetailEditModalProps) {
  const history = useHistory();

  const handleCompleted: SaveCompletedHandler<SaveTokenMutation, EditFormData> = useCallback(
    (data, apolloClient) => {
      // go to detail only when creating new item
      if (!token) {
        history.push('/tokens/' + data.saveToken.id);
        apolloClient.resetStore();
      }
    },
    [token, history]
  );
  return (
    <QueryWithLoader query={LastPeriodsDocument}>
      {({ data }) => {
        return (
          <EditModal
            title={title}
            tag={TokenDetailEdit}
            initialData={{
              periods: data.lastPeriods,
              ...token
            }}
            data2variables={data2variables}
            validate={validate}
            mutation={SaveTokenDocument}
            onCompleted={handleCompleted}
            children={children}
          />
        );
      }}
    </QueryWithLoader>
  );
}
