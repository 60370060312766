import { IconSolidPlus } from '@monorepo/common/component/Icons';
import NoData from '@monorepo/common/component/NoData';
import QueryWithLoader from '@monorepo/common/component/QueryWithLoader';
import React from 'react';
import { match, Redirect } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import { DisciplineDocument, DisciplineQuery, DisciplineQueryVariables } from '../../graphql-operations';
import DisciplineDetailEditModal from './DisciplineDetailEditModal';
import DisciplineDetailPeriodView from './DisciplineDetailPeriodView';

const NewDisciplineCol = () => (
  <Col>
    <DisciplineDetailEditModal title="Nový oddíl">
      {(handleModalOpen, title) => (
        <h2 className="text-right">
          <IconSolidPlus onClick={handleModalOpen} title={title} className="action-icon text-primary" />
        </h2>
      )}
    </DisciplineDetailEditModal>
  </Col>
);

interface DisciplineDetailProps {
  match: match<DisciplineDetailUrlParams>;
}

interface DisciplineDetailUrlParams {
  disciplineId?: string;
}

export default function DisciplineDetail({ match }: DisciplineDetailProps) {
  const { disciplineId } = match.params;
  return (
    <>
      {!!disciplineId && (
        <QueryWithLoader<DisciplineQuery, DisciplineQueryVariables>
          query={DisciplineDocument}
          variables={{ id: disciplineId }}
        >
          {({ data }) => {
            const discipline = data.discipline;

            if (!discipline) {
              return <Redirect to="/page-not-found" />;
            }

            return <DisciplineDetailPeriodView discipline={discipline} match={match} />;
          }}
        </QueryWithLoader>
      )}

      {!disciplineId && (
        <>
          <Container>
            <Row>
              <NewDisciplineCol />
            </Row>
          </Container>
          <NoData text="Vyberte oddíl" />
        </>
      )}
    </>
  );
}
