import CommonButtonGroup from '@monorepo/common/component/form/CommonButtonGroup';
import ValidatedInputGroup from '@monorepo/common/component/form/ValidatedInputGroup';
import { EditFormProps, Validator } from '@monorepo/common/component/modal/EditModal';
import PeriodLabel from '@monorepo/common/component/PeriodLabel';
import { integerNormalizer2 } from '@monorepo/common/util/Normalizer';
import { validateAnyField, validateStringField } from '@monorepo/common/util/Validation';
import React from 'react';
import { Col, Form, FormGroup, Label } from 'reactstrap';
import { Period } from '../../graphql-operations';

export const validate: Validator<EditFormData> = (data, touched) => {
  const errors = {};
  validateAnyField('period', 'Období', data, errors, touched, { required: true });

  validateStringField('name', 'Název', data, errors, touched, { required: true, text: { length: { max: 50 } } });

  validateStringField('priceFrom', 'Minimální cena', data, errors, touched, {
    required: true,
    number: { range: { min: 0 } }
  });

  return errors;
};

export interface EditFormData {
  id: string;
  period: Period;
  periods: Period[];
  name: string;
  priceFrom: number;
}

interface TokenDetailEditProps extends EditFormProps<EditFormData> {}

export default function TokenDetailEdit({
  validation,
  onPropTouch,
  onPropChange,
  periods,
  ...token
}: TokenDetailEditProps) {
  const { id, period } = token;
  return (
    <Form>
      {/* Period */}
      {!id && (
        <FormGroup row>
          <Label sm={12} md={3}>
            Období
          </Label>
          <Col sm={12} md={9} className="text-md-right">
            <CommonButtonGroup
              items={periods}
              selectedItem={period}
              item2label={p => <PeriodLabel period={p} />}
              item2id={p => p.id}
              error={validation.errors.period}
              onChange={p => onPropChange('period', p)}
              onBlur={() => onPropTouch('period')}
            />
          </Col>
        </FormGroup>
      )}
      {id && period && (
        <FormGroup row>
          <Col sm={12} md={3}>
            Období
          </Col>
          <Col sm={12} md={9} className="text-md-right">
            <PeriodLabel period={period} />
          </Col>
        </FormGroup>
      )}

      {/* Name */}
      <FormGroup row>
        <Label sm={12} md={3}>
          Název
        </Label>
        <Col sm={12} md={9}>
          <ValidatedInputGroup
            property="name"
            data={token}
            placeholder="Zadejte název"
            validation={validation}
            onPropChange={onPropChange}
            onPropTouch={onPropTouch}
          />
        </Col>
      </FormGroup>

      {/* Price from */}
      <FormGroup row>
        <Label sm={12} md={3}>
          Minimální cena
        </Label>
        <Col sm={12} md={9} className="text-md-right">
          <ValidatedInputGroup
            property="priceFrom"
            data={token}
            placeholder="Zadejte minimální cenu"
            currency="Kč"
            normalizer={integerNormalizer2}
            validation={validation}
            onPropChange={onPropChange}
            onPropTouch={onPropTouch}
          />
        </Col>
      </FormGroup>
    </Form>
  );
}
