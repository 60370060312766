import MoneyLabel from '@monorepo/common/component/MoneyLabel';
import _range from 'lodash-es/range';
import React from 'react';
import { Card, CardBody, CardHeader, Table } from 'reactstrap';
import { sumStats } from './StatsAll';

export interface StatPaymentStampType {
  amount: number;
  count: number;
  sum: number;
}

interface StatsPaymentStampProps {
  statsPaymentStamp: StatPaymentStampType[];
  maxRows: number;
}

export default function StatsPaymentStamp({ statsPaymentStamp, maxRows }: StatsPaymentStampProps) {
  const total = sumStats(statsPaymentStamp);

  return (
    <Card>
      <CardHeader tag="h4" className="text-center">
        Platby známek
      </CardHeader>
      <CardBody>
        <Table size="sm" striped>
          <thead>
            <tr>
              <th>Známka</th>
              <th>Počet</th>
              <th className="text-right">Součet</th>
            </tr>
          </thead>
          <tbody>
            {/* empty rows for better alignment */}
            {_range(Math.max(0, maxRows - statsPaymentStamp.length)).map(i => (
              <tr key={i}>
                <td colSpan={3}>&nbsp;</td>
              </tr>
            ))}

            {statsPaymentStamp.map(statsPaymentStamp => (
              <tr key={statsPaymentStamp.amount}>
                <td>
                  <MoneyLabel amount={statsPaymentStamp.amount} />
                </td>
                <td>{statsPaymentStamp.count}x</td>
                <td className="text-right">
                  <MoneyLabel amount={statsPaymentStamp.sum} />
                </td>
              </tr>
            ))}

            <tr className="font-weight-bold">
              <td>Celkem</td>
              <td>{total.count}x</td>
              <td className="text-right">
                <MoneyLabel amount={total.sum} />
              </td>
            </tr>
          </tbody>
        </Table>
      </CardBody>
    </Card>
  );
}
