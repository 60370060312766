import { NoDataWithMarginBottom } from '@monorepo/common/component/NoData';
import React from 'react';
import { Card, CardBody, CardHeader } from 'reactstrap';
import CovidAccountBalanceList, { OrderDiscipline } from './CovidAccountBalanceList';

interface CovidAccountBalanceProps {
  covidAccountBalanceInfo: {
    covidAccountBalance: number;
    ordersDiscipline: OrderDiscipline[];
  };
}

export default function CovidAccountBalance({
  covidAccountBalanceInfo: { ordersDiscipline, covidAccountBalance }
}: CovidAccountBalanceProps) {
  return (
    <Card>
      <CardHeader tag="h4" className="text-center">
        Covid účet
      </CardHeader>
      <CardBody>
        {!!ordersDiscipline.length && (
          <>
            <CovidAccountBalanceList ordersDiscipline={ordersDiscipline} covidAccountBalance={covidAccountBalance} />
          </>
        )}

        {!ordersDiscipline.length && <NoDataWithMarginBottom text="Žádné transakce s covid bonusem" />}
      </CardBody>
    </Card>
  );
}
