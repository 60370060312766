import { QueryOptions } from '@apollo/client/core/watchQueryOptions';
import EditModal, { BaseEditModalProps, VariablesExtractor } from '@monorepo/common/component/modal/EditModal';
import { PartialNull } from '@monorepo/common/type/Types';
import { failOnMissing } from '@monorepo/common/util/Validation';
import React from 'react';
import {
  ClientDocument,
  PersonDocument,
  SaveLegalRepresentationDocument,
  SaveLegalRepresentationMutationVariables
} from '../../graphql-operations';
import { ClientType } from '../client/ClientDetailView';
import { PersonSwitch } from '../person/switch/PersonSwitchDetail';
import LegalRepresentationDetailEdit, { EditFormData, validate } from './LegalRepresentationDetailEdit';
import { LegalRepresentationType } from './LegalRepresentationList';

const data2variables: VariablesExtractor<EditFormData, SaveLegalRepresentationMutationVariables> = data => {
  const { id, gdprSignDateSwitch, gdprSignDate } = data;

  return {
    id,
    childId: failOnMissing(data, 'child').id,
    representativeId: failOnMissing(data, 'representative').id,
    gdprSignDate: gdprSignDateSwitch ? gdprSignDate : undefined,
    role: failOnMissing(data, 'role')
  };
};

const refetchQueries = ({ child, representative }: PartialNull<EditFormData>) => {
  const queryOptions: (QueryOptions | undefined | null)[] = [
    child && {
      query: ClientDocument,
      variables: { id: child.id }
    },
    representative && {
      query: PersonDocument,
      variables: { id: representative.id }
    }
  ];

  return queryOptions.filter((q: QueryOptions | undefined | null): q is QueryOptions => q !== undefined && q !== null);
};

interface LegalRepresentationDetailEditModalProps extends BaseEditModalProps {
  title: string;
  child?: ClientType;
  representative?: PersonSwitch;
  legalRepresentation?: LegalRepresentationType;
}

export default function LegalRepresentationDetailEditModal({
  title,
  child,
  representative,
  legalRepresentation,
  children
}: LegalRepresentationDetailEditModalProps) {
  return (
    <EditModal
      title={title}
      tag={LegalRepresentationDetailEdit}
      initialData={{
        child,
        representative,
        ...legalRepresentation
      }}
      data2variables={data2variables}
      validate={validate}
      mutation={SaveLegalRepresentationDocument}
      refetchQueries={refetchQueries}
      children={children}
    />
  );
}
