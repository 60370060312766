import React, { Component, SyntheticEvent } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { ChildrenRenderProp } from "./EditModal";

const initialState = () => {
  return {
    isOpen: false,
  };
};

export interface ConfirmModalProps {
  title: string;
  cancelButtonText: string;
  confirmButtonText: string;
  confirmButtonColor?: string;
  size?: string;
  body: () => React.ReactNode;
  onConfirm: (close: () => void) => void;
  children: ChildrenRenderProp;
}
interface ConfirmModalState {
  isOpen: boolean;
}

export default class ConfirmModal extends Component<
  ConfirmModalProps,
  ConfirmModalState
> {
  constructor(props: ConfirmModalProps) {
    super(props);

    this.handleConfirmClick = this.handleConfirmClick.bind(this);
    this.handleModalOpen = this.handleModalOpen.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);

    this.state = initialState();
  }

  handleConfirmClick(event: SyntheticEvent) {
    event.preventDefault();
    this.props.onConfirm(this.handleModalClose);
  }

  handleModalOpen(event: SyntheticEvent) {
    event.preventDefault();
    this.setState({ isOpen: true });
  }

  handleModalClose() {
    this.setState(initialState());
  }

  render() {
    const {
      title,
      cancelButtonText = "Ne",
      confirmButtonText = "Ano",
      confirmButtonColor = "danger",
      size = "sm",
      body,
      children,
    } = this.props;

    const { isOpen } = this.state;
    return (
      <>
        {children(this.handleModalOpen, title)}
        <Modal
          isOpen={isOpen}
          size={size}
          toggle={this.handleModalClose}
          centered
        >
          <ModalHeader toggle={this.handleModalClose}>{title}</ModalHeader>
          <ModalBody>{body()}</ModalBody>
          <ModalFooter>
            <Button onClick={this.handleModalClose} className="mr-auto">
              {cancelButtonText}
            </Button>
            <Button
              onClick={this.handleConfirmClick}
              color={confirmButtonColor}
            >
              {confirmButtonText}
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}
