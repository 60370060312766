import React, { useState } from "react";
import { Button, Form, FormGroup, Input, Label } from "reactstrap";
import LoginLayout from "../layout/LoginLayout";
import { preventedDefault } from "../util/Input";
import { xhrPut } from "../util/Network";
import { valueOrEmpty } from "../util/Text";

enum STATE {
  INIT = "INIT",
  POSTING = "POSTING",
  ERROR = "ERROR",
  SUCCESS = "SUCCESS",
}

interface ForgottenPasswordPageProps {
  forUsername?: string;
}

export default function ForgottenPasswordPage({
  forUsername,
}: ForgottenPasswordPageProps) {
  const [username, setUsername] = useState(forUsername);
  const [state, setState] = useState(STATE.INIT);

  function handleReset() {
    setState(STATE.POSTING);
    xhrPut(`/rest/auth/password/token/${username}`, undefined)
      .then(() => {
        setState(STATE.SUCCESS);
      })
      .catch((e) => {
        console.error(e);
        setState(STATE.ERROR);
      });
  }

  return (
    <LoginLayout>
      <Form onSubmit={preventedDefault(handleReset)}>
        {/*result message*/}
        {state === STATE.SUCCESS && (
          <span className="text-success">
            Na Váš email byl zaslán odkaz pro dokončení resetu hesla.
          </span>
        )}
        {state === STATE.ERROR && (
          <span className="text-danger">
            Došlo k neznámé chybě. Opakujte požadavek později.
          </span>
        )}

        <FormGroup>
          <Label for="username">
            Email nebo rodné číslo bez lomítka (10 číslic)
          </Label>
          <Input
            type="text"
            name="username"
            id="username"
            value={valueOrEmpty(username)}
            onChange={(e) => setUsername(e.target.value)}
          />
        </FormGroup>
        <Button
          type="submit"
          color="primary"
          disabled={state === STATE.SUCCESS || state === STATE.POSTING}
        >
          Resetovat heslo
        </Button>
      </Form>
    </LoginLayout>
  );
}
