import { ErrorMessage, Field, useField } from "formik";
import React, { ReactNode } from "react";
import { FormFeedback, FormGroup, Input, Label } from "reactstrap";

interface RadioProps<T> {
  name: string;
  value: T;
  disabled: boolean;
}

function Radio<T>({ name, value, disabled }: RadioProps<T>) {
  // @ts-ignore
  const [field, meta, helpers] = useField({
    name,
    type: "radio",
    value,
  });

  const error = meta.touched && meta.error;
  // console.log("Radio", field, meta, helpers, error);

  return (
    <Field
      {...field}
      onChange={() => helpers.setValue(value)}
      as={Input}
      type="radio"
      invalid={!!error}
      disabled={disabled}
    />
  );
}

interface Item<T> {
  id: string;
  label: ReactNode;
  value: T;
  disabled: boolean;
}

interface FormikRadioGroupProps<T> {
  name: string;
  items: Item<T>[];
  disabled?: boolean;
}

export default function FormikRadioGroup<T>({
  name,
  items,
  disabled = false,
}: FormikRadioGroupProps<T>) {
  return (
    <>
      {items.map((item, idx, all) => (
        <FormGroup check key={item.id}>
          <Label check>
            <Radio
              name={name}
              value={item.value}
              disabled={item.disabled || disabled}
            />
            {item.label}

            {/*Show error only at the bottom*/}
            {idx === all.length - 1 && (
              <>
                <FormFeedback>
                  <ErrorMessage name={name} />
                </FormFeedback>
              </>
            )}
          </Label>
        </FormGroup>
      ))}
    </>
  );
}
