import { Query } from '@apollo/client/react/components';
import FilterableList from '@monorepo/common/component/FilterableList';
import PeriodLabel from '@monorepo/common/component/PeriodLabel';
import TokenNameLabel from '@monorepo/common/component/token/TokenNameLabel';
import React, { useState } from 'react';
import { TokensDocument, TokensQuery, TokensQueryVariables } from '../../graphql-operations';

interface TokenFilterableListProps {
  tokenId: string;
  url: string;
}

export default function TokenFilterableList({ tokenId, url }: TokenFilterableListProps) {
  const [filter, setFilter] = useState('');
  return (
    <Query<TokensQuery, TokensQueryVariables> query={TokensDocument} variables={{ filter }}>
      {({ data, loading, error, fetchMore }) => {
        const tokens = (data || {}).tokens;

        return (
          <FilterableList
            items={tokens?.items}
            loading={loading}
            error={error}
            selectedId={tokenId}
            idExtractor={token => token.id}
            urlExtractor={token => `${url}/${token.id}`}
            filter={filter}
            hasNext={!!tokens?.next}
            onFilterChange={setFilter}
            onMoreClick={() =>
              tokens?.next &&
              fetchMore({
                variables: {
                  page: tokens.next.page,
                  pageSize: tokens.next.size
                },
                updateQuery: (prev, { fetchMoreResult }) => {
                  if (!fetchMoreResult) {
                    return prev;
                  }
                  return {
                    ...fetchMoreResult,
                    tokens: {
                      ...fetchMoreResult.tokens,
                      items: [...prev.tokens.items, ...fetchMoreResult.tokens.items]
                    }
                  };
                }
              })
            }
          >
            {token => (
              <>
                <TokenNameLabel token={token} /> (<PeriodLabel period={token.period} />)
              </>
            )}
          </FilterableList>
        );
      }}
    </Query>
  );
}
