import ClientNameLabel from '@monorepo/common/component/client/ClientNameLabel';
import PersonNameLabel, { PersonDataType } from '@monorepo/common/component/person/PersonNameLabel';
import React from 'react';

interface PersonSwitchNameLabelProps {
  person: {
    client?: {
      personData: PersonDataType;
    };
    personData: PersonDataType;
  };
}

export default function PersonSwitchNameLabel({ person }: PersonSwitchNameLabelProps) {
  if (person.client) {
    return <ClientNameLabel client={person.client} />;
  }

  return <PersonNameLabel person={person} />;
}
