import React from "react";
import { EditRow, EditRowProps } from "./EditRow";

export function EditRowLeftRight<T, K extends keyof T>({
  labelProps = { sm: 12, md: 3 },
  dataColProps,
  ...props
}: EditRowProps<T, K>) {
  const dataColPropsNew = {
    md: 9,
    className: "text-md-right",
    ...dataColProps,
  };

  return (
    <EditRow
      {...props}
      labelProps={labelProps}
      dataColProps={dataColPropsNew}
    />
  );
}
