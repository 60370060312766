import React from "react";

export interface TokenNameLabelProps {
  token: {
    name: string;
  };
}

export default function TokenNameLabel({ token }: TokenNameLabelProps) {
  return <span>{token.name}</span>;
}
