import { IconRegularEdit, IconSolidPrint, IconSolidTrashAlt } from '@monorepo/common/component/Icons';
import MoneyLabel from '@monorepo/common/component/MoneyLabel';
import { NoDataWithMarginBottom } from '@monorepo/common/component/NoData';
import OrderLink from '@monorepo/common/component/order/OrderLink';
import PeriodLabel from '@monorepo/common/component/PeriodLabel';
import React from 'react';
import { Button, Card, CardBody, CardHeader, Table } from 'reactstrap';
import { getServerBasename } from '../../util/Url';
import InsuranceReceiptDetailDeleteModal from './InsuranceReceiptDetailDeleteModal';
import InsuranceReceiptDetailEditModal from './InsuranceReceiptDetailEditModal';

export interface InsuranceReceipt {
  id: string;
  receiptId: string;
  created: string;
  purpose: string;
  amount: number;
  period: {
    id: string;
    year: number;
    number: number;
  };
  paymentDiscipline?: {
    id: string;
    disciplineConfig: {
      id: string;
      discipline: {
        id: string;
        name: string;
      };
      period: {
        id: string;
        year: number;
        number: number;
      };
    };
    orderDiscipline: {
      id: string;
      priceDiscounted: number;
      orderTotal: {
        id: string;
        orderNumber: string;
      };
    };
  } | null;
}

interface InsuranceReceiptListProps {
  clientId: string;
  insuranceReceipts: InsuranceReceipt[];
}

export default function InsuranceReceiptList({ clientId, insuranceReceipts }: InsuranceReceiptListProps) {
  return (
    <Card>
      <CardHeader tag="h4" className="text-center">
        Potvrzení pro pojišťovny
      </CardHeader>
      <CardBody>
        {!!insuranceReceipts.length && (
          <Table size="sm" striped>
            <thead>
              <tr>
                <th>Období</th>
                <th className="text-right">Číslo dokladu</th>
                <th className="text-right">Částka</th>
                <th>Akce</th>
              </tr>
            </thead>
            <tbody>
              {insuranceReceipts.map(insuranceReceipt => (
                <tr key={insuranceReceipt.id}>
                  <td>
                    <PeriodLabel period={insuranceReceipt.period} />
                  </td>
                  <td className="text-right">{insuranceReceipt.receiptId}</td>
                  <td className="text-right">
                    {insuranceReceipt.paymentDiscipline ? (
                      <OrderLink
                        orderNumber={insuranceReceipt.paymentDiscipline.orderDiscipline.orderTotal.orderNumber}
                      >
                        <MoneyLabel amount={insuranceReceipt.amount} />
                      </OrderLink>
                    ) : (
                      <MoneyLabel amount={insuranceReceipt.amount} />
                    )}
                  </td>
                  <td>
                    {/*edit*/}
                    <InsuranceReceiptDetailEditModal
                      title="Změna potvrzení pro pojišťovnu"
                      clientId={clientId}
                      insuranceReceipt={insuranceReceipt}
                    >
                      {(handleModalOpen, title) => (
                        <IconRegularEdit onClick={handleModalOpen} title={title} className="action-icon text-primary" />
                      )}
                    </InsuranceReceiptDetailEditModal>

                    {/*print*/}
                    <a
                      href={getServerBasename() + `/rest/export/client/insurance-receipt/${insuranceReceipt.id}`}
                      title="Tisk potvrzení"
                      download
                    >
                      <IconSolidPrint />
                    </a>

                    {/*delete*/}
                    <InsuranceReceiptDetailDeleteModal clientId={clientId} insuranceReceipt={insuranceReceipt}>
                      {(handleModalOpen, title) => (
                        <IconSolidTrashAlt
                          onClick={handleModalOpen}
                          title={title}
                          className="action-icon text-danger"
                        />
                      )}
                    </InsuranceReceiptDetailDeleteModal>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}

        {!insuranceReceipts.length && <NoDataWithMarginBottom text="Žádná potvrzení" />}

        <InsuranceReceiptDetailEditModal key={clientId} title="Nové potvrzení" clientId={clientId}>
          {(handleModalOpen, title) => (
            <Button block size="sm" onClick={handleModalOpen} title={title}>
              Přidat
            </Button>
          )}
        </InsuranceReceiptDetailEditModal>
      </CardBody>
    </Card>
  );
}
