import { useField } from "formik";
import React from "react";
import {
  FormFeedback,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import { InputType } from "reactstrap/es/Input";

interface FormikInputGroupProps {
  name: string;
  type?: InputType;
  placeholder?: string;
  currency?: string;
  alignRight?: boolean;
  disabled?: boolean;
}

export default function FormikInputGroup({
  name,
  type = "text",
  placeholder,
  currency,
  alignRight = false,
  disabled = false,
}: FormikInputGroupProps) {
  const [field, meta] = useField({ name, type });

  const error = meta.touched && meta.error;
  return (
    <InputGroup>
      <Input
        {...field}
        id={name}
        type={type}
        disabled={disabled}
        placeholder={placeholder}
        invalid={!!error}
        className={alignRight || currency ? "text-right" : ""}
      />

      {currency && (
        <InputGroupAddon addonType="append">
          <InputGroupText>{currency}</InputGroupText>
        </InputGroupAddon>
      )}
      {error && <FormFeedback>{error}</FormFeedback>}
    </InputGroup>
  );
}
