import MoneyLabel from '@monorepo/common/component/MoneyLabel';
import React from 'react';
import { Card, CardBody, CardHeader, Table } from 'reactstrap';
import { StatPaymentDisciplineType } from './StatsPaymentDiscipline';
import { StatPaymentStampType } from './StatsPaymentStamp';
import { StatsPaymentTokenType } from './StatsPaymentToken';

interface StatsIntersection {
  count: number;
  sum: number;
}

export function sumStats(stats: StatsIntersection[]) {
  return stats.reduce(
    (sum, stat) => ({
      count: sum.count + stat.count,
      sum: sum.sum + stat.sum
    }),
    {
      count: 0,
      sum: 0
    }
  );
}

interface StatsAllProps {
  statsPaymentStamp: StatPaymentStampType[];
  statsPaymentDiscipline: StatPaymentDisciplineType[];
  statsPaymentToken: StatsPaymentTokenType[];
}

export default function StatsAll({ statsPaymentStamp, statsPaymentDiscipline, statsPaymentToken }: StatsAllProps) {
  const total = sumStats([...statsPaymentStamp, ...statsPaymentDiscipline, ...statsPaymentToken]);

  return (
    <Card>
      <CardHeader tag="h4" className="text-center">
        Celkem
      </CardHeader>
      <CardBody>
        <Table size="sm" striped>
          <thead>
            <tr>
              <th />
              <th>Počet</th>
              <th className="text-right">Součet</th>
            </tr>
          </thead>
          <tbody>
            <tr className="font-weight-bold">
              <td>Celkem</td>
              <td>{total.count}x</td>
              <td className="text-right">
                <MoneyLabel amount={total.sum} />
              </td>
            </tr>
          </tbody>
        </Table>
      </CardBody>
    </Card>
  );
}
