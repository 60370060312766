import SimpleModal, { BaseSimpleModalProps } from '@monorepo/common/component/modal/SimpleModal';
import React from 'react';
import DisciplineConfigWaveOfferList from './DisciplineConfigWaveOfferList';

interface DisciplineConfigWaveOfferListModalProps extends BaseSimpleModalProps {
  title: string;
  clientId: string;
}

export default function DisciplineConfigWaveOfferListModal({
  title,
  clientId,
  children
}: DisciplineConfigWaveOfferListModalProps) {
  return (
    <SimpleModal
      title={title}
      size="lg"
      body={() => <DisciplineConfigWaveOfferList clientId={clientId} />}
      children={children}
    />
  );
}
