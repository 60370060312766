import { EditFormProps, Validator } from '@monorepo/common/component/modal/EditModal';
import { EditRow } from '@monorepo/common/component/modal/EditRow';
import { date2ymd } from '@monorepo/common/util/Time';
import { validateAnyField } from '@monorepo/common/util/Validation';
import React from 'react';
import { Col, Form, Row } from 'reactstrap';
import { LegalRepresentationType } from '../legalRepresentation/LegalRepresentationList';

export const validate: Validator<EditFormData> = (data, touched) => {
  const errors = {};

  validateAnyField('gdprSignDate', 'Souhlas GDPR udělen', data, errors, touched, { required: true });

  return errors;
};

export interface EditFormData {
  clientId: string;
  gdprSignDate?: string;
  legalRepresentation: LegalRepresentationType;
}

interface GdprAgreementNewProps extends EditFormProps<EditFormData> {}

export default function GdprAgreementNew({ validation, onPropChange, onPropTouch, ...data }: GdprAgreementNewProps) {
  return (
    <Form>
      <Row form>
        <Col sm={12}>
          <EditRow
            label="Souhlas GDPR udělen"
            property="gdprSignDate"
            data={data}
            type="date"
            badges={[
              {
                text: 'dnešní datum',
                enabled: !data.gdprSignDate,
                onClick: changePropValue => changePropValue('gdprSignDate', date2ymd(new Date()))
              }
            ]}
            validation={validation}
            onPropTouch={onPropTouch}
            onPropChange={onPropChange}
          />
        </Col>
      </Row>
    </Form>
  );
}
