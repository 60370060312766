import QueryWithLoader from '@monorepo/common/component/QueryWithLoader';
import React from 'react';
import { StatsOrderTotalDocument, StatsOrderTotalQuery, StatsOrderTotalQueryVariables } from '../../graphql-operations';
import StatsOrderTotalChart from './StatsOrderTotalChart';

/**
 * Adds days to given date.
 * See https://stackoverflow.com/a/563442/1310733
 *
 * @param date date
 * @param days days to add (positive or negative)
 */
function addDay(date: Date, days: number) {
  const copy = new Date(date.valueOf());
  copy.setDate(date.getDate() + days);
  return copy;
}

export default function StatsOrderTotal() {
  return (
    <QueryWithLoader<StatsOrderTotalQuery, StatsOrderTotalQueryVariables>
      query={StatsOrderTotalDocument}
      variables={{
        dateTimeFrom: addDay(new Date(), -30).toISOString(),
        dateTimeTo: new Date().toISOString()
      }}
      fetchPolicy="network-only"
    >
      {({ data }) => {
        return <StatsOrderTotalChart statsOrderTotal={[...data.statsOrderTotal].reverse()} />;
      }}
    </QueryWithLoader>
  );
}
