import { Form, Formik, useFormikContext } from "formik";
import React, { ReactNode, useEffect } from "react";
import StatelessConfirmModal from "./StatelessConfirmModal";

export interface FormikConfirmModalProps<T> {
  isOpen: boolean;
  title: string;
  size?: string;
  cancelButtonText?: string;
  confirmButtonText?: string;
  confirmButtonColor?: string;
  initialValues: T;
  validationSchema: any | (() => any);
  onConfirm: (value: T) => Promise<any>;
  onCancel: () => Promise<any>;
  onChange?: (value: T) => void;
  children: ReactNode;
}

export default function FormikConfirmModal<T>({
  isOpen,
  title,
  size,
  cancelButtonText,
  confirmButtonText,
  confirmButtonColor,
  initialValues,
  validationSchema,
  onConfirm,
  onCancel,
  onChange,
  children,
}: FormikConfirmModalProps<T>) {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      // validator={() => ({})}
      onSubmit={(values, actions) => {
        onConfirm(values).then(() => {
          actions.setSubmitting(false);
          actions.resetForm();
        });
      }}
    >
      {(formik) => (
        <>
          <StatelessConfirmModal
            isOpen={isOpen}
            title={title}
            size={size}
            cancelButtonText={cancelButtonText}
            confirmButtonText={confirmButtonText}
            confirmButtonColor={confirmButtonColor}
            onCancelClick={() => onCancel().then(() => formik.resetForm())}
            onConfirmClick={() => formik.submitForm()}
          >
            <Form>{children}</Form>
          </StatelessConfirmModal>
          {onChange && <MyOnChangeComponent onChange={onChange} />}
        </>
      )}
    </Formik>
  );
}

interface MyOnChangeComponentProps<T> {
  onChange: (value: T) => void;
}

/**
 * See https://github.com/formium/formik/issues/1633#issuecomment-602112073
 *
 * @param onChange
 * @return {null}
 * @constructor
 */
function MyOnChangeComponent<T>({ onChange }: MyOnChangeComponentProps<T>) {
  const { values } = useFormikContext<T>();

  // detect formik form update as new instance of values object
  useEffect(() => {
    onChange && onChange(values);
  }, [values, onChange]);

  return null;
}
