import React from "react";
import { Badge, Label, LabelProps } from "reactstrap";
import { preventedDefault } from "../util/Input";
import { changePropValue } from "../util/Validation";
import { PropChangedHandler, PropTouchedHandler } from "./modal/EditModal";

export enum BADGE_ALIGNMENT {
  LEFT = "LEFT",
  RIGHT = "RIGHT",
}

export type BadgeClickHandler<T, K extends keyof T> = (
  handler: PropChangedHandler<T, K>
) => void;

export interface BadgeType<T, K extends keyof T> {
  text: string;
  enabled?: boolean;
  onClick?: BadgeClickHandler<T, K>;
  downloadUrl?: string;
}

// @ts-ignore
export interface MultiBadgeLabelProps<T, K extends keyof T> extends LabelProps {
  label: string;
  property: K;
  badges?: BadgeType<T, K>[];
  alignment?: BADGE_ALIGNMENT;
  onPropTouch: PropTouchedHandler<T>;
  onPropChange: PropChangedHandler<T, K>;
}

export default function MultiBadgeLabel<T, K extends keyof T>({
  label,
  property,
  badges,
  alignment = BADGE_ALIGNMENT.RIGHT,
  onPropTouch,
  onPropChange,
  ...labelProps
}: MultiBadgeLabelProps<T, K>) {
  const isRight = alignment === BADGE_ALIGNMENT.RIGHT;
  return (
    <>
      <Label
        for={`${property}`}
        xs={badges && isRight ? 6 : 12}
        {...labelProps}
      >
        {label}
      </Label>

      {badges && (
        <Label xs={isRight ? 6 : 12} className={isRight ? "text-right" : ""}>
          {badges.map(({ text, enabled = true, onClick, downloadUrl }) => (
            <div key={text}>
              <Badge
                href={enabled ? downloadUrl ?? "#" : undefined}
                color={enabled ? "primary" : "secondary"}
                download={downloadUrl ? true : undefined}
                onClick={
                  enabled && onClick
                    ? preventedDefault(() =>
                        onClick(changePropValue(onPropTouch, onPropChange))
                      )
                    : undefined
                }
              >
                {text}
              </Badge>
            </div>
          ))}
        </Label>
      )}
    </>
  );
}
