import React from "react";

interface PeriodLabelProps {
  period: {
    year: number;
    number: number;
  };
}

export default function PeriodLabel({ period }: PeriodLabelProps) {
  return (
    <span>
      {period.year}/0{period.number / 10}
    </span>
  );
}
