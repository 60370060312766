import { ApolloError } from "@apollo/client";
import React, { MouseEventHandler } from "react";
import {
  Button,
  Col,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
} from "reactstrap";
import { IconSolidSearch } from "./Icons";
import List from "./List";

interface FilterableListProps<T> {
  items?: T[];
  loading: boolean;
  error?: ApolloError;
  selectedId: string;
  idExtractor: (item: T) => string;
  urlExtractor: (item: T, selected: boolean) => string;
  filter: string;
  hasNext: boolean;
  children: (item: T, filter?: string) => React.ReactNode;
  onFilterChange: (filter: string) => void;
  onSelect?: (item: T) => void;
  onMoreClick: MouseEventHandler;
}

export default function FilterableList<T>({
  items,
  selectedId,
  idExtractor,
  urlExtractor,
  filter,
  loading,
  error,
  hasNext,
  children,
  onFilterChange,
  onSelect,
  onMoreClick,
}: FilterableListProps<T>) {
  return (
    <>
      <Row className="mb-4">
        <Col>
          <InputGroup placeholder="Vyhledat">
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <IconSolidSearch />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              placeholder="Vyhledat"
              type="search"
              value={filter}
              onChange={(e) => onFilterChange(e.target.value)}
            />
          </InputGroup>
        </Col>
      </Row>
      <Row className="overflow-auto">
        <Col>
          <List
            items={items}
            loading={loading}
            error={error}
            selectedId={selectedId}
            idExtractor={idExtractor}
            urlExtractor={urlExtractor}
            filter={filter}
            onSelect={onSelect}
            children={children}
          />
          {hasNext && (
            <Button block onClick={onMoreClick}>
              Další
            </Button>
          )}
        </Col>
      </Row>
    </>
  );
}
