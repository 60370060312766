import { RefetchQueriesFunction, useMutation } from '@apollo/client';
import FormikConfirmModal from '@monorepo/common/component/modal/FormikConfirmModal';
import useConfirmModal, { ControlledConfirmModalProps } from '@monorepo/common/component/modal/useConfirmModal';
import React, { MouseEvent } from 'react';
import {
  RefundTransactionCashDocument,
  RefundTransactionCashMutation,
  RefundTransactionCashMutationVariables,
  RefundTransactionDocument,
  RefundTransactionMutation,
  RefundTransactionMutationVariables,
  ReverseTransactionDocument,
  ReverseTransactionMutation,
  ReverseTransactionMutationVariables
} from '../../../graphql-operations';
import OrderTransactionRefundForm, {
  createInitialValues,
  FormData,
  validationSchema
} from './OrderTransactionRefundForm';

export function useOrderTransactionRefundModal(
  mutation: typeof RefundTransactionDocument | typeof ReverseTransactionDocument | typeof RefundTransactionCashDocument,
  transactionId: string,
  refetchQueries: RefetchQueriesFunction
) {
  const [refundTransaction] = useMutation<
    RefundTransactionMutation | ReverseTransactionMutation | RefundTransactionCashMutation,
    RefundTransactionMutationVariables | ReverseTransactionMutationVariables | RefundTransactionCashMutationVariables
  >(mutation, {
    refetchQueries
  });

  const { modalProps, confirm } = useConfirmModal<FormData>();

  function openModal(e: MouseEvent) {
    e.preventDefault();

    confirm().then(({ refundReason }) =>
      // console.log('Refunded', refundReason, transactionId)
      refundTransaction({
        variables: {
          id: transactionId,
          reason: refundReason
        }
      })
    );
  }

  return { modalProps, openModal };
}

interface OrderTransactionRefundModalProps extends ControlledConfirmModalProps<FormData> {
  amount: number;
}

export default function OrderTransactionRefundModal({
  isOpen,
  onCancel,
  onConfirm,
  amount
}: OrderTransactionRefundModalProps) {
  return (
    <FormikConfirmModal<FormData>
      isOpen={isOpen}
      title="Vrácení peněz"
      size="md"
      cancelButtonText="Zrušit"
      confirmButtonText="Vrátit peníze"
      confirmButtonColor="danger"
      initialValues={createInitialValues()}
      validationSchema={validationSchema}
      onCancel={onCancel}
      onConfirm={onConfirm}
    >
      <OrderTransactionRefundForm amount={amount} />
    </FormikConfirmModal>
  );
}
