import React from "react";
import { StampType } from "../type/Types";

const STAMP_TYPE_LABEL = {
  SENIOR: "senior",
  ADULT: "dospělý",
  CHILD: "dítě",
  EXTERNAL: "hostující",
};

interface StampTypeLabelProps {
  type: StampType;
  short?: boolean;
}

export default function StampTypeLabel({
  type,
  short = false,
}: StampTypeLabelProps) {
  if (short) {
    return <span>{STAMP_TYPE_LABEL[type]}</span>;
  }
  return <span>Známka {STAMP_TYPE_LABEL[type]}</span>;
}
