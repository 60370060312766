import FormikInputGroup from '@monorepo/common/component/form/FormikInputGroup';
import MoneyLabel from '@monorepo/common/component/MoneyLabel';
import React from 'react';
import { Col, FormGroup, Label } from 'reactstrap';
import * as Yup from 'yup';

export function createInitialValues() {
  return {
    refundReason: ''
  };
}

export interface FormData {
  refundReason: string;
}

export const validationSchema = Yup.object({
  refundReason: Yup.string().required('Musíte vyplnit důvod')
});

interface OrderTransactionRefundFormProps {
  amount: number;
}

export default function OrderTransactionRefundForm({ amount }: OrderTransactionRefundFormProps) {
  return (
    <>
      <span>
        Opravdu vrátit <MoneyLabel amount={amount} /> ?
      </span>
      <FormGroup row>
        <Label sm={12}>Důvod</Label>
        <Col sm={12}>
          <FormikInputGroup name="refundReason" type="textarea" />
        </Col>
      </FormGroup>
    </>
  );
}
