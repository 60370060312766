import React from 'react';
import { match, Route } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import PersonFilterableList from '../component/person/PersonFilterableList';
import PersonSwitchDetail from '../component/person/switch/PersonSwitchDetail';

interface PersonsPageProps {
  match: match;
}

export default function PersonsPage({ match }: PersonsPageProps) {
  return (
    <Container>
      <Row>
        <Col xs={3}>
          <div className="position-fixed-scrollable">
            <Route
              path={`${match.path}/:personId?`}
              render={props => <PersonFilterableList personId={props.match.params.personId} url={match.url} />}
            />
          </div>
        </Col>

        <Col xs={9}>
          <Route path={`${match.path}/:personId`} render={props => <PersonSwitchDetail match={props.match} />} />
          <Route exact path={match.path} render={props => <PersonSwitchDetail match={props.match} />} />
        </Col>
      </Row>
    </Container>
  );
}
