import React, { ReactText } from "react";
import { Col, FormGroup, Label } from "reactstrap";
import * as Yup from "yup";
import DateRange from "../../DateRange";
import FormikRadioGroup from "../../form/FormikRadioGroup";
import MoneyLabel from "../../MoneyLabel";
import PersonNameLabel, { PersonDataType } from "../../person/PersonNameLabel";

export interface AccompanistChoice {
  client: {
    id: string;
    personData: PersonDataType;
  };
  covidDiscount: number;
  hasStamp: boolean;
  hasGdpr: boolean;
}

export type DisciplineAccompanistsSelect = {
  disciplineAccompanist: {
    birthDateFrom?: string | null;
    birthDateTo?: string | null;
  };
  choices: AccompanistChoice[];
};

export type DisciplineAccompanistsSelects = DisciplineAccompanistsSelect[];

export interface DisciplineAccompanistSelectFormType {
  accompanists: (AccompanistChoice | undefined)[];
}

export function createInitialValues(
  accompanistSelects: DisciplineAccompanistsSelects
): DisciplineAccompanistSelectFormType {
  return {
    accompanists: accompanistSelects.map(() => undefined),
  };
}

export const validationSchema = Yup.object({
  accompanists: Yup.array()
    .of(Yup.object<AccompanistChoice>().required("Musíte vybrat doprovod"))
    .required("Musíte vybrat doprovod"),
});

interface DisabledAccompanistLabelProps {
  hasStamp: boolean;
  hasGdpr: boolean;
}

function DisabledAccompanistLabel({
  hasStamp,
  hasGdpr,
}: DisabledAccompanistLabelProps) {
  if (hasStamp && hasGdpr) {
    return null;
  }

  const messages = [];

  if (!hasStamp) {
    messages.push("nemá koupenou známku");
  }

  if (!hasGdpr) {
    messages.push("nemá platný souhlas s GDPR");
  }

  return <span className="text-muted"> ({messages.join(", ")})</span>;
}

interface DisciplineAccompanistSelectProps {
  idx: ReactText;
  accompanistSelect: DisciplineAccompanistsSelect;
}

function DisciplineAccompanistSelect({
  idx,
  accompanistSelect,
}: DisciplineAccompanistSelectProps) {
  const { disciplineAccompanist, choices } = accompanistSelect;
  return (
    <FormGroup>
      <Label sm={12}>
        Doprovod (datum narození{" "}
        {
          <DateRange
            dateFromString={disciplineAccompanist.birthDateFrom}
            dateToString={disciplineAccompanist.birthDateTo}
          />
        }
        )
      </Label>
      <Col sm={12}>
        <FormikRadioGroup
          name={`accompanists[${idx}]`}
          items={choices.map((choice) => {
            const { client, covidDiscount, hasStamp, hasGdpr } = choice;
            return {
              id: client.id,
              value: choice,
              label: (
                <span>
                  <PersonNameLabel person={client} />
                  {covidDiscount > 0 && (
                    <span>
                      , sleva covid <MoneyLabel amount={covidDiscount} />
                    </span>
                  )}
                  <DisabledAccompanistLabel
                    hasStamp={hasStamp}
                    hasGdpr={hasGdpr}
                  />
                </span>
              ),
              disabled: !hasStamp || !hasGdpr,
            };
          })}
        />
      </Col>
    </FormGroup>
  );
}

interface DisciplineAccompanistSelectFormProps {
  accompanistSelects: DisciplineAccompanistsSelects;
}

export default function DisciplineAccompanistSelectForm({
  accompanistSelects,
}: DisciplineAccompanistSelectFormProps) {
  return (
    <>
      {accompanistSelects.map((accompanistSelect, idx) => (
        <React.Fragment key={idx}>
          <DisciplineAccompanistSelect
            accompanistSelect={accompanistSelect}
            idx={idx}
          />
          {/*Show error only at the bottom*/}
          {/*{idx === all.length - 1 && (*/}
          {/*  <FormGroup>*/}
          {/*    <Col sm={12}>*/}
          {/*      <Input type="hidden" invalid={true} />*/}
          {/*      <FormFeedback>*/}
          {/*        {idx}*/}
          {/*        <ErrorMessage name="accompanistIds[0]" />*/}
          {/*        {idx}*/}
          {/*      </FormFeedback>*/}
          {/*    </Col>*/}
          {/*  </FormGroup>*/}
          {/*)}*/}
        </React.Fragment>
      ))}
    </>
  );
}
