import React from "react";
import { isDef } from "../util/Text";
import { formatAge } from "./AgeLabel";

interface AgeRangeLabelProps {
  ageFrom?: number | null;
  ageTo?: number | null;
  emptyLabel?: string;
}

export function formatAgeRange(ageFrom?: number | null, ageTo?: number | null) {
  if (ageFrom && ageTo) {
    return `${ageFrom} - ${formatAge(ageTo)}`;
  }

  if (isDef(ageTo)) {
    return `do ${formatAge(ageTo)}`;
  }

  if (isDef(ageFrom)) {
    return `od ${formatAge(ageFrom)}`;
  }
  return null;
}

export default function AgeRangeLabel({
  ageFrom,
  ageTo,
  emptyLabel,
}: AgeRangeLabelProps) {
  return <span>{formatAgeRange(ageFrom, ageTo) || emptyLabel || null}</span>;
}
