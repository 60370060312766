import { IconRegularEdit, IconSolidTrashAlt, IconSolidUserPlus } from '@monorepo/common/component/Icons';
import PersonNameLabel from '@monorepo/common/component/person/PersonNameLabel';
import Secured, { ROLE_ADMIN_PERSON_DELETE } from '@monorepo/common/component/Secured';
import SexLabel from '@monorepo/common/component/SexLabel';
import VerificationIcon from '@monorepo/common/component/VerificationIcon';
import React, { ReactNode } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { ModuleCol } from '../client/ClientDetailView';
import LegalRepresentationList, { LegalRepresentationType } from '../legalRepresentation/LegalRepresentationList';
import PersonDetailDeleteModal from './PersonDetailDeleteModal';
import PersonDetailEditModal from './PersonDetailEditModal';

interface PersonRowProps {
  label: string;
  value?: ReactNode;
  children?: ReactNode;
}

const PersonRow = ({ label, value, children }: PersonRowProps) => {
  return (
    <Row>
      <Col sm={12} md={5}>
        <span className="text-muted">{label}</span>
      </Col>
      <Col sm={12} md={7}>
        {children || value}
      </Col>
    </Row>
  );
};

export const NewPersonIcon = () => (
  <PersonDetailEditModal title="Nová osoba">
    {(handleModalOpen, title) => (
      <h2 className="text-right">
        <IconSolidUserPlus onClick={handleModalOpen} title={title} className="action-icon text-primary" />
      </h2>
    )}
  </PersonDetailEditModal>
);

export interface PersonType {
  __typename?: 'Person';
  id: string;
  personData: {
    __typename?: 'PersonData';
    id: string;
    degreeBefore?: string | null;
    degreeAfter?: string | null;
    firstName: string;
    lastName: string;
    sex?: string | null;
    phone?: number | null;
    phone2?: number | null;
    email?: string | null;
    note?: string | null;
    personalDataVerified: boolean;
    legalRepresentationsChilds: LegalRepresentationType[];
  };
}

interface PersonDetailViewProps {
  person: PersonType;
}

export default function PersonDetailView({ person }: PersonDetailViewProps) {
  const { personData } = person;
  return (
    <Container>
      {/*Header with name and New icon*/}
      <Row>
        <Col sm="auto">
          <PersonDetailEditModal key={person.id} title="Změna osoby" person={person}>
            {(handleModalOpen, title) => (
              <h2>
                <IconRegularEdit onClick={handleModalOpen} title={title} className="action-icon text-primary" />
                <Secured authority={ROLE_ADMIN_PERSON_DELETE}>
                  <PersonDetailDeleteModal key={person.id} person={person}>
                    {(handleModalOpen, title) => (
                      <IconSolidTrashAlt onClick={handleModalOpen} title={title} className="action-icon text-danger" />
                    )}
                  </PersonDetailDeleteModal>
                </Secured>
                <PersonNameLabel person={person} /> <VerificationIcon verified={personData.personalDataVerified} />
              </h2>
            )}
          </PersonDetailEditModal>
        </Col>
        <Col />
        <Col sm={2}>
          <NewPersonIcon />
        </Col>
      </Row>

      {/*Person info*/}
      <Row>
        <Col sm={6}>
          <PersonRow label="Telefon" value={personData.phone} />
          <PersonRow label="Telefon2" value={personData.phone2} />
        </Col>
        <Col sm={6}>
          <PersonRow label="E-mail" value={personData.email} />
          {/*@ts-ignore*/}
          <PersonRow label="Pohlaví">{personData.sex && <SexLabel sex={personData.sex} />}</PersonRow>
          <PersonRow label="Poznámka" value={personData.note} />
        </Col>
      </Row>

      {/*Person modules*/}
      <Row>
        <ModuleCol sm={8}>
          <LegalRepresentationList
            representative={person}
            legalRepresentationsChilds={personData.legalRepresentationsChilds}
            legalRepresentationsRepresentatives={[]}
          />
        </ModuleCol>
      </Row>
    </Container>
  );
}
