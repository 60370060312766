import { ApolloClient, ApolloLink, ApolloProvider } from "@apollo/client";
import { InMemoryCache } from "@apollo/client/cache";
import { onError } from "@apollo/client/link/error";
import { ServerError, ServerParseError } from "apollo-link-http-common";
import { createUploadLink } from "apollo-upload-client";
import React, { Component } from "react";
import { RouteComponentProps } from "react-router";
import { withRouter } from "react-router-dom";
import { User } from "../type/Types";
import { xhrFormPost } from "../util/Network";
import {
  Authentication,
  AuthenticationProvider,
  AuthenticationStatus,
  Credentials,
} from "./AuthenticationContext";
import { NOTIFICATION_TYPE } from "./notification";
import { NotificationData } from "./notification/NotificationProvider";

function isServerError(
  error: Error | ServerError | ServerParseError
): error is ServerError {
  return (error as ServerError).statusCode !== undefined;
}
function isServerParseError(
  error: Error | ServerError | ServerParseError
): error is ServerParseError {
  return (error as ServerParseError).statusCode !== undefined;
}

interface PureAuthenticationApolloProviderProps extends RouteComponentProps {
  homePageUrl: string;
  notificationContext: NotificationData;
}

interface PureAuthenticationApolloProviderState extends Authentication {}

class PureAuthenticationApolloProvider extends Component<
  PureAuthenticationApolloProviderProps,
  PureAuthenticationApolloProviderState
> {
  constructor(props: PureAuthenticationApolloProviderProps) {
    super(props);

    this.handleLogoutClient = this.handleLogoutClient.bind(this);
    this.handleLogoutServer = this.handleLogoutServer.bind(this);
    this.handleLoginClient = this.handleLoginClient.bind(this);
    this.handleLoginServer = this.handleLoginServer.bind(this);
    this.handleMaintenance = this.handleMaintenance.bind(this);

    const cache = new InMemoryCache();

    let credentials = "same-origin";
    if (process.env.NODE_ENV !== "production") {
      credentials = "include";
    }

    const httpLink = createUploadLink({
      uri: "/graphql",
      credentials,
    });

    const client = new ApolloClient({
      cache,
      link: ApolloLink.from([
        onError((err) => {
          const { graphQLErrors, networkError, operation } = err;

          console.log("Error", networkError);
          if (graphQLErrors) {
            graphQLErrors.map(({ message, locations, path }) =>
              console.log(
                `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
              )
            );

            const accessDenied = graphQLErrors.some(
              (err) => err.extensions?.classification === "ACCESS_DENIED"
            );
            if (accessDenied) {
              this.handleLogoutClient();
            }

            // user-friendly errors
            graphQLErrors
              .filter(
                (err) =>
                  err.extensions?.classification === "USER_FRIENDLY_ERROR"
              )
              .forEach((err) => {
                const notification = {
                  type: NOTIFICATION_TYPE.ERROR,
                  header: "Chyba",
                  body: err.message,
                };

                this.props.notificationContext.showNotification(notification);
              });
          }

          if (
            networkError &&
            (isServerError(networkError) || isServerParseError(networkError))
          ) {
            if (networkError.statusCode === 403) {
              this.handleLogoutClient();
            }

            if (networkError.statusCode === 502) {
              this.handleMaintenance();
            }

            // console.log(`[Network error]: ${networkError}`);
            console.log("[Context]:", { ctx: operation.getContext() });

            console.log("[Network error]:", { err });
            // console.log('[op]:', GET_ME);
          }
        }),
        httpLink,
      ]),
    });

    this.state = {
      status: undefined,
      user: undefined,
      login: this.handleLoginServer,
      loginClient: this.handleLoginClient,
      logout: this.handleLogoutServer,
      maintenance: this.handleMaintenance,
      client,
    };
  }

  handleLogoutClient() {
    console.log("Client logout");

    this.setState(
      {
        status: AuthenticationStatus.ANONYMOUS,
        user: undefined,
      },
      () => {
        this.state.client.clearStore();
        // this.props.history.push('/');
      }
    );
  }

  handleMaintenance() {
    console.log("Maintenance");

    this.props.history.push("/maintenance");
  }

  handleLogoutServer() {
    console.log("Logging out client");

    return xhrFormPost("/rest/auth/logout").then(this.handleLogoutClient);
  }

  handleLoginClient(user: User, redirect = true) {
    console.log("Called Client login", user);
    if (this.state.status !== AuthenticationStatus.AUTHENTICATED) {
      console.log("Doing Client login");
      this.setState(
        {
          status: AuthenticationStatus.AUTHENTICATED,
          user,
        },
        () => {
          redirect && this.props.history.push(this.props.homePageUrl);
        }
      );
    }
  }

  handleLoginServer(credentials: Credentials) {
    console.log("Called Server login", credentials.username);

    return xhrFormPost("/rest/auth/login", credentials)
      .then((response) => response.json())
      .then((user) => this.handleLoginClient(user));
  }

  render() {
    return (
      <ApolloProvider client={this.state.client}>
        <AuthenticationProvider value={this.state}>
          {this.props.children}
        </AuthenticationProvider>
      </ApolloProvider>
    );
  }
}

export default withRouter(PureAuthenticationApolloProvider);
