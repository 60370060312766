import {
  IconProps,
  IconSolidChartLine,
  IconSolidUser,
  IconSolidVolleyballBall
} from '@monorepo/common/component/Icons';
import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Container, Jumbotron, Row } from 'reactstrap';

interface BoxWithIconProps {
  icon: React.ElementType<IconProps>;
  link: string;
  title: string;
  description: string;
}

function BoxWithIcon({ icon, link, title, description }: BoxWithIconProps) {
  const Icon = icon;
  return (
    <Col sm={12} lg={4}>
      <Jumbotron className="text-center">
        <div className="d-none d-lg-block mb-5 text-muted">
          <Icon size="9x" className="mb-5" />
          <hr className="my-4" />
        </div>
        <Link to={link}>
          <h2>{title}</h2>
        </Link>
        <p>{description}</p>
      </Jumbotron>
    </Col>
  );
}

export default function HomePage() {
  return (
    <Container>
      <Row>
        <BoxWithIcon icon={IconSolidUser} link="/clients" title="Členové" description="Správa členů a jejich plateb" />

        <BoxWithIcon icon={IconSolidVolleyballBall} link="/disciplines" title="Oddíly" description="Správa oddílů" />

        <BoxWithIcon
          icon={IconSolidChartLine}
          link="/stats"
          title="Statistiky a exporty"
          description="Zobrazení různých statistik a exportů"
        />
      </Row>
    </Container>
  );
}
