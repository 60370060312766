import React from "react";

interface HorizontalSeparatorProps {
  text?: string;
  className?: string;
}

export default function HorizontalSeparator({
  text,
  className,
}: HorizontalSeparatorProps) {
  return <div className={`separator text-muted ${className}`}>{text}</div>;
}
