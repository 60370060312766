import EditModal, { BaseEditModalProps, VariablesExtractor } from '@monorepo/common/component/modal/EditModal';
import { failOnMissing } from '@monorepo/common/util/Validation';
import React, { useCallback } from 'react';
import {
  AddDisciplineAccompanistDocument,
  AddDisciplineAccompanistMutationVariables,
  DisciplineDocument
} from '../../../graphql-operations';
import DisciplineAccompanistDetailNew, { EditFormData, validate } from './DisciplineAccompanistDetailNew';

const data2variables: VariablesExtractor<EditFormData, AddDisciplineAccompanistMutationVariables> = data => {
  const { birthDateFrom, birthDateTo } = data;

  return {
    disciplineId: failOnMissing(data, 'disciplineId'),
    birthDateFrom,
    birthDateTo,
    price: failOnMissing(data, 'price')
  };
};

interface DisciplineAccompanistDetailNewModalProps extends BaseEditModalProps {
  title: string;
  disciplineId: string;
}

export default function DisciplineAccompanistDetailNewModal({
  title,
  disciplineId,
  children
}: DisciplineAccompanistDetailNewModalProps) {
  const refetchQueries = useCallback(() => {
    return [
      {
        query: DisciplineDocument,
        variables: {
          id: disciplineId
        }
      }
    ];
  }, [disciplineId]);

  return (
    <EditModal
      title={title}
      tag={DisciplineAccompanistDetailNew}
      initialData={{
        disciplineId
      }}
      data2variables={data2variables}
      validate={validate}
      mutation={AddDisciplineAccompanistDocument}
      refetchQueries={refetchQueries}
      children={children}
    />
  );
}
