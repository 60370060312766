import { IconRegularEdit, IconSolidPlus } from '@monorepo/common/component/Icons';
import MoneyLabel from '@monorepo/common/component/MoneyLabel';
import NoData from '@monorepo/common/component/NoData';
import OrderLink from '@monorepo/common/component/order/OrderLink';
import PeriodLabel from '@monorepo/common/component/PeriodLabel';
import { PersonDataType } from '@monorepo/common/component/person/PersonNameLabel';
import React, { ReactNode } from 'react';
import { Col, Container, Row, Table } from 'reactstrap';
import ClientNameLink from '../client/ClientNameLink';
import TokenDetailEditModal from './TokenDetailEditModal';

interface ClientRowProps {
  label: string;
  children?: ReactNode;
}

function TokenRow({ label, children }: ClientRowProps) {
  return (
    <Row>
      <Col sm={12} md={5}>
        <span className="text-muted">{label}</span>
      </Col>
      <Col sm={12} md={7}>
        {children}
      </Col>
    </Row>
  );
}

export function NewTokenIcon() {
  return (
    <TokenDetailEditModal title="Nová známka">
      {(handleModalOpen, title) => (
        <h2 className="text-right">
          <IconSolidPlus onClick={handleModalOpen} title={title} className="action-icon text-primary" />
        </h2>
      )}
    </TokenDetailEditModal>
  );
}

export interface TokenType {
  __typename?: 'Token';
  id: string;
  period: { __typename?: 'Period'; id: string; year: number; number: number };
  name: string;
  priceFrom: number;
  paymentsToken: {
    __typename?: 'PaymentToken';
    id: string;
    client: {
      id: string;
      personData: PersonDataType;
    };
    orderToken: {
      __typename?: 'OrderToken';
      id: string;
      price: number;
      orderTotal: {
        id: string;
        orderNumber: string;
      };
    };
  }[];
}

interface TokenDetailViewProps {
  token: TokenType;
}

export default function TokenDetailView({ token }: TokenDetailViewProps) {
  const { period, name, priceFrom, paymentsToken } = token;

  return (
    <>
      <Container>
        {/* header with name and New icon */}
        <Row>
          <Col sm="auto">
            <TokenDetailEditModal key={token.id} title="Změna známky" token={token}>
              {(handleModalOpen, title) => (
                <h2>
                  <IconRegularEdit onClick={handleModalOpen} title={title} className="action-icon text-primary" />
                  <span>{name}</span>
                </h2>
              )}
            </TokenDetailEditModal>
          </Col>
          <Col />
          <Col sm={2}>
            <NewTokenIcon />
          </Col>
        </Row>

        {/* token info */}
        <Row className="mt-3">
          <Col xs={12} sm={6}>
            <TokenRow label="Období">
              <PeriodLabel period={period} />
            </TokenRow>

            <TokenRow label="Cena od">
              <MoneyLabel amount={priceFrom} />
            </TokenRow>
          </Col>

          <Col xs={12} md={6} className="mt-3 mt-md-0">
            <h4>Platby členů</h4>
            {!!paymentsToken.length && (
              <>
                <Table size="sm" striped>
                  <thead>
                    <tr>
                      <th>Člen</th>
                      <th className="text-right">Částka</th>
                    </tr>
                  </thead>
                  <tbody>
                    {paymentsToken.map(paymentToken => (
                      <tr key={paymentToken.orderToken.id}>
                        <td>
                          <ClientNameLink client={paymentToken.client} />
                        </td>
                        <td className="text-right">
                          <OrderLink orderNumber={paymentToken.orderToken.orderTotal.orderNumber}>
                            <MoneyLabel amount={paymentToken.orderToken.price} />
                          </OrderLink>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </>
            )}

            {!paymentsToken.length && <NoData text="Žádné platby" />}
          </Col>
        </Row>
      </Container>
    </>
  );
}
