import { IconSolidTrashAlt } from '@monorepo/common/component/Icons';
import { NoDataWithMarginBottom } from '@monorepo/common/component/NoData';
import PeriodLabel from '@monorepo/common/component/PeriodLabel';
import React from 'react';
import { Button, Card, CardBody, CardHeader, Table } from 'reactstrap';
import { SokolRole } from '../../graphql-operations';
import SokolRoleDetailDeleteModal from './SokolRoleDetailDeleteModal';
import SokolRoleDetailEditModal from './SokolRoleDetailEditModal';
import SokolRoleLabel from './SokolRoleLabel';

interface SokolRoleListProps {
  clientId: string;
  sokolRoles: SokolRole[];
}

export default function SokolRoleList({ clientId, sokolRoles }: SokolRoleListProps) {
  return (
    <Card>
      <CardHeader tag="h4" className="text-center">
        Role
      </CardHeader>
      <CardBody>
        {!!sokolRoles.length && (
          <Table size="sm" striped>
            <thead>
              <tr>
                <th>Období</th>
                <th>Role</th>
                <th>Akce</th>
              </tr>
            </thead>
            <tbody>
              {sokolRoles.map(sokolRole => (
                <tr key={sokolRole.id}>
                  <td>
                    <PeriodLabel period={sokolRole.period} />
                  </td>
                  <td>
                    <SokolRoleLabel role={sokolRole.role} />
                  </td>
                  <td>
                    <SokolRoleDetailDeleteModal clientId={clientId} sokolRole={sokolRole}>
                      {(handleModalOpen, title) => (
                        <IconSolidTrashAlt
                          onClick={handleModalOpen}
                          title={title}
                          className="action-icon text-danger"
                        />
                      )}
                    </SokolRoleDetailDeleteModal>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}

        {!sokolRoles.length && <NoDataWithMarginBottom text="Žádné role" />}

        <SokolRoleDetailEditModal key={clientId} title="Přidání role" clientId={clientId}>
          {(handleModalOpen, title) => (
            <Button block size="sm" onClick={handleModalOpen} title={title}>
              Přidat
            </Button>
          )}
        </SokolRoleDetailEditModal>
      </CardBody>
    </Card>
  );
}
