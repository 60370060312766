import IndexSelectInput from '@monorepo/common/component/form/IndexSelectInput';
import HorizontalSeparator from '@monorepo/common/component/HorizontalSeparator';
import {
  EditFormProps,
  PropChangedHandler,
  PropTouchedHandler,
  Validator
} from '@monorepo/common/component/modal/EditModal';
import { EditRowLeftRight } from '@monorepo/common/component/modal/EditRowLeftRight';
import MultiBadgeLabel, { BADGE_ALIGNMENT } from '@monorepo/common/component/MultiBadgeLabel';
import { PartialNull, Validation } from '@monorepo/common/type/Types';
import { valueOrEmpty } from '@monorepo/common/util/Text';
import { DAYS_OF_WEEK } from '@monorepo/common/util/Time';
import { validateAnyField, validateStringField } from '@monorepo/common/util/Validation';
import React from 'react';
import { Col, Form, FormFeedback, FormGroup, Input, Label, Row } from 'reactstrap';
import ActivityLabel from '../ActivityLabel';

export const validate: Validator<EditFormData> = (data, touched) => {
  const errors = {};
  validateStringField('name', 'Název', data, errors, touched, { required: true, text: { length: { max: 50 } } });
  validateAnyField('scheduleDay', 'Den v týdnu', data, errors, touched, { required: true });
  validateAnyField('scheduleTimeFrom', 'Čas začátku', data, errors, touched, { required: true });
  validateAnyField('scheduleTimeTo', 'Čas konce', data, errors, touched, { required: true });
  validateAnyField('activity', 'Aktivita', data, errors, touched, { required: true });
  validateStringField('insuranceReceiptPurpose', 'Účel platby v potvrzeni pro pojišťovny', data, errors, touched, {
    text: { length: { max: 500 } }
  });
  validateStringField('externalId', 'Externí ID', data, errors, touched, {
    text: { length: { max: 15 } }
  });
  return errors;
};

interface ActivityListProps<T, K extends keyof T> {
  activities: ActivityType[] | null | undefined;
  activity: ActivityType | null | undefined;
  vsestrannost: boolean;

  validation: Validation<T>;
  onPropTouch: PropTouchedHandler<T>;
  onPropChange: PropChangedHandler<T, K>;
}

function ActivityList({
  activities,
  activity,
  vsestrannost,
  validation,
  onPropTouch,
  onPropChange
}: ActivityListProps<PartialNull<EditFormData>, 'activity'>) {
  return (
    <>
      {activities &&
        activities
          .filter(a => a.vsestrannost === vsestrannost)
          .map((a, idx, all) => (
            <FormGroup check key={a.id}>
              <Label check>
                <Input
                  type="radio"
                  name="activity"
                  checked={a.id === activity?.id}
                  invalid={!!validation.errors.activity}
                  onChange={e => onPropChange('activity', a)}
                  onBlur={() => onPropTouch('activity')}
                />
                <ActivityLabel activity={a} />
                {/*Show it only at the bottom*/}
                {!vsestrannost && idx === all.length - 1 && validation.errors.activity && (
                  <FormFeedback>{validation.errors.activity}</FormFeedback>
                )}
              </Label>
            </FormGroup>
          ))}
    </>
  );
}

export interface ActivityType {
  __typename?: 'Activity';
  id: string;
  name: string;
  code: string;
  vsestrannost: boolean;
}

export interface EditFormData {
  id: string;
  externalId: string;
  name: string;
  scheduleDay: number;
  scheduleTimeFrom: string;
  scheduleTimeTo: string;
  activity: ActivityType;
  activities: ActivityType[];
  insuranceReceiptPurpose: string;
}

interface DisciplineDetailEditProps extends EditFormProps<EditFormData> {}

export default function DisciplineDetailEdit({
  validation,
  onPropTouch,
  onPropChange,
  ...discipline
}: DisciplineDetailEditProps) {
  const { activity, activities } = discipline;

  const badges = [
    'Všestranné cvičení rodičů a dětí.',
    'Všestranné cvičení rodičů, dětí a předškolních dětí.',
    'Všestranné cvičení předškolních dětí.',
    'Všestranné cvičení žáků a žákyň.',
    'Rehabilitační cvičení pro seniory.',
    'Rehabilitační cvičení na balančních míčích.'
  ].map(template => ({
    text: template,
    enabled: template !== discipline.insuranceReceiptPurpose,
    onClick: (callback: PropChangedHandler<EditFormData, 'insuranceReceiptPurpose'>) =>
      callback('insuranceReceiptPurpose', template)
  }));

  return (
    <Form>
      <EditRowLeftRight
        label="Název"
        placeholder="Zadejte název"
        property="name"
        data={discipline}
        validation={validation}
        onPropTouch={onPropTouch}
        onPropChange={onPropChange}
      />

      <FormGroup row>
        <MultiBadgeLabel
          label="Den v týdnu"
          property="scheduleDay"
          sm={12}
          md={3}
          onPropChange={onPropChange}
          onPropTouch={onPropTouch}
        />

        <Col sm={12} md={9} className="text-md-right">
          <IndexSelectInput
            id="scheduleDay"
            value={valueOrEmpty(discipline.scheduleDay)}
            options={DAYS_OF_WEEK}
            placeholder="Zadejte den v týdnu"
            invalid={!!validation.errors.scheduleDay}
            onChange={e => onPropChange('scheduleDay', e.target.value)}
            onBlur={() => onPropTouch('scheduleDay')}
          />
          {validation.errors.scheduleDay && <FormFeedback>{validation.errors.scheduleDay}</FormFeedback>}
        </Col>
      </FormGroup>

      <EditRowLeftRight
        label="Čas začátku"
        placeholder="Zadejte čas začátku"
        property="scheduleTimeFrom"
        type="time"
        data={discipline}
        validation={validation}
        onPropTouch={onPropTouch}
        onPropChange={onPropChange}
      />

      <EditRowLeftRight
        label="Čas konce"
        placeholder="Zadejte čas konce"
        property="scheduleTimeTo"
        type="time"
        data={discipline}
        validation={validation}
        onPropTouch={onPropTouch}
        onPropChange={onPropChange}
      />

      <FormGroup row>
        <Label for="activity" sm={12} md={3} check>
          Aktivita
        </Label>
        <Col sm={12} md={9}>
          <Row>
            <Col sm={12}>
              <ActivityList
                activities={activities}
                activity={activity}
                vsestrannost={true}
                validation={validation}
                onPropTouch={onPropTouch}
                onPropChange={onPropChange}
              />
            </Col>

            <Col sm={12}>
              <HorizontalSeparator text="nebo sportovní" />
            </Col>

            <Col sm={12}>
              <ActivityList
                activities={activities}
                activity={activity}
                vsestrannost={false}
                validation={validation}
                onPropTouch={onPropTouch}
                onPropChange={onPropChange}
              />
            </Col>
          </Row>
        </Col>
      </FormGroup>

      <EditRowLeftRight
        label="Externí ID"
        placeholder="Zadejte id ecz-sokol.cz"
        property="externalId"
        data={discipline}
        validation={validation}
        onPropTouch={onPropTouch}
        onPropChange={onPropChange}
      />

      <EditRowLeftRight
        label="Účel platby v potvrzeni pro pojišťovny"
        placeholder="Zadejte účel platby v potvrzeni pro pojišťovny"
        property="insuranceReceiptPurpose"
        data={discipline}
        type="textarea"
        labelProps={{ sm: 12 }}
        dataColProps={{ sm: 12, md: 12 }}
        badges={badges}
        badgesAlignment={BADGE_ALIGNMENT.LEFT}
        validation={validation}
        onPropTouch={onPropTouch}
        onPropChange={onPropChange}
      />
    </Form>
  );
}
