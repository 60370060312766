import React from 'react';
import { NavLink } from 'react-router-dom';

interface DisciplineNameLinkProps {
  discipline: {
    id: string;
    name: string;
  };
  period?: {
    id: string;
  };
}

export default function DisciplineNameLink({ discipline, period }: DisciplineNameLinkProps) {
  return <NavLink to={`/disciplines/${discipline.id}${period ? '/' + period.id : ''}`}>{discipline.name}</NavLink>;
}
