import QueryWithLoader from '@monorepo/common/component/QueryWithLoader';
import React from 'react';
import { Redirect } from 'react-router-dom';
import { DisciplineConfigDocument } from '../../../graphql-operations';
import DisciplineConfigDetailView from './DisciplineConfigDetailView';

interface DisciplineConfigDetailProps {
  disciplineConfigId: string;
}

export default function DisciplineConfigDetail({ disciplineConfigId }: DisciplineConfigDetailProps) {
  return (
    <QueryWithLoader query={DisciplineConfigDocument} variables={{ id: disciplineConfigId }}>
      {({ data }) => {
        const disciplineConfig = data.disciplineConfig;

        if (!disciplineConfig) {
          return <Redirect to="/page-not-found" />;
        }

        return <DisciplineConfigDetailView disciplineConfig={disciplineConfig} />;
      }}
    </QueryWithLoader>
  );
}
