import { EditFormProps, Validator } from '@monorepo/common/component/modal/EditModal';
import { validateField, validateStringField } from '@monorepo/common/util/Validation';
import React from 'react';
import { Col, Form, FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import { SokolRoleEnum } from '../../../graphql-operations';
import ClientAutocompleteInput, { ClientType } from '../../client/ClientAutocompleteInput';
import SokolRoleLabel, { roles } from '../../sokolRole/SokolRoleLabel';

export const validate: Validator<EditFormData> = (data, touched) => {
  const errors = {};

  validateField('client', 'Člen', data, errors, touched, { required: true });
  validateStringField('role', 'Role', data, errors, touched, {
    required: true,
    text: {
      oneOfTransformed: roles
    }
  });
  return errors;
};

export interface EditFormData {
  client: ClientType;
  role: SokolRoleEnum;
  periodId: string;
  disciplineConfigId: string;
}

interface DisciplineTrainerDetailEditProps extends EditFormProps<EditFormData> {}

export default function DisciplineTrainerDetailEdit({
  validation,
  onPropChange,
  onPropTouch,
  ...data
}: DisciplineTrainerDetailEditProps) {
  return (
    <Form>
      {/* role */}
      <FormGroup row>
        <Label for="role" sm={12} md={3} check>
          Role
        </Label>
        <Col sm={12} md={9}>
          {roles
            .filter(r => r.value === SokolRoleEnum.Trainer || r.value === SokolRoleEnum.Helper)
            .map((r, idx, all) => (
              <FormGroup check key={r.value}>
                <Label check>
                  <Input
                    type="radio"
                    name="role"
                    checked={!!data.role && r.value === data.role}
                    invalid={!!validation.errors.role}
                    onChange={e => onPropChange('role', r.value)}
                    onBlur={() => onPropTouch('role')}
                  />
                  <SokolRoleLabel role={r.value} />
                  {idx === all.length - 1 && validation.errors.role && (
                    <FormFeedback>{validation.errors.role}</FormFeedback>
                  )}
                </Label>
              </FormGroup>
            ))}
        </Col>
      </FormGroup>

      {/* trainer client */}
      <FormGroup row>
        <Label sm={12} md={3}>
          Člen
        </Label>
        <Col sm={12} md={9}>
          <ClientAutocompleteInput
            selected={data.client}
            disabled={!data.role}
            sokolRole={data.role}
            periodId={data.periodId}
            onSelect={t => onPropChange('client', t)}
            onBlur={() => onPropTouch('client')}
          />
          <Input type="hidden" invalid={!!validation.errors.client} />
          {validation.errors.client && <FormFeedback>{validation.errors.client}</FormFeedback>}
        </Col>
      </FormGroup>
    </Form>
  );
}
