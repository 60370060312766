import React, { useContext, useEffect, useState } from "react";
import { Col, Container, Row } from "reactstrap";
import { AuthenticationContext } from "../component/AuthenticationContext";

export default function LogoutPage() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const { logout } = useContext(AuthenticationContext);

  useEffect(() => {
    logout()
      .then(() => setLoading(false))
      .catch(() => setError(true));
  }, [logout, setLoading, setError]);

  return (
    <Container>
      <Row>
        <Col sm="12" md={{ size: 6, offset: 3 }} lg={{ size: 4, offset: 4 }}>
          {loading && <div className="text-center">Odhlašuji</div>}
          {!loading && (
            <div className="text-center">Byli jste úspěšně odhlášeni</div>
          )}
          {error && (
            <div className="text-danger text-center">Došlo k chybě</div>
          )}
        </Col>
      </Row>
    </Container>
  );
}
