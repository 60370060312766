import React from "react";
import { NavLink } from "react-router-dom";
import PersonNameLabel, { PersonDataType } from "./PersonNameLabel";

interface PersonNameLinkProps {
  person: {
    id: string;
    personData: PersonDataType;
  };
}

export default function PersonNameLink({ person }: PersonNameLinkProps) {
  return (
    <NavLink to={`/persons/${person.id}#`}>
      <PersonNameLabel person={person} />
    </NavLink>
  );
}
