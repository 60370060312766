import { BaseEditModalProps } from '@monorepo/common/component/modal/EditModal';
import SimpleModal from '@monorepo/common/component/modal/SimpleModal';
import NoData from '@monorepo/common/component/NoData';
import QueryWithLoader from '@monorepo/common/component/QueryWithLoader';
import { TokenOfferDocument, TokenOfferQuery, TokenOfferQueryVariables } from 'graphql-operations';
import React from 'react';
import { Alert } from 'reactstrap';
import TokenOfferDetail from './TokenOfferDetail';

interface TokenOfferModalProps extends BaseEditModalProps {
  title: string;
  clientId: string;
}

export default function TokenOfferModal({ title, clientId, children }: TokenOfferModalProps) {
  return (
    <SimpleModal
      title={title}
      size="lg"
      body={() => (
        <QueryWithLoader<TokenOfferQuery, TokenOfferQueryVariables>
          query={TokenOfferDocument}
          variables={{ clientId }}
          fetchPolicy="network-only"
        >
          {({ data }) => {
            const tokenOffer = data.tokenOffer;
            const tokenOfferItems = tokenOffer.tokenOfferItems;

            return (
              <>
                {/* Missing GDPR agreement error message */}
                {!tokenOffer.hasGdpr && (
                  <Alert color="danger">
                    Nemáte platný souhlas s GDPR. Dostavte se prosím osobně do kanceláře Sokola a podepište nový.
                  </Alert>
                )}

                {tokenOfferItems.map((tokenOfferItem, index) => (
                  <div key={tokenOfferItem.token.id}>
                    <TokenOfferDetail
                      clientId={clientId}
                      tokenOfferItem={tokenOfferItem}
                      disabled={!tokenOffer.hasGdpr}
                    />

                    {/* divider */}
                    {index < tokenOfferItems.length - 1 && <hr />}
                  </div>
                ))}

                {tokenOfferItems.length === 0 && <NoData text="Momentálně pro Vás nemáme žádné známky" />}
              </>
            );
          }}
        </QueryWithLoader>
      )}
      children={children}
    />
  );
}
