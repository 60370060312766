import AgeRangeLabel from '@monorepo/common/component/AgeRangeLabel';
import DeleteModal from '@monorepo/common/component/modal/DeleteModal';
import { ChildrenRenderProp } from '@monorepo/common/component/modal/EditModal';
import MoneyLabel from '@monorepo/common/component/MoneyLabel';
import React, { useCallback } from 'react';
import { DeleteDisciplineConfigPriceDocument, DisciplineConfigDocument } from '../../../graphql-operations';

function data2text({ ageFrom, price }: DisciplineConfigPrice) {
  return (
    <>
      <div>
        Věková hranice: <AgeRangeLabel ageFrom={ageFrom} />
      </div>

      <div>
        Cena: <MoneyLabel amount={price} />
      </div>
    </>
  );
}

function data2variables({ id }: DisciplineConfigPrice) {
  return {
    id
  };
}

interface DisciplineConfigPrice {
  __typename?: 'DisciplineConfigPrice';
  id: string;
  ageFrom: number;
  price: number;
}

interface DisciplineConfigPriceDeleteModalProps {
  disciplineConfigId: string;
  price: DisciplineConfigPrice;
  children: ChildrenRenderProp;
}

export default function DisciplineConfigPriceDeleteModal({
  disciplineConfigId,
  price,
  children
}: DisciplineConfigPriceDeleteModalProps) {
  const refetchQueries = useCallback(() => {
    return [
      {
        query: DisciplineConfigDocument,
        variables: {
          id: disciplineConfigId
        }
      }
    ];
  }, [disciplineConfigId]);

  return (
    <DeleteModal
      title="Smazání ceny oddílu"
      data={price}
      data2text={data2text}
      data2variables={data2variables}
      mutation={DeleteDisciplineConfigPriceDocument}
      refetchQueries={refetchQueries}
      children={children}
    />
  );
}
