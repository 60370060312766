import React from 'react';
import { SokolRoleEnum } from '../../graphql-operations';

export const roles = [
  {
    value: SokolRoleEnum.Trainer,
    name: 'Cvičitel'
  },
  {
    value: SokolRoleEnum.Helper,
    name: 'Pomahatel'
  },
  {
    value: SokolRoleEnum.Referee,
    name: 'Rozhodčí'
  },
  {
    value: SokolRoleEnum.Cinovnik,
    name: 'Činovník'
  }
];

const role2label = {
  TRAINER: 'Cvičitel',
  HELPER: 'Pomahatel',
  REFEREE: 'Rozhodčí',
  CINOVNIK: 'Činovník'
};

interface SokolRoleLabelProps {
  role: SokolRoleEnum;
}
export default function SokolRoleLabel({ role }: SokolRoleLabelProps) {
  return <span>{role2label[role]}</span>;
}
