import { RefetchQueriesFunction } from '@apollo/client';
import React from 'react';
import OrderTransactionCashDetail, { TransactionCash } from './cash/OrderTransactionCashDetail';
import OrderTransactionCsobDetail, { TransactionCsob } from './csob/OrderTransactionCsobDetail';

interface OrderTransactionsListProps {
  transactionsCsob: TransactionCsob[];
  transactionsCash: TransactionCash[];
  refetchQueries: RefetchQueriesFunction;
}

export default function OrderTransactionsList({
  transactionsCsob,
  transactionsCash,
  refetchQueries
}: OrderTransactionsListProps) {
  return (
    <>
      {transactionsCsob.map(transactionCsob => {
        return (
          <React.Fragment key={transactionCsob.id}>
            <h5>Bezhotovostní transakce branou ČSOB č. {transactionCsob.id}</h5>
            <OrderTransactionCsobDetail transaction={transactionCsob} refetchQueries={refetchQueries} />
          </React.Fragment>
        );
      })}

      {transactionsCash.map(transactionCash => {
        return (
          <React.Fragment key={transactionCash.id}>
            <h5>Hotovostní transakce č. {transactionCash.id}</h5>
            <OrderTransactionCashDetail transaction={transactionCash} refetchQueries={refetchQueries} />
          </React.Fragment>
        );
      })}
    </>
  );
}
