import React from "react";
import CenteredHV from "../layout/CenteredHV";

interface NoDataProps {
  text: string;
  className?: string;
}

export const NoDataWithMarginBottom = (props: NoDataProps) => {
  return <NoData className="mb-3" {...props} />;
};

export default function NoData({ text }: NoDataProps) {
  return <CenteredHV>{text}</CenteredHV>;
}
