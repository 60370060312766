import DateRange from '@monorepo/common/component/DateRange';
import DeleteModal from '@monorepo/common/component/modal/DeleteModal';
import { ChildrenRenderProp } from '@monorepo/common/component/modal/EditModal';
import React, { useCallback } from 'react';
import { DeleteDisciplineAccompanistDocument, DisciplineDocument } from '../../../graphql-operations';

function data2text({ birthDateFrom, birthDateTo }: AccompanistType) {
  return (
    <>
      <div>
        Datum narození: <DateRange dateFromString={birthDateFrom} dateToString={birthDateTo} />
      </div>
    </>
  );
}

function data2variables({ id }: AccompanistType) {
  return {
    id
  };
}

interface AccompanistType {
  __typename?: 'DisciplineAccompanist';
  id: string;
  birthDateFrom?: string | null;
  birthDateTo?: string | null;
}

interface DisciplineAccompanistDetailDeleteModalProps {
  disciplineId: string;
  accompanist: AccompanistType;
  children: ChildrenRenderProp;
}

export default function DisciplineAccompanistDetailDeleteModal({
  disciplineId,
  accompanist,
  children
}: DisciplineAccompanistDetailDeleteModalProps) {
  const refetchQueries = useCallback(() => {
    return [
      {
        query: DisciplineDocument,
        variables: {
          id: disciplineId
        }
      }
    ];
  }, [disciplineId]);

  return (
    <DeleteModal
      title="Smazání nastavení doprovodu"
      data={accompanist}
      data2text={data2text}
      data2variables={data2variables}
      mutation={DeleteDisciplineAccompanistDocument}
      refetchQueries={refetchQueries}
      children={children}
    />
  );
}
