import { Query } from '@apollo/client/react/components';
import ClientNameLabel from '@monorepo/common/component/client/ClientNameLabel';
import FilterableList from '@monorepo/common/component/FilterableList';
import React, { useState } from 'react';
import { ClientsDocument, ClientsQuery, ClientsQueryVariables } from '../../graphql-operations';

interface ClientFilterableListProps {
  clientId: string;
  url: string;
}

export default function ClientFilterableList({ clientId, url }: ClientFilterableListProps) {
  const [filter, setFilter] = useState('');
  return (
    <Query<ClientsQuery, ClientsQueryVariables> query={ClientsDocument} variables={{ filter }}>
      {({ data, loading, error, fetchMore }) => {
        const clients = (data || {}).clients;

        return (
          <FilterableList
            items={clients?.items}
            loading={loading}
            error={error}
            selectedId={clientId}
            idExtractor={client => client.id}
            urlExtractor={client => `${url}/${client.id}`}
            filter={filter}
            hasNext={!!clients?.next}
            onFilterChange={setFilter}
            onMoreClick={() =>
              clients?.next &&
              fetchMore({
                variables: {
                  page: clients.next.page,
                  pageSize: clients.next.size
                },
                updateQuery: (prev, { fetchMoreResult }) => {
                  if (!fetchMoreResult) {
                    return prev;
                  }
                  return {
                    ...fetchMoreResult,
                    clients: {
                      ...fetchMoreResult.clients,
                      items: [...prev.clients.items, ...fetchMoreResult.clients.items]
                    }
                  };
                }
              })
            }
          >
            {client => <ClientNameLabel client={client} />}
          </FilterableList>
        );
      }}
    </Query>
  );
}
