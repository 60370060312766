import NoData from '@monorepo/common/component/NoData';
import QueryWithLoader from '@monorepo/common/component/QueryWithLoader';
import React from 'react';
import { match, Redirect } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import { ClientDocument, ClientQuery, ClientQueryVariables } from '../../graphql-operations';
import ClientDetailView, { NewClientIcon } from './ClientDetailView';

interface ClientDetailProps {
  match: match<ClientDetailUrlParams>;
}

interface ClientDetailUrlParams {
  clientId?: string;
}

export default function ClientDetail({ match }: ClientDetailProps) {
  const { clientId } = match.params;
  return (
    <>
      {!!clientId && (
        <QueryWithLoader<ClientQuery, ClientQueryVariables> query={ClientDocument} variables={{ id: clientId }}>
          {({ data }) => {
            const client = data.client;

            if (!client) {
              return <Redirect to="/page-not-found" />;
            }

            return (
              <>
                <ClientDetailView client={client} />
              </>
            );
          }}
        </QueryWithLoader>
      )}

      {!clientId && (
        <>
          <Container>
            <Row>
              <Col sm={12}>
                <NewClientIcon />
              </Col>
            </Row>
          </Container>
          <NoData text="Vyberte člena" />
        </>
      )}
    </>
  );
}
