import React from "react";

interface AgeLabelProps {
  age: number;
}

export const formatAge = function (age: number) {
  if (age >= 5 || age === 0) {
    return `${age} let`;
  }
  if (age >= 2) {
    return `${age} roky`;
  }
  return `${age} rok`;
};

export default function AgeLabel({ age }: AgeLabelProps) {
  return <span>{formatAge(age)}</span>;
}
