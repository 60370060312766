import DeleteModal from '@monorepo/common/component/modal/DeleteModal';
import { ChildrenRenderProp } from '@monorepo/common/component/modal/EditModal';
import MoneyLabel from '@monorepo/common/component/MoneyLabel';
import PeriodLabel from '@monorepo/common/component/PeriodLabel';
import React, { useCallback } from 'react';
import { ClientDocument, DeleteInsuranceReceiptDocument } from '../../graphql-operations';

function data2text(insuranceReceipt: InsuranceReceipt) {
  return (
    <>
      <div>Číslo dokladu: {insuranceReceipt.receiptId}</div>
      <div>
        Období: <PeriodLabel period={insuranceReceipt.period} />
      </div>
      <div>
        Částka: <MoneyLabel amount={insuranceReceipt.amount} />
      </div>
    </>
  );
}

function data2variables(insuranceReceipt: InsuranceReceipt) {
  return {
    id: insuranceReceipt.id
  };
}

export interface InsuranceReceipt {
  id: string;
  receiptId: string;
  amount: number;
  period: {
    id: string;
    year: number;
    number: number;
  };
}

interface InsuranceReceiptDetailDeleteModalProps {
  clientId: string;
  insuranceReceipt: InsuranceReceipt;
  children: ChildrenRenderProp;
}

export default function InsuranceReceiptDetailDeleteModal({
  clientId,
  insuranceReceipt,
  children
}: InsuranceReceiptDetailDeleteModalProps) {
  const refetchQueries = useCallback(() => {
    return [
      {
        query: ClientDocument,
        variables: { id: clientId }
      }
    ];
  }, [clientId]);

  return (
    <DeleteModal
      title="Smazání potvrzení"
      data={insuranceReceipt}
      data2text={data2text}
      data2variables={data2variables}
      mutation={DeleteInsuranceReceiptDocument}
      refetchQueries={refetchQueries}
      children={children}
    />
  );
}
