import DeleteModal from '@monorepo/common/component/modal/DeleteModal';
import { ChildrenRenderProp } from '@monorepo/common/component/modal/EditModal';
import PeriodLabel from '@monorepo/common/component/PeriodLabel';
import React, { useCallback } from 'react';
import { ClientDocument, DeleteSokolRoleDocument, SokolRole } from '../../graphql-operations';
import SokolRoleLabel from './SokolRoleLabel';

function data2text(sokolRole: SokolRole) {
  return (
    <>
      <div>
        Rok: <PeriodLabel period={sokolRole.period} />
      </div>
      <div>
        Role: <SokolRoleLabel role={sokolRole.role} />
      </div>
    </>
  );
}

function data2variables(sokolRole: SokolRole) {
  return {
    id: sokolRole.id
  };
}

interface SokolRoleDetailDeleteModalProps {
  clientId: string;
  sokolRole: SokolRole;
  children: ChildrenRenderProp;
}

export default function SokolRoleDetailDeleteModal({ clientId, sokolRole, children }: SokolRoleDetailDeleteModalProps) {
  const refetchQueries = useCallback(() => {
    return [
      {
        query: ClientDocument,
        variables: { id: clientId }
      }
    ];
  }, [clientId]);

  return (
    <DeleteModal
      title="Smazání role"
      data={sokolRole}
      data2text={data2text}
      data2variables={data2variables}
      mutation={DeleteSokolRoleDocument}
      refetchQueries={refetchQueries}
      children={children}
    />
  );
}
