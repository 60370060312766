import React from "react";
import { useAppConfig } from "../hook/AppConfigHook";
import { Period } from "../type/Types";

interface AppConfigContextType {
  id?: string;
  currentPeriod?: Period;
  signUpDisabledMessage?: string;
}

export const AppConfigContext = React.createContext<AppConfigContextType>({});

interface AppConfigProviderProps {
  children?: React.ReactNode;
}

export default function AppConfigProvider({
  children,
}: AppConfigProviderProps) {
  const { loading, appConfig } = useAppConfig();

  if (loading || !appConfig) {
    return null;
  }

  return (
    <AppConfigContext.Provider value={appConfig}>
      {children}
    </AppConfigContext.Provider>
  );
}
