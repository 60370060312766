import AgeLabel, { formatAge } from '@monorepo/common/component/AgeLabel';
import ClientNameLabel from '@monorepo/common/component/client/ClientNameLabel';
import AutocompleteInput from '@monorepo/common/component/form/AutocompleteInput';
import { PersonDataType } from '@monorepo/common/component/person/PersonNameLabel';
import React, { FocusEventHandler } from 'react';
import {
  ClientData,
  ClientsDocument,
  ClientsQuery,
  ClientsQueryVariables,
  SokolRoleEnum
} from '../../graphql-operations';

export interface ClientType {
  id: string;
  clientData: Pick<ClientData, 'id' | 'age' | 'birthNumber'>;
  personData: PersonDataType;
}

interface ClientDropdownItemProps {
  client: ClientType;
}

export function ClientDropdownItem({ client }: ClientDropdownItemProps) {
  return (
    <span>
      <ClientNameLabel client={client} />{' '}
      <span className="text-muted">
        (<AgeLabel age={client.clientData.age} />, rč: {client.clientData.birthNumber})
      </span>
    </span>
  );
}

interface ClientSelectedItemProps {
  client: ClientType;
}

export function ClientSelectedItem({ client }: ClientSelectedItemProps) {
  return (
    <abbr title={`${formatAge(client.clientData.age)}, rč: ${client.clientData.birthNumber}`}>
      <ClientNameLabel client={client} />
    </abbr>
  );
}

interface ClientAutocompleteInputProps<T> {
  ageFrom?: number;
  ageTo?: number;
  sokolRole?: SokolRoleEnum | null;
  periodId?: string | null;
  selected?: T | null;

  disabled?: boolean;
  onSelect: (item: T | undefined) => void;
  onBlur: FocusEventHandler<HTMLElement>;
}

export default function ClientAutocompleteInput({
  ageFrom,
  ageTo,
  sokolRole,
  periodId,
  ...rest
}: ClientAutocompleteInputProps<ClientType>) {
  return (
    <AutocompleteInput<ClientType, ClientsQuery, ClientsQueryVariables>
      {...rest}
      placeholder="Vyberte člena"
      query={ClientsDocument}
      queryVariables={{
        ageFrom: ageFrom,
        ageTo: ageTo,
        role: sokolRole,
        periodId: periodId
      }}
      item2nameSelected={client => <ClientSelectedItem client={client} />}
      item2nameDropdown={client => <ClientDropdownItem client={client} />}
      result2items={data => data.clients.items}
    />
  );
}
