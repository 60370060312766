// my stuff
import AppConfigProvider from '@monorepo/common/component/AppConfigProvider';
import AuthenticationApolloProvider from '@monorepo/common/component/AuthenticationApolloProvider';
import AuthenticationContext, { AuthenticationStatus } from '@monorepo/common/component/AuthenticationContext';
import { NotificationConsumer, NotificationProvider } from '@monorepo/common/component/notification';
import QueryWithLoader from '@monorepo/common/component/QueryWithLoader';
import ChangePasswordPage from '@monorepo/common/page/ChangePasswordPage';
import ForgottenPasswordPage from '@monorepo/common/page/ForgottenPasswordPage';
import LoginPage from '@monorepo/common/page/LoginPage';
import LogoutPage from '@monorepo/common/page/LogoutPage';
import MaintenancePage from '@monorepo/common/page/MaintenancePage';
import NotFoundPage from '@monorepo/common/page/NotFoundPage';
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useContext } from 'react';
import { RouteProps } from 'react-router';
import { BrowserRouter as Router, Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';
import './App.css';
import { MeDocument, MeQuery } from './graphql-operations';
import WithHeader from './layout/WithHeader';
import AppConfigPage from './page/AppConfigPage';
import ClientsPage from './page/ClientsPage';
import CsobCrossCheckReportPage from './page/CsobCrossCheckReportPage';
import DisciplinesPage from './page/DisciplinesPage';
import HomePage from './page/HomePage';
import OrdersPage from './page/OrdersPage';
import PersonsPage from './page/PersonsPage';
import StatsPage from './page/StatsPage';
import TokensPage from './page/TokensPage';

// Enter on inputs behaves as Tab
document.addEventListener('keydown', function (event) {
  if (event.key === 'Enter' && event.target) {
    const target = event.target as HTMLInputElement;

    if (target.nodeName === 'INPUT') {
      const form = target.form;
      if (form) {
        const index = Array.prototype.indexOf.call(form, target);
        const step = event.shiftKey ? -1 : 1;

        const nextInput = form.elements[
          Math.max(Math.min(index + step, form.elements.length - 1), 0)
        ] as HTMLInputElement;

        nextInput.focus();
        event.preventDefault();
      }
    }
  }
});

interface SecureRouteProps extends RouteProps {
  render: (match: RouteComponentProps) => React.ReactNode;
}

function SecureRoute({ render, ...props }: SecureRouteProps) {
  const { status, loginClient } = useContext(AuthenticationContext);

  if (status === AuthenticationStatus.ANONYMOUS) {
    return <Redirect to="/login" />;
  }

  console.log('Rendering GET_ME');

  return (
    <Route
      {...props}
      render={match => {
        return (
          <QueryWithLoader<MeQuery>
            query={MeDocument}
            onCompleted={data => loginClient(data.me, false)}
            pollInterval={50 * 1000}
          >
            {() => {
              return (
                <AppConfigProvider>
                  <WithHeader>{render(match)}</WithHeader>
                </AppConfigProvider>
              );
            }}
          </QueryWithLoader>
        );
      }}
    />
  );
}

export default function App() {
  return (
    <Router>
      <NotificationProvider>
        <NotificationConsumer>
          {notificationContext => (
            <AuthenticationApolloProvider homePageUrl="/" notificationContext={notificationContext}>
              <Switch>
                <Route
                  path="/login"
                  render={() => (
                    <WithHeader>
                      <LoginPage />
                    </WithHeader>
                  )}
                />
                <Route
                  path="/forgotten-password/:username?"
                  render={({ match }) => (
                    <WithHeader>
                      <ForgottenPasswordPage forUsername={match.params.username} />
                    </WithHeader>
                  )}
                />
                <Route
                  path="/change-password/:token"
                  render={({ match }) => (
                    <WithHeader>
                      <ChangePasswordPage token={match.params.token} />
                    </WithHeader>
                  )}
                />
                <Route
                  path="/logout"
                  render={() => (
                    <WithHeader>
                      <LogoutPage />
                    </WithHeader>
                  )}
                />

                <Route
                  path="/maintenance"
                  render={() => (
                    <WithHeader>
                      <MaintenancePage />
                    </WithHeader>
                  )}
                />

                <SecureRoute exact path="/" render={() => <HomePage />} />
                <SecureRoute path="/clients" render={match => <ClientsPage match={match.match} />} />
                <SecureRoute path="/persons" render={match => <PersonsPage match={match.match} />} />
                <SecureRoute path="/disciplines" render={match => <DisciplinesPage match={match} />} />
                <SecureRoute path="/orders" render={match => <OrdersPage match={match} />} />
                <SecureRoute path="/tokens" render={match => <TokensPage match={match.match} />} />
                <SecureRoute path="/stats" render={match => <StatsPage match={match} />} />
                <SecureRoute path="/csob-cross-check-report" render={() => <CsobCrossCheckReportPage />} />
                <SecureRoute path="/app-config" render={() => <AppConfigPage />} />

                <SecureRoute exact path="/page-not-found" render={() => <NotFoundPage />} />
                <SecureRoute render={() => <NotFoundPage />} />
              </Switch>
            </AuthenticationApolloProvider>
          )}
        </NotificationConsumer>
      </NotificationProvider>
    </Router>
  );
}
