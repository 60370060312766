import {
  ApolloClient,
  MutationFunctionOptions,
  useApolloClient,
  useMutation,
} from "@apollo/client";
import { InternalRefetchQueriesInclude } from "@apollo/client/core";
import { DocumentNode, ExecutionResult } from "graphql";
import { History } from "history";
import React, {
  EventHandler,
  ReactNode,
  SyntheticEvent,
  useCallback,
  useRef,
} from "react";
import { useHistory } from "react-router-dom";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { useStateCallback } from "../../hook/useStateCallback";
import { entries, PartialNull, Validation } from "../../type/Types";

export type Validator<T> = (
  data: PartialNull<T>,
  touched: Touched<T>
) => ValidationErrors<T>;

export type ValidationErrors<T> = Partial<Record<keyof T, string>>;

export type Touched<T> = Partial<Record<keyof T | "all", boolean>>;

interface State<T> {
  isOpen: boolean;
  data: PartialNull<T>;
  validation: {
    errors: ValidationErrors<T>;
    warnings: ValidationErrors<T>;
    touched: Touched<T>;
  };
}

function initialState<T>(
  data: T | undefined,
  validate: Validator<T>,
  validateWarnings: Validator<T>
): State<T> {
  const touched: Touched<T> = { all: false };

  return {
    isOpen: false,
    data: {
      ...data,
    },
    validation: {
      errors: validate(data || {}, touched),
      warnings: validateWarnings(data || {}, { ...touched, all: true }),
      touched,
    },
  };
}

export type PropTouchedHandler<T> = (property: keyof T) => void;

export type PropChangedHandler<T, K extends keyof T> = (
  property: K,
  value: T[K] | undefined
) => void;

export type EditFormProps<T> = PartialNull<T> & {
  validation: Validation<Partial<T>>;
  onPropTouch: PropTouchedHandler<PartialNull<T>>;
  onPropChange: PropChangedHandler<PartialNull<T>, keyof T>;
};

export interface BaseEditModalProps {
  children: ChildrenRenderProp;
}

export type ChildrenRenderProp = (
  open: EventHandler<SyntheticEvent>,
  title: string
) => ReactNode;

export type SaveCompletedHandler<MutResult, T> = (
  responseData: MutResult,
  apolloClient: ApolloClient<any>,
  history: History,
  formData: PartialNull<T>
) => void;

export type VariablesExtractor<T, MutVariables> = (
  data: PartialNull<T>
) => MutVariables;

export type DiffExtractor<T> = (
  name: keyof T,
  value: T[typeof name] | undefined,
  diff: Partial<T>
) => Partial<T>;

interface EditModalProps<T, MutVariables, MutResult>
  extends BaseEditModalProps {
  title: string;
  size?: string;
  initialData?: PartialNull<T>;
  change2diff?: DiffExtractor<T>;
  data2variables: VariablesExtractor<T, MutVariables>;
  tag: React.ElementType<EditFormProps<Partial<T>>>;
  validate: Validator<PartialNull<T>>;
  validateWarnings?: Validator<PartialNull<T>>;
  disabled?: boolean;
  mutation: DocumentNode;
  refetchQueries?: (data: PartialNull<T>) => InternalRefetchQueriesInclude;
  onCompleted?: SaveCompletedHandler<MutResult, T>;
}

const noopValidator = () => ({});

export default function EditModal<T, MutVariables, MutResult>({
  title,
  size,
  initialData,
  change2diff = (name, value, diff) => diff,
  data2variables,
  tag,
  validate,
  validateWarnings = noopValidator,
  disabled,
  mutation,
  refetchQueries,
  onCompleted,
  children,
}: EditModalProps<T, MutVariables, MutResult>) {
  const [state, setState] = useStateCallback<State<T>>(() =>
    initialState(initialData, validate, validateWarnings)
  );

  const history = useHistory();
  const client = useApolloClient();

  const validateAndStoreData = useCallback(
    (
      dataDiff: Partial<T>,
      allFields: boolean = false,
      onValidCallback?: (data: PartialNull<T>) => void
    ) => {
      setState(
        (prevState) => {
          const data = {
            ...prevState.data,
            ...dataDiff,
          };

          const touched: Touched<T> = {
            ...prevState.validation.touched,
            all: prevState.validation.touched.all || allFields,
          };

          const validation = {
            touched,
            errors: validate(data, touched),
            warnings: validateWarnings(data, { ...touched, all: true }),
          };

          return {
            ...prevState,
            data,
            validation,
          };
        },
        (newState) => {
          if (onValidCallback) {
            const hasErrors = entries(newState.validation.errors).some(
              ([, value]) => !!value
            );

            if (!hasErrors) {
              onValidCallback(newState.data);
            }
          }
        }
      );
    },
    [setState, validate, validateWarnings]
  );

  const handlePropTouch = useCallback<PropTouchedHandler<T>>(
    (property) => {
      console.log("touch", property);
      const diff: Touched<T> = { all: false };

      diff[property] = true;
      setState(
        (prevState) => ({
          ...prevState,
          validation: {
            ...prevState.validation,
            touched: {
              ...prevState.validation.touched,
              ...diff,
              all: prevState.validation.touched.all,
            },
          },
        }),
        () => validateAndStoreData({})
      );
    },
    [setState, validateAndStoreData]
  );

  const handlePropChange = useCallback<PropChangedHandler<T, keyof T>>(
    (name, value) => {
      if (typeof value === "string" && value === "") {
        value = undefined;
      }
      console.log(name, value);
      let diff: Partial<T> = {};
      diff[name] = value;
      diff = change2diff(name, value, diff);

      validateAndStoreData(diff);
    },
    [validateAndStoreData]
  );

  const handleSaveClick = useCallback(
    (
      save: (
        options?: MutationFunctionOptions<MutResult, MutVariables>
      ) => Promise<ExecutionResult<MutResult>>,
      data: PartialNull<T>
    ) => {
      validateAndStoreData({}, true, () => {
        save({
          variables: {
            ...data2variables(data),
          },
        });
      });
    },
    [validateAndStoreData, data2variables]
  );

  const handleModalClose = useCallback(() => {
    setState((prevState) => ({ ...prevState, isOpen: false }));
  }, [setState, initialState, initialData, validate, validateWarnings]);

  const handleCompleted = useCallback(
    (responseData: MutResult) => {
      onCompleted && onCompleted(responseData, client, history, state.data);
      handleModalClose();
    },
    [onCompleted, handleModalClose, state.data]
  );

  const handleCompletedRef = useRef(handleCompleted);
  handleCompletedRef.current = handleCompleted;

  const handleModalOpen = useCallback(
    (event: SyntheticEvent) => {
      event.preventDefault();

      setState({
        ...initialState(initialData, validate, validateWarnings),
        isOpen: true,
      });
    },
    [setState, initialData, validate, validateWarnings]
  );

  const [save, { loading, error }] = useMutation<MutResult, MutVariables>(
    mutation,
    {
      // already fired mutation's onCompleted can not be changed with updated initialData, so ref must be used
      onCompleted: (data: MutResult) => handleCompletedRef.current(data),
      refetchQueries: refetchQueries ? () => refetchQueries(data) : undefined,
    }
  );

  const Tag = tag;
  const { isOpen, data, validation } = state;

  return (
    <>
      {children(handleModalOpen, title)}
      <Modal isOpen={isOpen} size={size} toggle={handleModalClose} centered>
        <ModalHeader toggle={handleModalClose}>{title}</ModalHeader>
        <ModalBody>
          {/*// @ts-ignore*/}
          <Tag
            {...data}
            validation={validation}
            onPropTouch={handlePropTouch}
            onPropChange={handlePropChange}
          />
        </ModalBody>
        <ModalFooter>
          <Button onClick={handleModalClose} className="MutResult-auto">
            Zrušit
          </Button>
          <Button
            onClick={() => handleSaveClick(save, data)}
            color="primary"
            disabled={disabled}
          >
            Uložit
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}
