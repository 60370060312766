import DeleteModal from '@monorepo/common/component/modal/DeleteModal';
import { ChildrenRenderProp } from '@monorepo/common/component/modal/EditModal';
import PersonNameLabel from '@monorepo/common/component/person/PersonNameLabel';
import { Person } from '@monorepo/common/type/Types';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { DeletePersonDocument } from '../../graphql-operations';

function data2text(person: Person) {
  return (
    <div>
      Osoba: <PersonNameLabel person={person} />
    </div>
  );
}

function data2variables(person: Person) {
  return {
    id: person.id
  };
}

interface PersonDetailDeleteModalProps {
  person: Person;
  children: ChildrenRenderProp;
}

export default function PersonDetailDeleteModal({ person, children }: PersonDetailDeleteModalProps) {
  const history = useHistory();

  const handleCompleted = useCallback(
    (data, close, apolloClient) => {
      history.push('/persons');
      apolloClient.resetStore();
    },
    [history]
  );

  return (
    <DeleteModal
      title="Smazání osoby"
      data={person}
      data2text={data2text}
      data2variables={data2variables}
      mutation={DeletePersonDocument}
      onCompleted={handleCompleted}
      children={children}
    />
  );
}
