import { PersonDataType } from '@monorepo/common/component/person/PersonNameLabel';
import PersonNameLink from '@monorepo/common/component/person/PersonNameLink';
import React from 'react';
import ClientNameLink from '../../client/ClientNameLink';

interface PersonSwitchNameLinkProps {
  person: {
    id: string;
    personData: PersonDataType;
    clientData?: {} | null;
  };
}

export default function PersonSwitchNameLink({ person }: PersonSwitchNameLinkProps) {
  if (person.clientData) {
    return <ClientNameLink client={person} />;
  }

  return <PersonNameLink person={person} />;
}
