import { formatDateFromDate } from "../component/DateLabel";
import {
  PropChangedHandler,
  PropTouchedHandler,
  Touched,
  ValidationErrors,
} from "../component/modal/EditModal";
import { isDef, isNumeric } from "./Text";
import { date2ymd } from "./Time";

type ErrorMessageProducer = (label: string) => string;

interface ValidatorText {
  length?: {
    min?: number;
    max?: number;
  };
  oneOfTransformed?: {
    name: string;
    value: string;
  }[];
  email?: boolean;
  regex?: {
    pattern: RegExp;
    handler: ErrorMessageProducer;
  };
  date?: {
    min?: Date;
    minMessage?: ErrorMessageProducer;
    max?: Date;
    maxMessage?: ErrorMessageProducer;
  };
}

interface ValidatorNumber {
  range?: {
    min?: number;
    max?: number;
  };
}

interface ValidatorExact<T> {
  value: T;
  message?: ErrorMessageProducer;
}

type ValidatorGeneric<T> = (
  value: T | null | undefined,
  label: string
) => string | undefined;

interface AnyFieldValidators<T> {
  required?: boolean;
  exact?: ValidatorExact<T>;
  generic?: ValidatorGeneric<T>;
}

interface StringFieldValidators extends AnyFieldValidators<string | number> {
  text?: ValidatorText;
  number?: ValidatorNumber;
}

export function validateField<T>(
  property: keyof T,
  label: string,
  data: Partial<T>,
  errors: ValidationErrors<T>,
  touched: Touched<T>,
  { required, text, number, exact }: StringFieldValidators
) {
  errors[property] =
    (touched.all || touched[property]) &&
    // @ts-ignore
    validateStringFieldImpl(label, data ? data[property] : undefined, {
      required,
      text,
      number,
      exact,
    });
}

export function validateStringField<T, K extends keyof T>(
  property: K,
  label: string,
  data: Partial<Record<K, string | number | undefined | null>>,
  errors: ValidationErrors<T>,
  touched: Touched<T>,
  validators: StringFieldValidators
) {
  errors[property] =
    (touched.all || touched[property]) &&
    validateStringFieldImpl(
      label,
      data ? data[property] : undefined,
      validators
    );
}

export function validateAnyField<T>(
  property: keyof T,
  label: string,
  data: Partial<T>,
  errors: ValidationErrors<T>,
  touched: Touched<T>,
  validators: AnyFieldValidators<T[typeof property]>
) {
  errors[property] =
    (touched.all || touched[property]) &&
    validateAnyFieldImpl<T[typeof property]>(
      label,
      data ? data[property] : undefined,
      validators
    );
}

function validateStringFieldImpl(
  label: string,
  value: string | number | undefined | null,
  validators: StringFieldValidators
) {
  const anyValidationErrors = validateAnyFieldImpl(label, value, validators);

  if (anyValidationErrors) {
    return anyValidationErrors;
  }

  const { text, number, exact } = validators;

  // TEXT
  if (text) {
    if (typeof value === "number") {
      value = value.toString();
    }

    if (
      typeof value !== "undefined" &&
      typeof value !== "string" &&
      value !== null
    ) {
      throw new Error(
        `Can not validate '${typeof value}', '${value}' using text validator`
      );
    }

    const { length, oneOfTransformed, email, regex, date } = text;

    // length validation
    if (length) {
      if (length.max !== undefined && value && value.length > length.max) {
        return `Pole ${label} musí být kratší než ${length.max} znaků (${value.length})`;
      }
      if (length.min !== undefined && (!value || value.length < length.min)) {
        return `Pole ${label} musí být delší než ${length.min} znaků (${
          value ? value.length : 0
        })`;
      }
    }

    // one of validation
    if (oneOfTransformed) {
      if (!value || !oneOfTransformed.map((one) => one.value).includes(value)) {
        const names = oneOfTransformed.map((one) => one.name);
        return `Pole ${label} musí být hodnotou ze seznamu ${names} (${value})`;
      }
    }

    if (email) {
      if (value && !/^.+@.+\.[^.]+$/.test(value)) {
        return `Pole ${label} musí být validní emailová adresa (xxx@yyy.zzz)`;
      }
    }

    if (regex) {
      if (value && !regex.pattern.test(value)) {
        return regex.handler(label);
      }
    }

    if (date && value) {
      const valueDate = new Date(value);
      if (date.min && valueDate < date.min) {
        if (date.minMessage) {
          return date.minMessage(label);
        }

        return `Datum ${label} musí mít hodnotu po datu ${formatDateFromDate(
          date.min
        )}`;
      }

      if (date.max && valueDate > date.max) {
        if (date.maxMessage) {
          return date.maxMessage(label);
        }

        return `Datum ${label} musí mít hodnotu před datem ${formatDateFromDate(
          date.max
        )}`;
      }
    }
  }

  // NUMBER
  if (number) {
    const { range } = number;

    // is number validation
    if (value && !isNumeric(value)) {
      return `Pole ${label} musí být číslo`;
    }

    // range validation
    if (range) {
      if (
        isDef(range.max) &&
        isDef(value) &&
        Number(value) > Number(range.max)
      ) {
        return `Pole ${label} musí být číslo menší než ${range.max} (${value})`;
      }
      if (
        isDef(range.min) &&
        isDef(value) &&
        Number(value) < Number(range.min)
      ) {
        return `Pole ${label} musí být číslo větší než ${range.min} (${value})`;
      }
    }
  }

  // everything is OK
  return undefined;
}

function validateAnyFieldImpl<T>(
  label: string,
  value: T | undefined | null,
  { required, exact, generic }: AnyFieldValidators<T>
) {
  // required validation
  if (required) {
    if (
      value === undefined ||
      value === null ||
      (typeof value === "string" && !value.trim())
    ) {
      return `Pole ${label} je povinné`;
    }
  }

  // BOOLEAN
  if (exact) {
    // exact value validation
    if (value !== exact.value) {
      if (exact.message) {
        return exact.message(label);
      }

      return `Pole ${label} musí být ${exact.value}`;
    }
  }

  // GENERIC
  if (generic) {
    const message = generic(value, label);
    if (message) {
      return message;
    }
  }

  // everything is OK
  return undefined;
}

export function changePropValue<T, K extends keyof T>(
  onPropTouch: PropTouchedHandler<T>,
  onPropChange: PropChangedHandler<T, K>
) {
  return (property: K, value: T[K] | undefined) => {
    onPropChange(property, value);
    onPropTouch(property);
  };
}

export function failOnMissing<T, K extends keyof T>(
  data: T,
  property: K
): NonNullable<Required<T[K]>> {
  const value = data[property];

  if (value === undefined) {
    throw new Error(`Property ${property} on ${data} is undefined`);
  }

  if (value === null) {
    throw new Error(`Property ${property} on ${data} is null`);
  }

  // @ts-ignore
  return value;
}

export function parseBirthNumber(
  value: string
): { birthDateString: string; sex: "f" | "m" } | undefined {
  if (value.length !== 10) {
    return undefined;
  }

  const number = Number(value);
  if (number % 11 !== 0) {
    return undefined;
  }

  const birthYear = Number(value.substr(0, 2));
  const birthMonth = Number(value.substr(2, 2));
  const birthDay = Number(value.substr(4, 2));

  let birthMonthReal = birthMonth;
  let sex: "f" | "m" = "m";

  if (birthMonth > 70) {
    birthMonthReal = birthMonth - 70;
    sex = "f";
  } else if (birthMonth > 50) {
    birthMonthReal = birthMonth - 50;
    sex = "f";
  } else if (birthMonth > 20) {
    birthMonthReal = birthMonth - 20;
  }

  const birthYearFull = birthYear < 54 ? 2000 + birthYear : 1900 + birthYear;
  const birthDate = new Date(birthYearFull, birthMonthReal - 1, birthDay);
  const birthDateString = date2ymd(birthDate);

  return {
    birthDateString,
    sex,
  };
}
