import React from "react";
import LoginLayout from "../layout/LoginLayout";

export default function NotFoundPage() {
  return (
    <LoginLayout>
      <div className="text-center">Požadovaná stránka neexistuje</div>
    </LoginLayout>
  );
}
