import React from "react";
import { IconRegularCheckCircle, IconRegularXmarkCircle } from "./Icons";

interface VerificationIconProps {
  verified: boolean;
}

export default function VerificationIcon({ verified }: VerificationIconProps) {
  if (verified) {
    return (
      <IconRegularCheckCircle
        title="Osobní údaje ověřeny"
        className="text-success small"
      />
    );
  }

  return (
    <IconRegularXmarkCircle
      title="Osobní údaje nejsou ověřeny"
      className="text-warning small"
    />
  );
}
