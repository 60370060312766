import DateRange from '@monorepo/common/component/DateRange';
import { IconSolidTrashAlt } from '@monorepo/common/component/Icons';
import MoneyLabel from '@monorepo/common/component/MoneyLabel';
import { NoDataWithMarginBottom } from '@monorepo/common/component/NoData';
import React from 'react';
import { Button, Card, CardBody, CardHeader, Table } from 'reactstrap';
import DisciplineAccompanistDetailDeleteModal from './DisciplineAccompanistDetailDeleteModal';
import DisciplineAccompanistDetailNewModal from './DisciplineAccompanistDetailNewModal';

interface AccompanistType {
  __typename?: 'DisciplineAccompanist';
  id: string;
  birthDateFrom?: string | null;
  birthDateTo?: string | null;
  price: number;
}

interface DisciplineAccompanistTableProps {
  accompanists: AccompanistType[];
  disciplineId: string;
}

function DisciplineAccompanistTable({ accompanists, disciplineId }: DisciplineAccompanistTableProps) {
  return (
    <Table size="sm" striped>
      <thead>
        <tr>
          <th>Datum narození</th>
          <th>Cena</th>
          <th>Akce</th>
        </tr>
      </thead>
      <tbody>
        {accompanists.map(accompanist => (
          <tr key={accompanist.id}>
            <td>
              <DateRange dateFromString={accompanist.birthDateFrom} dateToString={accompanist.birthDateTo} />
            </td>
            <td className="text-right">
              <MoneyLabel amount={accompanist.price} />
            </td>
            <td>
              <DisciplineAccompanistDetailDeleteModal disciplineId={disciplineId} accompanist={accompanist}>
                {(handleModalOpen, title) => (
                  <IconSolidTrashAlt onClick={handleModalOpen} title={title} className="action-icon text-danger" />
                )}
              </DisciplineAccompanistDetailDeleteModal>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

interface DisciplineAccompanistListProps {
  accompanists: AccompanistType[];
  disciplineId: string;
}

export default function DisciplineAccompanistList({ accompanists, disciplineId }: DisciplineAccompanistListProps) {
  return (
    <Card>
      <CardHeader tag="h4" className="text-center">
        Doprovod
      </CardHeader>
      <CardBody>
        {!!accompanists.length && (
          <DisciplineAccompanistTable accompanists={accompanists} disciplineId={disciplineId} />
        )}

        {!accompanists.length && <NoDataWithMarginBottom text="Žádné doprovody" />}

        <DisciplineAccompanistDetailNewModal key={disciplineId} title="Přidání doprovodu" disciplineId={disciplineId}>
          {(handleModalOpen, title) => (
            <Button block size="sm" onClick={handleModalOpen} title={title}>
              Přidat
            </Button>
          )}
        </DisciplineAccompanistDetailNewModal>
      </CardBody>
    </Card>
  );
}
