import MoneyLabel from '@monorepo/common/component/MoneyLabel';
import _range from 'lodash-es/range';
import React from 'react';
import { Card, CardBody, CardHeader, Table } from 'reactstrap';
import { sumStats } from './StatsAll';

export interface StatPaymentDisciplineType {
  periodNumber: number;
  count: number;
  sum: number;
}

interface StatsPaymentDisciplineProps {
  statsPaymentDiscipline: StatPaymentDisciplineType[];
  maxRows: number;
}

export default function StatsPaymentDiscipline({ statsPaymentDiscipline, maxRows }: StatsPaymentDisciplineProps) {
  const total = sumStats(statsPaymentDiscipline);

  return (
    <Card>
      <CardHeader tag="h4" className="text-center">
        Oddílové příspěvky
      </CardHeader>
      <CardBody>
        <Table size="sm" striped>
          <thead>
            <tr>
              <th>Období</th>
              <th>Počet</th>
              <th className="text-right">Částka sumy</th>
            </tr>
          </thead>
          <tbody>
            {/* empty rows for better alignment */}
            {_range(Math.max(0, maxRows - statsPaymentDiscipline.length)).map(i => (
              <tr key={i}>
                <td colSpan={3}>&nbsp;</td>
              </tr>
            ))}

            {statsPaymentDiscipline.map(spd => (
              <tr key={spd.periodNumber}>
                <td>{spd.periodNumber / 10}. pololetí</td>
                <td>{spd.count}x</td>
                <td className="text-right">
                  <MoneyLabel amount={spd.sum} />
                </td>
              </tr>
            ))}

            <tr className="font-weight-bold">
              <td>Celkem</td>
              <td>{total.count}x</td>
              <td className="text-right">
                <MoneyLabel amount={total.sum} />
              </td>
            </tr>
          </tbody>
        </Table>
      </CardBody>
    </Card>
  );
}
