import React, { useEffect, useState } from "react";

interface formatDeltaProps {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}

const formatDelta = function ({
  days,
  hours,
  minutes,
  seconds,
}: formatDeltaProps) {
  function format(count: number, label: string) {
    return count ? `${count} ${label}` : "";
  }

  return `${format(days, "dní")} ${format(hours, "hodin")} ${format(
    minutes,
    "minut"
  )} ${seconds} sekund`;
};

function computeDelta(dateTimeString: string) {
  const diff = new Date(dateTimeString).getTime() - Date.now();

  if (diff < 0) {
    return {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
      totalMs: 0,
    };
  }

  return {
    days: Math.floor(diff / (24 * 60 * 60 * 1000)),
    hours: Math.floor((diff % (24 * 60 * 60 * 1000)) / (60 * 60 * 1000)),
    minutes: Math.floor((diff % (60 * 60 * 1000)) / (60 * 1000)),
    seconds: Math.floor((diff % (60 * 1000)) / 1000),
    totalMs: diff,
  };
}
interface DateTimeCountDownLabelProps {
  dateTimeString: string;
  finishedLabel: string;
}

export default function DateTimeCountDownLabel({
  dateTimeString,
  finishedLabel,
}: DateTimeCountDownLabelProps) {
  const [delta, setDelta] = useState(computeDelta(dateTimeString));

  function tick() {
    const deltaNew = computeDelta(dateTimeString);
    setDelta(deltaNew);
  }

  useEffect(() => {
    if (delta.totalMs > 0) {
      const id = setTimeout(tick, 1000);
      return () => window.clearTimeout(id);
    }
  }, [delta.totalMs]);

  if (finishedLabel && delta.totalMs === 0) {
    return <span>{finishedLabel}</span>;
  }

  return <span>{formatDelta(delta)}</span>;
}
