import { RefetchQueriesFunction, useMutation } from '@apollo/client';
import DateTimeLabel from '@monorepo/common/component/DateTimeLabel';
import { IconSolidSync, IconSolidUndo } from '@monorepo/common/component/Icons';
import MoneyLabel from '@monorepo/common/component/MoneyLabel';
import PropTypes from 'prop-types';
import React, { MouseEventHandler, ReactNode, useCallback } from 'react';
import { Col, Row, Table } from 'reactstrap';
import {
  BasicPersonFragment,
  GatewayTransactionCsobStatusEnum,
  RefundTransactionDocument,
  ReverseTransactionDocument,
  UpdateTransactionDocument
} from '../../../../graphql-operations';
import PersonSwitchNameLink from '../../../person/switch/PersonSwitchNameLink';
import OrderTransactionRefundModal, { useOrderTransactionRefundModal } from '../OrderTransactionRefundModal';

const TRANSACTION_STATUS = {
  INITIALIZED: { label: 'Platba založena (1)', color: '' },
  IN_PROGRESS: { label: 'Platba probíhá (2)', color: '' },
  CANCELLED_BY_USER: {
    label: 'Platba zrušena uživatelem (3)',
    color: 'text-danger'
  },
  ACCEPTED: { label: 'Platba potvrzena (4)', color: 'text-success' },
  REVERSED: { label: 'Platba odvolána (5)', color: 'text-warning' },
  REJECTED: { label: 'Platba zamítnuta (6)', color: 'text-danger' },
  WAITING_FOR_CLOSE: { label: 'Čeká na zúčtování (7)', color: 'text-success' },
  CLOSED: { label: 'Platba zúčtována (8)', color: 'text-success' },
  REFUNDING: { label: 'Zpracování vrácení (9)', color: 'text-warning' },
  REFUNDED: { label: 'Platba vrácena (10)', color: 'text-warning' }
};

interface Revision {
  id: string;
  metadata: {
    id: string;
    dateTime: string;
    person: BasicPersonFragment;
  };
  entity: {
    refundReason?: string | null;
    status: {
      type: GatewayTransactionCsobStatusEnum;
    };
  };
}

interface OrderTransactionRevisionsListProps {
  revisions: Revision[];
}

function OrderTransactionRevisionsList({ revisions }: OrderTransactionRevisionsListProps) {
  return (
    <Table size="sm" striped>
      <thead>
        <tr>
          <th>Datum</th>
          <th>Stav</th>
          <th>Autor</th>
        </tr>
      </thead>
      <tbody>
        {revisions.map(revision => {
          const statusLabel = TRANSACTION_STATUS[revision.entity.status.type];
          return (
            <tr key={revision.id}>
              <td>
                <DateTimeLabel dateTimeString={revision.metadata.dateTime} />
              </td>
              <td>
                <span className={statusLabel.color}>{statusLabel.label}</span>
                {revision.entity.refundReason && <div>{revision.entity.refundReason}</div>}
              </td>
              <td>
                <PersonSwitchNameLink person={revision.metadata.person} />
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
}

interface InfoRowProps {
  label: string;
  children: ReactNode;
}

const InfoRow = ({ label, children }: InfoRowProps) => {
  return (
    <Row className="mb-1">
      <Col sm={12} md={3}>
        <span className="text-muted">{label}</span>
      </Col>
      <Col sm={12} md={9}>
        {children}
      </Col>
    </Row>
  );
};

interface RefundTransactionIconProps {
  refundMutation: typeof RefundTransactionDocument | typeof ReverseTransactionDocument;
  transactionId: string;
  amount: number;
  refetchQueries: RefetchQueriesFunction;
}

function RefundTransactionIcon({ refundMutation, transactionId, amount, refetchQueries }: RefundTransactionIconProps) {
  const { modalProps, openModal } = useOrderTransactionRefundModal(refundMutation, transactionId, refetchQueries);

  return (
    <>
      <IconSolidUndo onClick={openModal} title="Vrácení peněz" className="action-icon text-warning" />
      <OrderTransactionRefundModal {...modalProps} amount={amount} />
    </>
  );
}

export interface TransactionCsob {
  id: string;
  payId: string;
  amount: number;
  refundReason?: string | null;
  status: {
    type: GatewayTransactionCsobStatusEnum;
    reversible: boolean;
    refundable: boolean;
  };
  revisions: Revision[];
}

export interface OrderTransactionCsobDetailProps {
  transaction: TransactionCsob;
  refetchQueries: RefetchQueriesFunction;
}

export default function OrderTransactionCsobDetail({ transaction, refetchQueries }: OrderTransactionCsobDetailProps) {
  const [updateTransaction] = useMutation(UpdateTransactionDocument, {
    refetchQueries
  });

  const statusLabel = TRANSACTION_STATUS[transaction.status.type];

  const refundMutation = transaction.status.reversible
    ? ReverseTransactionDocument
    : transaction.status.refundable
    ? RefundTransactionDocument
    : undefined;

  const onUpdateTransactionClick = useCallback<MouseEventHandler>(
    e => {
      e.preventDefault();
      updateTransaction({
        variables: {
          id: transaction.id
        }
      });
    },
    [updateTransaction, transaction.id]
  );

  return (
    <>
      <InfoRow label="PayId">{transaction.payId}</InfoRow>

      <InfoRow label="Stav">
        <span className={statusLabel.color}>{statusLabel.label}</span>{' '}
        <IconSolidSync onClick={onUpdateTransactionClick} title="Aktualizovat" className="action-icon text-primary" />
      </InfoRow>

      {transaction.refundReason && (
        <InfoRow label="Důvod vrácení peněz">
          <span>{transaction.refundReason}</span>
        </InfoRow>
      )}

      <InfoRow label="Částka">
        <MoneyLabel amount={transaction.amount} />{' '}
        {refundMutation && (
          <RefundTransactionIcon
            refundMutation={refundMutation}
            transactionId={transaction.id}
            amount={transaction.amount}
            refetchQueries={refetchQueries}
          />
        )}
      </InfoRow>

      <Row className="mb-1">
        <Col sm={12}>
          <span className="text-muted">Historie stavů</span>
        </Col>
        <Col sm={12}>
          <OrderTransactionRevisionsList revisions={[...transaction.revisions].reverse()} />
        </Col>
      </Row>
    </>
  );
}

OrderTransactionCsobDetail.propTypes = {
  transaction: PropTypes.shape({
    id: PropTypes.string.isRequired,
    payId: PropTypes.string.isRequired,
    status: PropTypes.shape({
      type: PropTypes.string.isRequired,
      reversible: PropTypes.bool.isRequired,
      refundable: PropTypes.bool.isRequired
    }).isRequired,
    amount: PropTypes.number.isRequired
  }).isRequired,
  refetchQueries: PropTypes.func
};
