import React from "react";
import { isDef } from "../util/Text";
import DateLabel from "./DateLabel";

interface DateRangeProps {
  dateFromString?: string | null;
  dateToString?: string | null;
  emptyLabel?: string;
}

export default function DateRange({
  dateFromString,
  dateToString,
  emptyLabel,
}: DateRangeProps) {
  if (dateFromString && dateToString) {
    return (
      <span>
        <DateLabel dateString={dateFromString} /> -{" "}
        <DateLabel dateString={dateToString} />{" "}
      </span>
    );
  }

  if (isDef(dateToString)) {
    return (
      <span>
        do <DateLabel dateString={dateToString} />{" "}
      </span>
    );
  }

  if (isDef(dateFromString)) {
    return (
      <span>
        od <DateLabel dateString={dateFromString} />
      </span>
    );
  }

  if (emptyLabel) {
    return <span>{emptyLabel}</span>;
  }

  return null;
}
