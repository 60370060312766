import React from "react";
import { Col, Progress, Row } from "reactstrap";

interface OccupancyProgressProps {
  paidCount: number;
  reservationsCount: number;
  capacity: number;
}

export default function OccupancyProgress({
  paidCount,
  reservationsCount,
  capacity,
}: OccupancyProgressProps) {
  const total = paidCount + reservationsCount;

  const maxTmp = capacity || total;
  const percent = total / maxTmp;
  let color;

  if (percent >= 1) {
    color = "danger";
  } else if (percent > 0.7) {
    color = "warning";
  } else {
    color = "primary";
  }

  return (
    <Row>
      <Col sm="auto">
        <span>
          {total} / {capacity}
        </span>
      </Col>
      <Col>
        <Progress multi style={{ height: 24 }}>
          <Progress bar value={paidCount} color={color} max={capacity} />

          <Progress
            bar
            striped
            value={reservationsCount}
            color={color}
            max={capacity}
          />
        </Progress>
      </Col>
    </Row>
  );
}
