import React, { FocusEventHandler, ReactNode } from "react";
import { Button, ButtonGroup, FormFeedback, Input } from "reactstrap";

interface CommonButtonGroupProps<T> {
  items?: T[] | null;
  selectedItem?: T | null;
  item2label?: (item: T) => ReactNode;
  item2id?: (item: T) => string;
  error?: string;
  onChange: (item: T) => void;
  onBlur?: FocusEventHandler;
}

export default function CommonButtonGroup<T>({
  items,
  selectedItem,
  item2label = (item) => JSON.stringify(item),
  item2id = (item) => JSON.stringify(item),
  error,
  onChange,
  onBlur,
}: CommonButtonGroupProps<T>) {
  return (
    <>
      <ButtonGroup>
        {items?.map((item) => {
          const selected =
            !!selectedItem && item2id(item) === item2id(selectedItem);
          return (
            <Button
              key={item2id(item)}
              active={selected}
              outline={!selected}
              onClick={() => onChange(item)}
              onBlur={onBlur}
            >
              {item2label(item)}
            </Button>
          );
        })}
      </ButtonGroup>
      <Input type="hidden" invalid={!!error} />
      {error && <FormFeedback>{error}</FormFeedback>}
    </>
  );
}
