import React, { ChangeEvent, useEffect, useState } from "react";
import { Button, Form, FormGroup, Input, Label } from "reactstrap";
import LoginLayout from "../layout/LoginLayout";
import { preventedDefault } from "../util/Input";
import { xhrGet, xhrPut } from "../util/Network";
import { valueOrEmpty } from "../util/Text";

enum STATE {
  LOADING = "LOADING",
  INVALID = "INVALID",
  EDIT = "EDIT",
  POSTING = "POSTING",
  ERROR = "ERROR",
  SUCCESS = "SUCCESS",
  NOT_SAME = "NOT_SAME",
}

interface ChangePasswordPageProps {
  token: string;
}

export default function ChangePasswordPage({ token }: ChangePasswordPageProps) {
  const [password, setPassword] = useState<string | undefined>(undefined);
  const [password2, setPassword2] = useState<string | undefined>(undefined);
  const [state, setState] = useState(STATE.LOADING);

  useEffect(() => {
    xhrGet(`/rest/auth/password/token/${token}`)
      .then(() => {
        setState(STATE.EDIT);
      })
      .catch((e) => {
        console.error(e);
        setState(STATE.INVALID);
      });
  }, [token]);

  function handleChange() {
    if (password !== password2) {
      setState(STATE.NOT_SAME);
      return;
    }

    setState(STATE.POSTING);
    xhrPut(`/rest/auth/password/change/${token}`, { password })
      .then(() => {
        setState(STATE.SUCCESS);
      })
      .catch((e) => {
        console.error(e);
        setState(STATE.ERROR);
      });
  }

  if (state === STATE.LOADING) {
    return null;
  }

  return (
    <LoginLayout>
      <Form onSubmit={preventedDefault(handleChange)}>
        {state === STATE.INVALID ? (
          <span className="text-danger">
            Požadavek na změnu hesla neexistuje nebo již není platný
          </span>
        ) : (
          <>
            {/*result message*/}
            {state === STATE.SUCCESS && (
              <span className="text-success">Heslo bylo úspěšně změněno.</span>
            )}
            {state === STATE.ERROR && (
              <span className="text-danger">
                Došlo k neznámé chybě. Opakujte požadavek později.
              </span>
            )}
            {state === STATE.NOT_SAME && (
              <span className="text-danger">Hesla nejsou stejná.</span>
            )}

            <FormGroup>
              <Label for="password">Nové heslo</Label>
              <Input
                type="password"
                name="password"
                id="password"
                value={valueOrEmpty(password)}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setPassword(e.target.value)
                }
              />
            </FormGroup>

            <FormGroup>
              <Label for="password2">Nové heslo znova</Label>
              <Input
                type="password"
                name="password2"
                id="password2"
                value={valueOrEmpty(password2)}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setPassword2(e.target.value)
                }
              />
            </FormGroup>
            <Button
              type="submit"
              color="primary"
              disabled={state === STATE.SUCCESS || state === STATE.POSTING}
            >
              Změnit heslo
            </Button>
          </>
        )}
      </Form>
    </LoginLayout>
  );
}
