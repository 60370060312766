import { ApolloError } from "@apollo/client";
import React from "react";
import { Link } from "react-router-dom";
import { ListGroup, ListGroupItem } from "reactstrap";
import Loader from "./Loader";

interface ListProps<T> {
  items?: T[];
  loading: boolean;
  error?: ApolloError;
  selectedId?: string;
  idExtractor: (item: T) => string;
  urlExtractor: (item: T, selected: boolean) => string;
  filter?: string;
  onSelect?: (item: T) => void;
  children: (item: T, filter?: string) => React.ReactNode;
}

export default function List<T>({
  items,
  selectedId,
  idExtractor,
  urlExtractor,
  filter,
  loading,
  error,
  children,
  onSelect,
}: ListProps<T>) {
  return (
    <>
      <ListGroup>
        <Loader loading={loading} error={error}>
          {items &&
            items.map((item) => {
              const id = idExtractor(item);
              const selected = selectedId === id;
              return (
                <ListGroupItem
                  key={id}
                  tag={Link}
                  to={urlExtractor(item, selected)}
                  action
                  onClick={() => onSelect && onSelect(item)}
                  active={selected}
                >
                  {children(item, filter)}
                </ListGroupItem>
              );
            })}
        </Loader>
      </ListGroup>
    </>
  );
}
