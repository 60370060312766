export interface User {
  id: string;
  username: string;
  firstName: string;
  lastName: string;
  authorities: string[];
}

export enum Sex {
  MALE = "m",
  FEMALE = "f",
}

export interface PersonData {
  id: string;
  degreeBefore?: string | null;
  firstName: string;
  lastName: string;
  degreeAfter?: string | null;
}

export interface Person {
  id: string;
  personData: PersonData;
}

export interface Client {
  id: string;
  personData: PersonData;
}

export interface Discipline {
  name: string;
}

export enum StampType {
  SENIOR = "SENIOR",
  ADULT = "ADULT",
  CHILD = "CHILD",
  EXTERNAL = "EXTERNAL",
}

export interface Period {
  id: string;
  year: number;
  number: number;
}

// FORMIK

export interface Validation<T> {
  errors: Partial<Record<keyof T, string>>;
  warnings: Partial<Record<keyof T, string>>;
  touched: Partial<Record<keyof T, string>>;
}

// MISC
type Entries<T> = {
  [K in keyof T]: [K, T[K]];
}[keyof T][];

export function entries<T>(obj: T): Entries<T> {
  return Object.entries(obj) as any;
}

export type PartialNull<T> = {
  [P in keyof T]?: T[P] | null;
};

/**
 * Makes optional only given keys
 */
export type Optional<T, K extends keyof T> = Partial<T> & Omit<T, K>;

/**
 * General type guards for field, checks for null and undefined
 */
type MakeDefined<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>> &
  { [P in K]-?: Exclude<T[P], undefined> };

export function ensureFieldDefined<T, K extends keyof T>(
  o: T | MakeDefined<T, K>,
  field: K
): o is MakeDefined<T, K> {
  return o[field] !== undefined;
}

type MakeNonNull<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>> &
  { [P in K]: NonNullable<Exclude<T[P], undefined>> };

export function ensureFieldNotNull<T, K extends keyof T>(
  o: T | MakeNonNull<T, K>,
  field: K
): o is MakeNonNull<T, K> {
  return o[field] !== null;
}
