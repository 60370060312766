import React from "react";
import FormikConfirmModal from "../../modal/FormikConfirmModal";
import TokenOfferPriceEditForm, {
  createInitialValues,
  TokenOfferPriceEditFormType,
  validationSchemaFactory,
} from "./TokenOfferPriceEditForm";

interface TokenOfferPriceEditModalProps<F> {
  isOpen: boolean;
  onCancel: () => Promise<any>;
  onConfirm: (value: F) => Promise<any>;
  onChange: (value: F) => void;
  title: string;
  confirmButtonText: string;

  priceFrom: number;
}

export default function TokenOfferPriceEditModal({
  isOpen,
  onCancel,
  onConfirm,
  onChange,
  title,
  confirmButtonText,
  priceFrom,
}: TokenOfferPriceEditModalProps<TokenOfferPriceEditFormType>) {
  return (
    <FormikConfirmModal
      isOpen={isOpen}
      title={title}
      size="md"
      confirmButtonText={confirmButtonText}
      initialValues={createInitialValues(priceFrom)}
      validationSchema={validationSchemaFactory(priceFrom)}
      onCancel={onCancel}
      onConfirm={onConfirm}
      onChange={onChange}
    >
      <TokenOfferPriceEditForm priceFrom={priceFrom} />
    </FormikConfirmModal>
  );
}
