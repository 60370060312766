import React from "react";

const formatTime = function (timeString: string, precision: Precision) {
  switch (precision) {
    case Precision.SECOND:
      return timeString;
    case Precision.MINUTE:
      return timeString.substring(0, 5);
    case Precision.HOUR:
      return timeString.substring(0, 2);
    default:
      return timeString;
  }
};

export enum Precision {
  SECOND,
  MINUTE,
  HOUR,
}

interface TimeLabelProps {
  timeString: string;
  precision?: Precision;
}

export default function TimeLabel({
  timeString,
  precision = Precision.MINUTE,
}: TimeLabelProps) {
  return <span>{formatTime(timeString, precision)}</span>;
}
