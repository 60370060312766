import React from "react";
import { Button, ButtonGroup } from "reactstrap";
import { Sex } from "../../type/Types";

interface SexButtonGroupProps {
  sex: Sex;
  disabled?: boolean;
  onChange: (sex: Sex) => void;
  onBlur: (sex: Sex) => void;
}

export default function SexButtonGroup({
  sex,
  disabled,
  onChange,
  onBlur,
}: SexButtonGroupProps) {
  return (
    <ButtonGroup>
      {/* Male */}
      <Button
        active={sex === Sex.MALE}
        outline={sex !== Sex.MALE}
        disabled={disabled}
        onClick={() => onChange(Sex.MALE)}
        onBlur={() => onBlur(Sex.MALE)}
      >
        Muž
      </Button>

      {/* Female */}
      <Button
        active={sex === Sex.FEMALE}
        outline={sex !== Sex.FEMALE}
        disabled={disabled}
        onClick={() => onChange(Sex.FEMALE)}
        onBlur={() => onBlur(Sex.FEMALE)}
      >
        Žena
      </Button>
    </ButtonGroup>
  );
}
