import {
  EditFormProps,
  PropChangedHandler,
  PropTouchedHandler,
  Validator
} from '@monorepo/common/component/modal/EditModal';
import { EditRow } from '@monorepo/common/component/modal/EditRow';
import MultiBadgeLabel, { BadgeType } from '@monorepo/common/component/MultiBadgeLabel';
import { PartialNull, Validation } from '@monorepo/common/type/Types';
import { valueOrEmpty } from '@monorepo/common/util/Text';
import { date2ymd } from '@monorepo/common/util/Time';
import { validateAnyField, validateStringField } from '@monorepo/common/util/Validation';
import React from 'react';
import { Col, CustomInput, Form, FormFeedback, FormGroup, Input, InputGroup, Label } from 'reactstrap';
import { InputType } from 'reactstrap/es/Input';
import ClientAutocompleteInput, { ClientType } from '../client/ClientAutocompleteInput';
import PersonSwitchAutocompleteInput from '../person/switch/PersonSwitchAutocompleteInput';
import { LegalRepresentationType } from './LegalRepresentationList';

export const validate: Validator<EditFormData> = (data, touched) => {
  const errors = {};

  validateAnyField('child', 'Dítě', data, errors, touched, { required: true });
  validateAnyField('representative', 'Zákonný zástupce', data, errors, touched, { required: true });
  if (data.gdprSignDateSwitch) {
    validateAnyField('gdprSignDate', 'Udělit GDPR souhlas', data, errors, touched, { required: true });
  }
  validateStringField('role', 'Role', data, errors, touched, { required: true, text: { length: { max: 50 } } });

  return errors;
};

interface OptionalEditRowProps<T, K extends keyof T, KS extends keyof T> {
  label: string;
  property: K;
  switchProperty: KS;
  data: T;
  type?: InputType;
  disabled?: boolean;

  rightLink?: BadgeType<T, K>;

  validation: Validation<T>;
  onPropTouch: PropTouchedHandler<T>;
  onPropChange: PropChangedHandler<T, K>;
}

function OptionalEditRow<T extends PartialNull<Record<K, string>>, K extends keyof T, KS extends keyof T>({
  label,
  switchProperty,
  property,
  data,
  type = 'text',
  disabled,
  rightLink,
  validation,
  onPropTouch,
  onPropChange
}: OptionalEditRowProps<T, K, KS>) {
  const error = validation.errors && validation.errors[property];
  return (
    <FormGroup row>
      <MultiBadgeLabel
        label={label}
        property={property}
        badges={
          rightLink && [
            {
              ...rightLink,
              enabled: rightLink?.enabled && !!valueOrEmpty(data[switchProperty])
            }
          ]
        }
        onPropTouch={onPropTouch}
        onPropChange={onPropChange}
      />
      <Col sm={12} className="text-md-right">
        <InputGroup className="d-flex align-items-center">
          <CustomInput
            id="gdpr"
            type="switch"
            disabled={disabled}
            checked={!!valueOrEmpty(data[switchProperty])}
            // @ts-ignore
            onChange={e => onPropChange(switchProperty, e.target.checked)}
            onBlur={() => onPropTouch(switchProperty)}
          />
          <Input
            id={`${property}`}
            type={type}
            value={valueOrEmpty(data[property])}
            disabled={disabled || !valueOrEmpty(data[switchProperty])}
            invalid={!!error}
            // @ts-ignore
            onChange={e => onPropChange(property, e.target.value)}
            onBlur={() => onPropTouch(property)}
          />

          {error && <FormFeedback>{error}</FormFeedback>}
        </InputGroup>
      </Col>
    </FormGroup>
  );
}

export interface EditFormData extends LegalRepresentationType {
  gdprSignDate?: string;
  gdprSignDateSwitch: boolean;
}

interface LegalRepresentationDetailEditProps extends EditFormProps<EditFormData> {}

export default function LegalRepresentationDetailEdit({
  validation,
  onPropChange,
  onPropTouch,
  ...legalRepresentation
}: LegalRepresentationDetailEditProps) {
  return (
    <Form>
      {/* child (client) */}
      <FormGroup row>
        <Label sm={12}>Dítě</Label>
        <Col sm={12}>
          <ClientAutocompleteInput
            selected={legalRepresentation.child}
            ageTo={18}
            onSelect={(child: ClientType | undefined) => onPropChange('child', child)}
            onBlur={() => onPropTouch('child')}
          />
          <Input type="hidden" invalid={!!validation.errors.child} />
          {validation.errors.child && <FormFeedback>{validation.errors.child}</FormFeedback>}
        </Col>
      </FormGroup>

      {/* legal representative (person) */}
      <FormGroup row>
        <Label sm={12}>Zákonný zástupce</Label>
        <Col sm={12}>
          <PersonSwitchAutocompleteInput
            selected={legalRepresentation.representative}
            ageFrom={18}
            // @ts-ignore
            onSelect={r => onPropChange('representative', r)}
            onBlur={() => onPropTouch('representative')}
          />
          <Input type="hidden" invalid={!!validation.errors.representative} />
          {validation.errors.representative && <FormFeedback>{validation.errors.representative}</FormFeedback>}
        </Col>
      </FormGroup>

      {/* gdpr sign date */}
      {!legalRepresentation.id && (
        <OptionalEditRow
          label="Udělit GDPR souhlas"
          switchProperty="gdprSignDateSwitch"
          property="gdprSignDate"
          data={legalRepresentation}
          type="date"
          rightLink={{
            text: 'dnešní datum',
            enabled: !legalRepresentation.gdprSignDate,
            onClick: changePropValue => changePropValue('gdprSignDate', date2ymd(new Date()))
          }}
          validation={validation}
          onPropTouch={onPropTouch}
          onPropChange={onPropChange}
        />
      )}

      {/* role */}
      <EditRow
        label="Role"
        property="role"
        data={legalRepresentation}
        validation={validation}
        onPropTouch={onPropTouch}
        onPropChange={onPropChange}
      />
    </Form>
  );
}
