import { formatDateNoYear } from '@monorepo/common/component/DateTimeLabel';
import { isDef } from '@monorepo/common/util/Text';
import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from 'chart.js';
import React from 'react';
import { Bar } from 'react-chartjs-2';
import { StatsOrderTotal as StatsOrderTotalType } from '../../graphql-operations';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const options = {
  responsive: true,
  scales: {
    x: {
      stacked: true
    },
    y: {
      stacked: true
    }
  }
};

function sum(a: number | null | undefined, b: number | null | undefined) {
  if (isDef(a) && isDef(b)) {
    return a + b;
  }

  if (isDef(a)) {
    return a;
  }

  if (isDef(b)) {
    return b;
  }

  return a;
}

interface StatsOrderTotalChartProps {
  statsOrderTotal: StatsOrderTotalType[];
}

export default function StatsOrderTotalChart({ statsOrderTotal }: StatsOrderTotalChartProps) {
  const labels = statsOrderTotal.map(s => formatDateNoYear(s.bucket));

  const data = {
    labels,
    datasets: [
      {
        label: 'Čas vypršel',
        data: statsOrderTotal.map(s => s.expired),
        backgroundColor: '#dc3545'
      },
      {
        label: 'Zaplacena',
        data: statsOrderTotal.map(s => s.paid),
        backgroundColor: '#28a745'
      },
      {
        label: 'Nezaplacena',
        data: statsOrderTotal.map(s => sum(s.paying, s.unpaid)),
        backgroundColor: 'rgba(53, 162, 235, 0.9)'
      },
      {
        label: 'Vrácena platba',
        data: statsOrderTotal.map(s => sum(s.refunded, s.refunding)),
        backgroundColor: '#ffc107'
      }
    ]
  };

  return <Bar options={options} data={data} />;
}
