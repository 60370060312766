import { AppConfigContext } from '@monorepo/common/component/AppConfigProvider';
import EditModal, { BaseEditModalProps, VariablesExtractor } from '@monorepo/common/component/modal/EditModal';
import { failOnMissing } from '@monorepo/common/util/Validation';
import React, { useCallback, useContext } from 'react';
import {
  ClientDocument,
  SaveInsuranceReceiptDocument,
  SaveInsuranceReceiptMutationVariables
} from '../../graphql-operations';
import InsuranceReceiptDetailEdit, { EditFormData, validate } from './InsuranceReceiptDetailEdit';

const data2variables: VariablesExtractor<EditFormData, SaveInsuranceReceiptMutationVariables> = data => {
  const { id, paymentDiscipline } = data;

  return {
    id,
    clientId: failOnMissing(data, 'clientId'),
    periodId: failOnMissing(data, 'period').id,
    paymentDisciplineId: paymentDiscipline && paymentDiscipline.id,
    purpose: failOnMissing(data, 'purpose'),
    amount: failOnMissing(data, 'amount')
  };
};

interface InsuranceReceiptDetailEditModalProps extends BaseEditModalProps {
  title: string;
  clientId: string;
  insuranceReceipt?: {
    id?: string;
    receiptId?: string;
    created?: string;
    purpose?: string | null;
    amount: number;
    period: {
      id: string;
      year: number;
      number: number;
    };
    paymentDiscipline?: {
      id: string;
      disciplineConfig: {
        id: string;
        discipline: {
          id: string;
          name: string;
        };
        period: {
          id: string;
          year: number;
          number: number;
        };
      };
      orderDiscipline: {
        id: string;
        priceDiscounted: number;
        orderTotal: {
          id: string;
          orderNumber: string;
        };
      };
    } | null;
  };
}

export default function InsuranceReceiptDetailEditModal({
  title,
  clientId,
  insuranceReceipt,
  children
}: InsuranceReceiptDetailEditModalProps) {
  const refetchQueries = useCallback(() => {
    return [
      {
        query: ClientDocument,
        variables: { id: clientId }
      }
    ];
  }, [clientId]);

  const appConfig = useContext(AppConfigContext);

  return (
    <EditModal
      title={title}
      tag={InsuranceReceiptDetailEdit}
      initialData={{
        clientId,
        period: appConfig.currentPeriod,
        ...insuranceReceipt
      }}
      data2variables={data2variables}
      validate={validate}
      mutation={SaveInsuranceReceiptDocument}
      refetchQueries={refetchQueries}
      children={children}
    />
  );
}
