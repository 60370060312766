import EditModal, {
  BaseEditModalProps,
  SaveCompletedHandler,
  VariablesExtractor
} from '@monorepo/common/component/modal/EditModal';
import { failOnMissing } from '@monorepo/common/util/Validation';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { SavePersonDocument, SavePersonMutation, SavePersonMutationVariables } from '../../graphql-operations';
import PersonDetailEdit, { EditFormData, validate } from './PersonDetailEdit';
import { PersonType } from './PersonDetailView';

interface PersonDetailEditModalProps extends BaseEditModalProps {
  title: string;
  person?: PersonType;
}

const data2variables: VariablesExtractor<EditFormData, SavePersonMutationVariables> = data => {
  const { id, degreeBefore, degreeAfter, sex, phone, phone2, email } = data;

  return {
    id,
    degreeBefore,
    degreeAfter,
    firstName: failOnMissing(data, 'firstName'),
    lastName: failOnMissing(data, 'lastName'),
    sex,
    phone,
    phone2,
    email
  };
};

export default function PersonDetailEditModal({ title, person, children }: PersonDetailEditModalProps) {
  const history = useHistory();

  const handleCompleted: SaveCompletedHandler<SavePersonMutation, EditFormData> = useCallback(
    (data, apolloClient) => {
      // go to detail only when creating new item
      if (!person) {
        history.push('/persons/' + data.savePerson.id);
        apolloClient.resetStore();
      }
    },
    [person, history]
  );

  return (
    <EditModal
      title={title}
      size="lg"
      tag={PersonDetailEdit}
      initialData={{
        ...(person || {}).personData
      }}
      validate={validate}
      data2variables={data2variables}
      mutation={SavePersonDocument}
      onCompleted={handleCompleted}
      children={children}
    />
  );
}
