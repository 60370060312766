import React from "react";

interface PeriodLongLabelProps {
  period: {
    year: number;
    number: number;
  };
}

export default function PeriodLongLabel({ period }: PeriodLongLabelProps) {
  return (
    <span>
      {period.number / 10}. pol. {period.year}
    </span>
  );
}
