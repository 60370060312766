let sequence = 0;

export default class Sequence {
  static getCurrent(name?: string) {
    return (name || "") + sequence;
  }

  static getNext(name?: string) {
    return (name || "") + ++sequence;
  }
}
