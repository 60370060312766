import React, { ReactNode } from "react";
import { NavLink } from "react-router-dom";

interface OrderLinkProps {
  orderNumber: string;
  children: ReactNode;
}

export default function OrderLink({ orderNumber, children }: OrderLinkProps) {
  return <NavLink to={`/orders/${orderNumber}`}>{children}</NavLink>;
}
