import { TypedDocumentNode } from "@apollo/client";
import { OperationVariables } from "@apollo/client/core";
import { Query } from "@apollo/client/react/components";
import {
  QueryFunctionOptions,
  QueryResult,
} from "@apollo/client/react/types/types";
import { DocumentNode } from "graphql";
import React from "react";
import Loader from "./Loader";

interface QueryResultWithData<TData, TVariables>
  extends QueryResult<TData, TVariables> {
  data: TData;
}

// extends QueryComponentOptions<TData> but uses QueryResultWithData
interface QueryWithLoaderProps<TData, TVariables>
  extends QueryFunctionOptions<TData, TVariables> {
  children: (
    result: QueryResultWithData<TData, TVariables>
  ) => JSX.Element | null;
  query: DocumentNode | TypedDocumentNode<TData, TVariables>;
}

export default function QueryWithLoader<
  TData,
  TVariables extends OperationVariables = OperationVariables
>(props: QueryWithLoaderProps<TData, TVariables>) {
  return (
    <Query<TData, TVariables> {...props}>
      {({ data, loading, error, ...rest }: QueryResult<TData, TVariables>) => (
        <Loader loading={loading} error={error}>
          {/*Children caching is necessary to enable loader over old content. Otherwise new props propagate into children*/}
          {/*render prop still having old data */}
          {/*never render old data when error occurs - it shows misleading content*/}
          {data &&
            !loading &&
            !error &&
            props.children({ data, loading, error, ...rest })}
        </Loader>
      )}
    </Query>
  );
}
