import CommonButtonGroup from '@monorepo/common/component/form/CommonButtonGroup';
import ValidatedInputGroup from '@monorepo/common/component/form/ValidatedInputGroup';
import { EditFormProps, Validator } from '@monorepo/common/component/modal/EditModal';
import PeriodLabel from '@monorepo/common/component/PeriodLabel';
import { Period } from '@monorepo/common/type/Types';
import { integerNormalizer2 } from '@monorepo/common/util/Normalizer';
import { validateAnyField, validateStringField } from '@monorepo/common/util/Validation';
import React from 'react';
import { Col, Form, FormGroup, Label } from 'reactstrap';

export const validate: Validator<EditFormData> = (data, touched) => {
  const errors = {};

  validateAnyField('disciplinePaymentScheduleRecurringDate', '1. vlna', data, errors, touched, { required: true });
  validateAnyField('disciplinePaymentScheduleOtherDate', '2. vlna', data, errors, touched, { required: true });
  validateAnyField('disciplinePaymentScheduleSignUpDate', '3. vlna', data, errors, touched, { required: true });
  validateAnyField('disciplinePaymentScheduleEndDate', 'Konec', data, errors, touched, { required: true });

  validateStringField('stampPriceChild', 'Cena známky pro děti do 18 let', data, errors, touched, {
    required: true,
    number: { range: { min: 0 } }
  });
  validateStringField('stampPriceAdult', 'Cena známky pro dospělé', data, errors, touched, {
    required: true,
    number: { range: { min: 0 } }
  });
  validateStringField('stampPriceSenior', 'Cena známky pro seniory', data, errors, touched, {
    required: true,
    number: { range: { min: 0 } }
  });
  validateAnyField('stampPaymentEndDate', 'Konec', data, errors, touched, { required: true });
  validateAnyField('tokenPaymentEndDate', 'Konec', data, errors, touched, { required: true });

  return errors;
};

export interface EditFormData {
  id: string;
  periods: Period[];
  period: Period;
  disciplinePaymentScheduleRecurringDate: string;
  disciplinePaymentScheduleOtherDate: string;
  disciplinePaymentScheduleSignUpDate: string;
  disciplinePaymentScheduleEndDate: string;
  stampPriceChild: number;
  stampPriceAdult: number;
  stampPriceSenior: number;
  stampPaymentEndDate: string;
  tokenPaymentEndDate: string;
}

interface PeriodAppConfigDetailEditProps extends EditFormProps<EditFormData> {}

export default function PeriodAppConfigDetailEdit({
  validation,
  onPropTouch,
  onPropChange,
  periods,
  ...periodAppConfig
}: PeriodAppConfigDetailEditProps) {
  const { id, period } = periodAppConfig;
  return (
    <Form>
      {/*Period*/}
      {!id && (
        <FormGroup row>
          <Label sm={12} md={3}>
            Období
          </Label>
          <Col sm={12} md={9} className="text-md-right">
            <CommonButtonGroup
              items={periods}
              selectedItem={period}
              item2label={p => <PeriodLabel period={p} />}
              item2id={p => p.id}
              error={validation.errors.period}
              onChange={p => onPropChange('period', p)}
              onBlur={() => onPropTouch('period')}
            />
          </Col>
        </FormGroup>
      )}
      {id && period && (
        <FormGroup row>
          <Col sm={12} md={3}>
            Období
          </Col>
          <Col sm={12} md={9} className="text-md-right">
            <PeriodLabel period={period} />
          </Col>
        </FormGroup>
      )}

      <h5>Oddíly</h5>

      {/*1. vlna*/}
      <FormGroup row>
        <Label sm={12}>Datum spuštění 1. vlny (členové z předchozího období)</Label>
        <Col sm={12} className="text-md-right">
          <ValidatedInputGroup
            property="disciplinePaymentScheduleRecurringDate"
            data={periodAppConfig}
            type="date"
            validation={validation}
            onPropTouch={onPropTouch}
            onPropChange={onPropChange}
          />
        </Col>
      </FormGroup>

      {/*2. vlna*/}
      <FormGroup row>
        <Label sm={12}>Datum spuštění 2. vlny (ostatní členové)</Label>
        <Col sm={12} className="text-md-right">
          <ValidatedInputGroup
            property="disciplinePaymentScheduleOtherDate"
            data={periodAppConfig}
            type="date"
            validation={validation}
            onPropTouch={onPropTouch}
            onPropChange={onPropChange}
          />
        </Col>
      </FormGroup>

      {/*3. vlna*/}
      <FormGroup row>
        <Label sm={12}>Datum spuštění 3. vlny (online registrace)</Label>
        <Col sm={12} className="text-md-right">
          <ValidatedInputGroup
            property="disciplinePaymentScheduleSignUpDate"
            data={periodAppConfig}
            type="date"
            validation={validation}
            onPropTouch={onPropTouch}
            onPropChange={onPropChange}
          />
        </Col>
      </FormGroup>

      {/*konec*/}
      <FormGroup row>
        <Label sm={12}>Datum ukončení online plateb (ke konci tohoto dne)</Label>
        <Col sm={12} className="text-md-right">
          <ValidatedInputGroup
            property="disciplinePaymentScheduleEndDate"
            data={periodAppConfig}
            type="date"
            validation={validation}
            onPropTouch={onPropTouch}
            onPropChange={onPropChange}
          />
        </Col>
      </FormGroup>

      <h5>Ceny známek</h5>

      <FormGroup row>
        <Label sm={4}>Děti (do 18 let)</Label>
        <Col sm={8} className="text-md-right">
          <ValidatedInputGroup
            property="stampPriceChild"
            data={periodAppConfig}
            currency="Kč"
            normalizer2={integerNormalizer2}
            validation={validation}
            onPropTouch={onPropTouch}
            onPropChange={onPropChange}
          />
        </Col>
      </FormGroup>

      <FormGroup row>
        <Label sm={4}>Dospělí</Label>
        <Col sm={8} className="text-md-right">
          <ValidatedInputGroup
            property="stampPriceAdult"
            data={periodAppConfig}
            currency="Kč"
            normalizer2={integerNormalizer2}
            validation={validation}
            onPropTouch={onPropTouch}
            onPropChange={onPropChange}
          />
        </Col>
      </FormGroup>

      <FormGroup row>
        <Label sm={4}>Senioři</Label>
        <Col sm={8} className="text-md-right">
          <ValidatedInputGroup
            property="stampPriceSenior"
            data={periodAppConfig}
            currency="Kč"
            normalizer2={integerNormalizer2}
            validation={validation}
            onPropTouch={onPropTouch}
            onPropChange={onPropChange}
          />
        </Col>
      </FormGroup>

      {/*konec*/}
      <FormGroup row>
        <Label sm={12}>Datum ukončení online plateb (ke konci tohoto dne)</Label>
        <Col sm={12} className="text-md-right">
          <ValidatedInputGroup
            property="stampPaymentEndDate"
            data={periodAppConfig}
            type="date"
            validation={validation}
            onPropTouch={onPropTouch}
            onPropChange={onPropChange}
          />
        </Col>
      </FormGroup>

      <h5>Mimořádné známky</h5>

      <FormGroup row>
        <Label sm={12}>Datum ukončení online plateb (ke konci tohoto dne)</Label>
        <Col sm={12} className="text-md-right">
          <ValidatedInputGroup
            property="tokenPaymentEndDate"
            data={periodAppConfig}
            type="date"
            validation={validation}
            onPropTouch={onPropTouch}
            onPropChange={onPropChange}
          />
        </Col>
      </FormGroup>
    </Form>
  );
}
