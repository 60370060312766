import { RefetchQueriesFunction } from '@apollo/client';
import DateTimeLabel from '@monorepo/common/component/DateTimeLabel';
import { IconSolidUndo } from '@monorepo/common/component/Icons';
import MoneyLabel from '@monorepo/common/component/MoneyLabel';
import React, { ReactNode } from 'react';
import { Col, Row, Table } from 'reactstrap';
import {
  BasicPersonFragment,
  RefundTransactionCashDocument,
  TransactionCashStatusEnum
} from '../../../../graphql-operations';
import PersonSwitchNameLink from '../../../person/switch/PersonSwitchNameLink';
import OrderTransactionRefundModal, { useOrderTransactionRefundModal } from '../OrderTransactionRefundModal';

const TRANSACTION_STATUS = {
  CLOSED: { label: 'Zaplaceno', color: 'text-success' },
  REFUNDED: { label: 'Platba vrácena', color: 'text-warning' }
};

interface Revision {
  id: string;
  metadata: {
    id: string;
    dateTime: string;
    person: BasicPersonFragment;
  };
  entity: {
    refundReason?: string | null;
    status: {
      type: TransactionCashStatusEnum;
    };
  };
}

interface OrderTransactionRevisionsListProps {
  revisions: Revision[];
}

function OrderTransactionRevisionsList({ revisions }: OrderTransactionRevisionsListProps) {
  return (
    <Table size="sm" striped>
      <thead>
        <tr>
          <th>Datum</th>
          <th>Stav</th>
          <th>Autor</th>
        </tr>
      </thead>
      <tbody>
        {revisions.map(revision => {
          const statusLabel = TRANSACTION_STATUS[revision.entity.status.type];
          return (
            <tr key={revision.id}>
              <td>
                <DateTimeLabel dateTimeString={revision.metadata.dateTime} />
              </td>
              <td>
                <span className={statusLabel.color}>{statusLabel.label}</span>
                {revision.entity.refundReason && <div>{revision.entity.refundReason}</div>}
              </td>
              <td>
                <PersonSwitchNameLink person={revision.metadata.person} />
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
}

interface InfoRowProps {
  label: string;
  children: ReactNode;
}

const InfoRow = ({ label, children }: InfoRowProps) => {
  return (
    <Row className="mb-1">
      <Col sm={12} md={3}>
        <span className="text-muted">{label}</span>
      </Col>
      <Col sm={12} md={9}>
        {children}
      </Col>
    </Row>
  );
};

interface RefundTransactionIconProps {
  refundMutation: typeof RefundTransactionCashDocument;
  transactionId: string;
  amount: number;
  refetchQueries: RefetchQueriesFunction;
}

function RefundTransactionIcon({ refundMutation, transactionId, amount, refetchQueries }: RefundTransactionIconProps) {
  const { modalProps, openModal } = useOrderTransactionRefundModal(refundMutation, transactionId, refetchQueries);

  return (
    <>
      <IconSolidUndo onClick={openModal} title="Vrácení peněz" className="action-icon text-warning" />
      <OrderTransactionRefundModal {...modalProps} amount={amount} />
    </>
  );
}

export interface TransactionCash {
  id: string;
  amount: number;
  refundReason?: string | null;
  status: {
    type: TransactionCashStatusEnum;
    refundable: boolean;
  };
  revisions: Revision[];
}

interface OrderTransactionCashDetailProps {
  transaction: TransactionCash;
  refetchQueries: RefetchQueriesFunction;
}

export default function OrderTransactionCashDetail({ transaction, refetchQueries }: OrderTransactionCashDetailProps) {
  const statusLabel = TRANSACTION_STATUS[transaction.status.type];
  const refundMutation = transaction.status.refundable ? RefundTransactionCashDocument : undefined;

  return (
    <>
      <InfoRow label="Stav">
        <span className={statusLabel.color}>{statusLabel.label}</span>
      </InfoRow>
      {transaction.refundReason && (
        <InfoRow label="Důvod vrácení peněz">
          <span>{transaction.refundReason}</span>
        </InfoRow>
      )}

      <InfoRow label="Částka">
        <MoneyLabel amount={transaction.amount} />{' '}
        {refundMutation && (
          <RefundTransactionIcon
            refundMutation={refundMutation}
            transactionId={transaction.id}
            amount={transaction.amount}
            refetchQueries={refetchQueries}
          />
        )}
      </InfoRow>

      <Row className="mb-1">
        <Col sm={12}>
          <span className="text-muted">Historie stavů</span>
        </Col>
        <Col sm={12}>
          <OrderTransactionRevisionsList revisions={[...transaction.revisions].reverse()} />
        </Col>
      </Row>
    </>
  );
}
