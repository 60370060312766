import { useCallback, useRef, useState } from "react";

interface PromiseRef<T> {
  resolve?: (value: T) => void;
  reject?: (reason: any) => void;
}

export interface ControlledConfirmModalProps<T = unknown> {
  isOpen: boolean;
  onConfirm: (value: T) => Promise<any>;
  onCancel: () => Promise<any>;
}

export default function useConfirmModal<T = unknown>(
  initialIsOpen: boolean = false
) {
  const [isOpen, setIsOpen] = useState(initialIsOpen);

  const confirmPromiseRef = useRef<PromiseRef<T>>({});

  const onCancel = useCallback(
    () =>
      new Promise((resolve) => {
        setIsOpen(false);
        // disable reject now - it throws error to console on cancel button click
        // confirmPromiseRef.current.reject && confirmPromiseRef.current.reject();
        resolve();
      }),
    [setIsOpen, confirmPromiseRef]
  );

  const onConfirm = useCallback(
    (values: T) =>
      new Promise((resolve) => {
        setIsOpen(false);
        confirmPromiseRef.current.resolve &&
          confirmPromiseRef.current.resolve(values);
        resolve();
      }),
    [setIsOpen, confirmPromiseRef]
  );

  const confirm = useCallback(() => {
    return new Promise<T>((resolve, reject) => {
      setIsOpen(true);
      confirmPromiseRef.current = { resolve, reject };
    });
  }, [setIsOpen, confirmPromiseRef]);

  const modalProps: ControlledConfirmModalProps<T> = {
    isOpen,
    onCancel,
    onConfirm,
  };

  return { modalProps, confirm };
}
