import { RefetchQueriesFunction } from '@apollo/client';
import DateTimeCountDownLabel from '@monorepo/common/component/DateTimeCountDownLabel';
import DateTimeLabel from '@monorepo/common/component/DateTimeLabel';
import MoneyLabel from '@monorepo/common/component/MoneyLabel';
import PeriodLongLabel from '@monorepo/common/component/PeriodLongLabel';
import StampTypeLabel from '@monorepo/common/component/StampTypeLabel';
import TokenNameLink from '@monorepo/common/component/token/TokenNameLink';
import React, { ReactNode } from 'react';
import { Alert, Button, Col, Container, Row, Table } from 'reactstrap';
import {
  BasicClientFragment,
  BasicPersonFragment,
  ClientDocument,
  DisciplineConfigDocument,
  FullPeriodFragment,
  OrderDocument,
  OrderTotalStatusEnum,
  StampTypeEnum,
  TokenDocument
} from '../../graphql-operations';
import ClientNameLink from '../client/ClientNameLink';
import DisciplineNameLink from '../discipline/DisciplineNameLink';
import PersonSwitchNameLink from '../person/switch/PersonSwitchNameLink';
import { TransactionCash } from './transaction/cash/OrderTransactionCashDetail';
import OrderTransactionCashNewModal, {
  useOrderTransactionCashNewModal
} from './transaction/cash/OrderTransactionCashNewModal';
import { TransactionCsob } from './transaction/csob/OrderTransactionCsobDetail';
import OrderTransactionsList from './transaction/OrderTransactionsList';

interface Order {
  id: string;
  orderNumber: string;
  created: string;
  validBefore: string;
  totalPriceDiscounted: number;
  status: OrderTotalStatusEnum;
  personFrom: BasicPersonFragment;
  ordersDiscipline: Array<{
    id: string;
    priceOrig: number;
    priceDiscounted: number;
    covidAccount: number;
    client: BasicClientFragment;
    disciplineConfig: {
      id: string;
      period: FullPeriodFragment;
      discipline: {
        id: string;
        name: string;
      };
    };
  }>;
  ordersStamp: {
    id: string;
    type: StampTypeEnum;
    priceOrig: number;
    priceDiscounted: number;
    client: BasicClientFragment;
    periodAppConfig: {
      id: string;
      period: {
        id: string;
        year: number;
      };
    };
  }[];
  ordersToken: {
    id: string;
    client: BasicClientFragment;
    token: {
      id: string;
      period: FullPeriodFragment;
      name: string;
    };
    price: number;
  }[];
  gatewayTransactionsCsob: TransactionCsob[];
  transactionsCash: TransactionCash[];
}

interface InfoRowProps {
  label: string;
  children: ReactNode;
}

const InfoRow = ({ label, children }: InfoRowProps) => {
  return (
    <Row className="mb-1">
      <Col sm={12} md={3}>
        <span className="text-muted">{label}</span>
      </Col>
      <Col sm={12} md={9}>
        {children}
      </Col>
    </Row>
  );
};

interface OrdersTableProps {
  order: Order;
}

function OrdersTable({ order }: OrdersTableProps) {
  return (
    <Table size="sm" striped>
      <thead>
        <tr>
          <th>Období</th>
          <th>Oddíl / známka</th>
          <th>Pro</th>
          <th className="text-right">Částka bez slevy</th>
          <th className="text-right">Částka po slevě</th>
          <th className="text-right">Covid účet</th>
        </tr>
      </thead>
      <tbody>
        {/*orders stamp*/}
        {order.ordersStamp.map(orderStamp => (
          <tr key={orderStamp.id}>
            <td>{orderStamp.periodAppConfig.period.year}</td>
            <td>
              {/*@ts-ignore*/}
              <StampTypeLabel type={orderStamp.type} />
            </td>
            <td>
              <ClientNameLink client={orderStamp.client} />
            </td>
            <td className="text-right">
              <MoneyLabel amount={orderStamp.priceOrig} />
            </td>
            <td className="text-right">
              <MoneyLabel amount={orderStamp.priceDiscounted} />
            </td>
            <td />
          </tr>
        ))}

        {/* orders token */}
        {order.ordersToken.map(orderToken => (
          <tr key={orderToken.id}>
            <td>
              <PeriodLongLabel period={orderToken.token.period} />
            </td>
            <td>
              <TokenNameLink token={orderToken.token} />
            </td>
            <td>
              <ClientNameLink client={orderToken.client} />
            </td>
            <td className="text-right">
              <MoneyLabel amount={orderToken.price} />
            </td>
            <td className="text-right">
              <MoneyLabel amount={orderToken.price} />
            </td>
            <td className="text-right" />
          </tr>
        ))}

        {/*orders discipline*/}
        {order.ordersDiscipline.map(orderDiscipline => (
          <tr key={orderDiscipline.id}>
            <td>
              <PeriodLongLabel period={orderDiscipline.disciplineConfig.period} />
            </td>
            <td>
              <DisciplineNameLink discipline={orderDiscipline.disciplineConfig.discipline} />
            </td>
            <td>
              <ClientNameLink client={orderDiscipline.client} />
            </td>
            <td className="text-right">
              <MoneyLabel amount={orderDiscipline.priceOrig} />
            </td>
            <td className="text-right">
              <MoneyLabel amount={orderDiscipline.priceDiscounted} />
            </td>
            <td className="text-right">
              {orderDiscipline.covidAccount !== 0 && <MoneyLabel amount={orderDiscipline.covidAccount} signed={true} />}
            </td>
          </tr>
        ))}
      </tbody>
      <tfoot>
        <tr className="font-weight-bold">
          <td colSpan={4}>Celkem</td>
          <td className="text-right">
            <MoneyLabel amount={order.totalPriceDiscounted} />
          </td>
          <td />
        </tr>
      </tfoot>
    </Table>
  );
}

export function textColorForStatus(status: OrderTotalStatusEnum) {
  const color = colorForStatus(status);
  return color && `text-${color}`;
}

function colorForStatus(status: OrderTotalStatusEnum) {
  switch (status) {
    case 'UNPAID':
    case 'PAYING':
      return '';
    case 'PAID':
      return 'success';
    case 'REFUNDING':
    case 'REFUNDED':
      return 'warning';
    case 'EXPIRED':
      return 'danger';
    default:
  }
}

interface PaymentCashProps {
  price: number;
  orderId: string;
  refetchQueries: RefetchQueriesFunction;
}

function PaymentCash({ price, orderId, refetchQueries }: PaymentCashProps) {
  const { modalProps, openModal } = useOrderTransactionCashNewModal(orderId, refetchQueries);

  return (
    <>
      <Button color="primary" onClick={openModal}>
        Zaplatit <MoneyLabel amount={price} /> hotově
      </Button>
      <OrderTransactionCashNewModal {...modalProps} price={price} key={orderId} />
    </>
  );
}

interface PaymentProps {
  order: Order;
  refetchQueries: RefetchQueriesFunction;
}

function Payment({ order, refetchQueries }: PaymentProps) {
  const { status, totalPriceDiscounted, validBefore } = order;
  if (status === 'UNPAID' || status === 'PAYING') {
    return (
      <>
        <Row>
          <Col sm={12}>
            <h4>Platba</h4>
          </Col>
          <Col sm={12} className="mb-1">
            Na zaplacení máte <DateTimeCountDownLabel dateTimeString={validBefore} finishedLabel="Čas vypršel" />
          </Col>
          <Col sm={12}>
            <PaymentCash price={totalPriceDiscounted} orderId={order.id} refetchQueries={refetchQueries} />
          </Col>
        </Row>

        <hr />
      </>
    );
  }
  return null;
}

interface OrderStatusProps {
  status: OrderTotalStatusEnum;
}

function OrderStatus({ status }: OrderStatusProps) {
  const color = colorForStatus(status);

  switch (status) {
    case 'PAID':
      return <Alert color={color}>Objednávka byla zaplacena</Alert>;
    case 'REFUNDING':
      return <Alert color={color}>Objednávka byla stornována a peníze jsou na cestě zpět na Váš účet.</Alert>;
    case 'REFUNDED':
      return <Alert color={color}>Objednávka byla stornována a peníze již máte zpět.</Alert>;
    case 'EXPIRED':
      return <Alert color={color}>Čas na zaplacení vypršel</Alert>;
    case 'UNPAID':
    case 'PAYING':
    default:
      return null;
  }
}

interface OrderDetailViewProps {
  order: Order;
}

export default function OrderDetailView({ order }: OrderDetailViewProps) {
  const expired = order.status === 'EXPIRED';

  function refetchQueries() {
    return [
      {
        query: OrderDocument,
        variables: { orderNumber: order.orderNumber }
      },
      ...order.ordersDiscipline.map(od => ({
        query: ClientDocument,
        variables: {
          id: od.client.id
        }
      })),
      ...order.ordersStamp.map(os => ({
        query: ClientDocument,
        variables: {
          id: os.client.id
        }
      })),
      ...order.ordersDiscipline.map(od => ({
        query: DisciplineConfigDocument,
        variables: {
          id: od.disciplineConfig.id
        }
      })),
      ...order.ordersToken.flatMap(ot => [
        {
          query: ClientDocument,
          variables: {
            id: ot.client.id
          }
        },
        {
          query: TokenDocument,
          variables: {
            id: ot.token.id
          }
        }
      ]),
      // filterable list
      'orders'
    ];
  }

  return (
    <>
      <Container>
        {/* header with name and New icon */}
        <Row>
          <Col sm="auto">
            <h2>Objednávka č. {order.orderNumber}</h2>
          </Col>
        </Row>

        <Row>
          <Col sm={12}>
            <OrderStatus status={order.status} />
          </Col>
        </Row>

        <InfoRow label="Vytvořeno">
          <DateTimeLabel dateTimeString={order.created} /> (<PersonSwitchNameLink person={order.personFrom} />)
        </InfoRow>

        <InfoRow label="Platnost do">
          <DateTimeLabel
            dateTimeString={order.validBefore}
            className={`font-weight-bold ${expired ? 'text-danger' : ''}`}
          />
        </InfoRow>

        <Row>
          <Col sm={12} className="mt-3">
            <OrdersTable order={order} />
          </Col>
        </Row>

        <hr />

        <Payment order={order} refetchQueries={refetchQueries} />

        <Row>
          <Col sm={12}>
            <h4>Transakce</h4>
          </Col>
          <Col sm={12}>
            <OrderTransactionsList
              transactionsCsob={order.gatewayTransactionsCsob}
              transactionsCash={order.transactionsCash}
              refetchQueries={refetchQueries}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
}
