import React from "react";

interface DateLabelProps {
  dateString: string;
}

const formatDateFromString = function (dateString: string) {
  return formatDateFromDate(new Date(dateString));
};

export const formatDateFromDate = function (date: Date) {
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  return `${day}.${month}.${year}`;
};

export default function DateLabel({ dateString }: DateLabelProps) {
  return <span>{formatDateFromString(dateString)}</span>;
}
