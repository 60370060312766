import React from 'react';
import { match, Route } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import ClientDetail from '../component/client/ClientDetail';
import ClientFilterableList from '../component/client/ClientFilterableList';

interface ClientsPageProps {
  match: match;
}

export default function ClientsPage({ match }: ClientsPageProps) {
  return (
    <Container>
      <Row>
        <Col xs={3}>
          <div className="position-fixed-scrollable">
            <Route
              path={`${match.path}/:clientId?`}
              render={props => <ClientFilterableList clientId={props.match.params.clientId} url={match.url} />}
            />
          </div>
        </Col>

        <Col xs={9}>
          <Route path={`${match.path}/:clientId`} render={props => <ClientDetail match={props.match} />} />
          <Route exact path={match.path} render={props => <ClientDetail match={props.match} />} />
        </Col>
      </Row>
    </Container>
  );
}
