import React, { Component } from "react";
import { Input, InputProps } from "reactstrap";

interface IndexSelectInputProps<O> extends InputProps {
  options: O[];
  value?: string | number;
  option2label?: (option: O) => string;
}

export default class IndexSelectInput<O> extends Component<
  IndexSelectInputProps<O>
> {
  render() {
    const { options, option2label = (o) => o, ...rest } = this.props;
    return (
      <Input {...rest} type="select">
        <option disabled={true} value="">
          Vyberte
        </option>
        {options.map((option, idx) => (
          <option key={idx} value={idx}>
            {option2label(option)}
          </option>
        ))}
      </Input>
    );
  }
}
