import { useField } from "formik";
import React, { ChangeEvent, useCallback, useRef, useState } from "react";
import {
  Button,
  FormFeedback,
  Input,
  InputGroup,
  InputGroupAddon,
} from "reactstrap";
import { IconRegularFolderOpen } from "../Icons";

interface FormikFileUploadProps {
  name: string;
  disabled?: boolean;
  onChange?: (file: File) => void;
}

/**
 *
 * https://github.com/formium/formik/issues/926
 * https://codesandbox.io/s/4wrrx8qok0?file=/src/components/CustomImageInput.js
 */
export default function FormikFileUpload({
  name,
  disabled = false,
  onChange,
}: FormikFileUploadProps) {
  const type = "file";

  const [field, meta, { setValue }] = useField({ name, type });

  const file = field.value;

  const [imagePreviewUrl, setImagePreviewUrl] = useState<
    string | ArrayBuffer | null | undefined
  >(undefined);

  const inputRef = useRef<HTMLInputElement>();

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();

      const files = e.currentTarget.files;

      if (files) {
        const file = files[0];

        // console.warn(file);
        // lastModified: 1601566918000
        // lastModifiedDate: Thu Oct 01 2020 17:41:58 GMT+0200 (Středoevropský letní čas) {}
        // name: "1704612220.275.txt"
        // size: 20952
        // type: "text/plain"
        // webkitRelativePath: ""

        const reader = new FileReader();

        reader.onloadend = () => {
          setImagePreviewUrl(reader.result);
        };
        reader.readAsDataURL(file);
        setValue(file);
        onChange && onChange(file);
      }
    },
    [setImagePreviewUrl, setValue, onChange]
  );

  const onSelect = useCallback(
    (e) => {
      e.preventDefault();

      if (inputRef.current) {
        inputRef.current.click();
      }
    },
    [inputRef]
  );

  const error = meta.touched && meta.error;
  return (
    <InputGroup>
      {/* Uncontrolled input - browser does not allow to set the value for security reasons */}
      <Input
        /*@ts-ignore*/
        innerRef={inputRef}
        hidden
        // name={field.name}
        // onBlur={field.onBlur}
        onChange={handleChange}
        // id={name}
        type={type}
        disabled={disabled}
        invalid={!!error}
      />

      <Input
        readOnly
        value={file ? file.name : "Vyberte soubor"}
        onClick={onSelect}
      />

      <InputGroupAddon addonType="append">
        <Button onClick={onSelect}>
          <IconRegularFolderOpen size="lg" />
        </Button>
      </InputGroupAddon>

      {error && <FormFeedback>{error}</FormFeedback>}
    </InputGroup>
  );
}
