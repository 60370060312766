import { Query } from '@apollo/client/react/components';
import DateTimeLabel from '@monorepo/common/component/DateTimeLabel';
import FilterableList from '@monorepo/common/component/FilterableList';
import MoneyLabel from '@monorepo/common/component/MoneyLabel';
import React, { useState } from 'react';
import { OrdersDocument, OrdersQuery, OrdersQueryVariables, OrderTotalStatusEnum } from '../../graphql-operations';
import { textColorForStatus } from './OrderDetailView';

interface OrderRowProps {
  order: {
    status: OrderTotalStatusEnum;
    orderNumber: string;
    created: string;
    totalPriceDiscounted: number;
  };
}

function OrderRow({ order }: OrderRowProps) {
  const color = textColorForStatus(order.status);

  return (
    <span className={color}>
      {order.orderNumber} | <DateTimeLabel dateTimeString={order.created} /> |{' '}
      <MoneyLabel amount={order.totalPriceDiscounted} />
    </span>
  );
}

interface OrderFilterableListProps {
  orderNumber: string;
  url: string;
}

export default function OrderFilterableList({ orderNumber, url }: OrderFilterableListProps) {
  const [filter, setFilter] = useState('');
  return (
    <Query<OrdersQuery, OrdersQueryVariables> query={OrdersDocument} variables={{ filter }}>
      {({ data, loading, error, fetchMore }) => {
        const orders = (data || {}).orders;

        return (
          <FilterableList
            items={orders?.items}
            loading={loading}
            error={error}
            selectedId={orderNumber}
            idExtractor={order => order.orderNumber}
            urlExtractor={order => `${url}/${order.orderNumber}`}
            filter={filter}
            hasNext={!!orders?.next}
            onFilterChange={setFilter}
            onMoreClick={() => {
              fetchMore({
                variables: {
                  page: orders?.next?.page,
                  pageSize: orders?.next?.size
                },
                updateQuery: (prev, { fetchMoreResult }) => {
                  if (!fetchMoreResult) {
                    return prev;
                  }
                  return {
                    ...fetchMoreResult,
                    orders: {
                      ...fetchMoreResult.orders,
                      items: [...prev.orders.items, ...fetchMoreResult.orders.items]
                    }
                  };
                }
              });
            }}
          >
            {order => <OrderRow order={order} />}
          </FilterableList>
        );
      }}
    </Query>
  );
}
