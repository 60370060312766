import { Query } from '@apollo/client/react/components';
import FilterableList from '@monorepo/common/component/FilterableList';
import React, { useState } from 'react';
import { DisciplineListDocument, DisciplineListQuery, DisciplineListQueryVariables } from '../../graphql-operations';

interface DisciplineFilterableListProps {
  disciplineId: string;
  url: string;
}

export default function DisciplineFilterableList({ disciplineId, url }: DisciplineFilterableListProps) {
  const [filter, setFilter] = useState('');
  return (
    <Query<DisciplineListQuery, DisciplineListQueryVariables> query={DisciplineListDocument} variables={{ filter }}>
      {({ data, loading, error, fetchMore }) => {
        const disciplines = (data || {}).disciplines;

        return (
          <FilterableList
            items={disciplines && disciplines.items}
            loading={loading}
            error={error}
            selectedId={disciplineId}
            idExtractor={discipline => discipline.id}
            urlExtractor={discipline => `${url}/${discipline.id}`}
            filter={filter}
            hasNext={!!disciplines?.next}
            // match={match}
            onFilterChange={setFilter}
            onMoreClick={() =>
              disciplines?.next &&
              fetchMore({
                variables: {
                  page: disciplines.next.page,
                  pageSize: disciplines.next.size
                },
                updateQuery: (prev, { fetchMoreResult, ...rest }) => {
                  if (!fetchMoreResult) {
                    return prev;
                  }
                  return {
                    ...fetchMoreResult,
                    disciplines: {
                      ...fetchMoreResult.disciplines,
                      items: [...prev.disciplines.items, ...fetchMoreResult.disciplines.items]
                    }
                  };
                }
              })
            }
          >
            {discipline => <>{discipline.name}</>}
          </FilterableList>
        );
      }}
    </Query>
  );
}
