import React from "react";
import { Col, Container, Row } from "reactstrap";

interface LoginLayoutProps {
  children: React.ReactNode;
}

export default function LoginLayout({ children }: LoginLayoutProps) {
  return (
    <Container>
      <Row>
        <Col sm="12" md={{ size: 6, offset: 3 }} lg={{ size: 4, offset: 4 }}>
          {children}
        </Col>
      </Row>
    </Container>
  );
}
