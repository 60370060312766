import ClientNameLabel from '@monorepo/common/component/client/ClientNameLabel';
import DeleteModal from '@monorepo/common/component/modal/DeleteModal';
import { ChildrenRenderProp } from '@monorepo/common/component/modal/EditModal';
import { PersonDataType } from '@monorepo/common/component/person/PersonNameLabel';
import React, { useCallback } from 'react';
import { DeleteDisciplineTrainerDocument, DisciplineConfigDocument, SokolRoleEnum } from '../../../graphql-operations';
import SokolRoleLabel from '../../sokolRole/SokolRoleLabel';

function data2text(trainer: TrainerType) {
  return (
    <>
      <div>
        Člen: <ClientNameLabel client={trainer.client} />
      </div>
      <div>
        Role: <SokolRoleLabel role={trainer.role} />
      </div>
    </>
  );
}

function data2variables(trainer: TrainerType) {
  return {
    id: trainer.id
  };
}

interface TrainerType {
  id: string;
  client: {
    personData: PersonDataType;
  };
  role: SokolRoleEnum;
}

interface DisciplineTrainerDetailDeleteModalProps {
  disciplineConfigId: string;
  trainer: TrainerType;
  children: ChildrenRenderProp;
}

export default function DisciplineTrainerDetailDeleteModal({
  trainer,
  disciplineConfigId,
  children
}: DisciplineTrainerDetailDeleteModalProps) {
  const refetchQueries = useCallback(() => {
    return [
      {
        query: DisciplineConfigDocument,
        variables: {
          id: disciplineConfigId
        }
      }
    ];
  }, [disciplineConfigId]);

  return (
    <DeleteModal
      title="Smazání role oddílu"
      data={trainer}
      data2text={data2text}
      data2variables={data2variables}
      mutation={DeleteDisciplineTrainerDocument}
      refetchQueries={refetchQueries}
      children={children}
    />
  );
}
