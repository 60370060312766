import React from "react";
import LoginLayout from "../layout/LoginLayout";

export default function MaintenancePage() {
  return (
    <LoginLayout>
      <div className="text-center">
        Právě probíhá údržba systému, vraťte se prosím později.
      </div>
    </LoginLayout>
  );
}
