import { DocumentNode } from "graphql";
import React, {
  FocusEventHandler,
  FormEventHandler,
  MouseEventHandler,
  ReactNode,
  useCallback,
  useState,
} from "react";
import {
  Badge,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  InputProps,
} from "reactstrap";
import { preventedDefault } from "../../util/Input";
import { IconSolidTimes } from "../Icons";
import QueryWithLoader from "../QueryWithLoader";
import { BadgeLeft, BadgeRight } from "../Style";

function ChildLessInput({ children, ...rest }: InputProps) {
  return <Input {...rest} />;
}

interface IdItem {
  id: string;
}

interface Item {
  id: string;
  name: string;
}

export interface AutocompleteInputProps<T, QR, QV>
  extends IdNameAutocompleteInputProps<T, QR, QV> {
  item2id?: (item: T) => string;
  item2nameSelected: (item: T) => ReactNode;
  item2nameDropdown: (item: T) => ReactNode;
}

export interface IdNameAutocompleteInputProps<T, QR, QV> {
  placeholder: string;
  query: DocumentNode;
  queryVariables: QV;
  selected?: T | null;
  disabled?: boolean;
  onSelect: (item: T | undefined) => void;
  onBlur: FocusEventHandler<HTMLElement>;
  result2items: (data: QR) => T[];
}

export function IdNameAutocompleteInput<T extends Item, QR, QV>(
  props: IdNameAutocompleteInputProps<T, QR, QV>
) {
  return (
    <AutocompleteInput<T, QR, QV>
      {...props}
      item2nameSelected={(i) => i.name}
      item2nameDropdown={(i) => i.name}
    />
  );
}

export default function AutocompleteInput<T extends IdItem, QR, QV>({
  placeholder,
  query,
  queryVariables,
  selected,
  disabled = false,
  onSelect,
  onBlur,
  item2id = (i) => i.id,
  item2nameSelected,
  item2nameDropdown,
  result2items,
}: AutocompleteInputProps<T, QR, QV>) {
  const [filter, setFilter] = useState("");
  const [open, setOpen] = useState(false);

  const handleToggle: MouseEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      if (e.currentTarget.type === "button") {
        setOpen((prevOpen) => !prevOpen);
      }
    },
    [setOpen]
  );

  const handleFilterChange: FormEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      setFilter(e.currentTarget.value);
      setOpen(true);
      onSelect && onSelect(undefined);
    },
    [setFilter, setOpen, onSelect]
  );

  const handleDelete = useCallback(() => {
    setFilter("");
    setOpen(false);
    onSelect && onSelect(undefined);
  }, [setFilter, setOpen, onSelect]);

  const handleOnSelect = useCallback(
    (item: T) => {
      onSelect && onSelect(item);
      setFilter("");
    },
    [setFilter, onSelect]
  );

  if (selected) {
    return (
      <h2>
        <Badge style={BadgeLeft}>{item2nameSelected(selected)}</Badge>
        {!disabled && (
          <Badge
            style={BadgeRight}
            href="#"
            onClick={preventedDefault(handleDelete)}
          >
            <IconSolidTimes />
          </Badge>
        )}
      </h2>
    );
  }
  return (
    <Dropdown isOpen={open} toggle={handleToggle}>
      <DropdownToggle
        tag={ChildLessInput}
        className="selected"
        type="text"
        disabled={disabled}
        placeholder={placeholder}
        value={filter}
        onChange={handleFilterChange}
        onBlur={onBlur}
      />

      {filter !== "" && (
        <DropdownMenu>
          <QueryWithLoader<QR, QV>
            query={query}
            variables={{
              ...queryVariables,
              filter,
              page: 0,
              pageSize: 5,
              emptyForEmptyFilter: true,
            }}
          >
            {({ data }) => {
              const items = result2items(data);
              if (items.length) {
                return (
                  <>
                    {items.map((item) => (
                      <DropdownItem
                        key={item2id(item)}
                        onClick={() => handleOnSelect(item)}
                      >
                        {item2nameDropdown(item)}
                      </DropdownItem>
                    ))}
                  </>
                );
              } else if (filter !== "") {
                return (
                  <>
                    <DropdownItem disabled={true}>Nic nenalezeno</DropdownItem>
                  </>
                );
              }
              return null;
            }}
          </QueryWithLoader>
        </DropdownMenu>
      )}
    </Dropdown>
  );
}
