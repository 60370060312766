import DeleteModal from '@monorepo/common/component/modal/DeleteModal';
import { ChildrenRenderProp } from '@monorepo/common/component/modal/EditModal';
import React from 'react';
import { AccompanistFragment, ClientDocument, DeleteClientAccompanistDocument } from '../../graphql-operations';
import AccompanistNameLabel from './AccompanistNameLabel';

function data2text(accompanist: AccompanistFragment) {
  return (
    <>
      <div>
        Doprovod: <AccompanistNameLabel accompanist={accompanist} />
      </div>
      <div>Role: {accompanist.role}</div>
    </>
  );
}

function data2variables(accompanist: AccompanistFragment) {
  return {
    id: accompanist.id
  };
}
function refetchQueries({ child, accompanist }: AccompanistFragment) {
  const childQuery = {
    query: ClientDocument,
    variables: { id: child.id }
  };

  const accompanistQuery = accompanist
    ? {
        query: ClientDocument,
        variables: { id: accompanist.id }
      }
    : undefined;

  return accompanistQuery ? [childQuery, accompanistQuery] : [childQuery];
}

interface ClientAccompanistDetailDeleteModalProps {
  accompanist: AccompanistFragment;
  children: ChildrenRenderProp;
}

export default function ClientAccompanistDetailDeleteModal({
  accompanist,
  children
}: ClientAccompanistDetailDeleteModalProps) {
  return (
    <DeleteModal
      title="Smazání doprovodu dítěte"
      data={accompanist}
      data2text={data2text}
      data2variables={data2variables}
      mutation={DeleteClientAccompanistDocument}
      refetchQueries={refetchQueries}
      children={children}
    />
  );
}
