import CommonButtonGroup from '@monorepo/common/component/form/CommonButtonGroup';
import ValidatedInputGroup from '@monorepo/common/component/form/ValidatedInputGroup';
import { EditFormProps, Validator } from '@monorepo/common/component/modal/EditModal';
import PeriodLabel from '@monorepo/common/component/PeriodLabel';
import { integerNormalizer2 } from '@monorepo/common/util/Normalizer';
import { validateAnyField, validateStringField } from '@monorepo/common/util/Validation';
import React from 'react';
import { Col, Form, FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import { Period, SokolRoleEnum } from '../../../graphql-operations';
import ClientAutocompleteInput, { ClientType } from '../../client/ClientAutocompleteInput';

export const validate: Validator<EditFormData> = (data, touched) => {
  const errors = {};
  validateAnyField('period', 'Období', data, errors, touched, { required: true });

  // price is mandatory only for new records
  if (!data.id) {
    validateStringField('price', 'Cena', data, errors, touched, { required: true, number: { range: { min: 0 } } });
  }

  validateStringField('capacity', 'Kapacita', data, errors, touched, { required: true, number: { range: { min: 0 } } });
  return errors;
};

export interface EditFormData {
  id: string;
  disciplineId: string;
  periods: Period[];
  period: Period;
  leader: ClientType;
  price: number;
  capacity: number;
  birthDateFrom?: string;
  birthDateTo?: string;
}

interface DisciplineConfigDetailEditProps extends EditFormProps<EditFormData> {}

export default function DisciplineConfigDetailEdit({
  validation,
  onPropTouch,
  onPropChange,
  periods,
  ...disciplineConfig
}: DisciplineConfigDetailEditProps) {
  const { id, period, leader } = disciplineConfig;
  return (
    <Form>
      {/*Period
       */}
      {!id && (
        <FormGroup row>
          <Label sm={12} md={3}>
            Období
          </Label>
          <Col sm={12} md={9} className="text-md-right">
            <CommonButtonGroup
              items={periods}
              selectedItem={period}
              item2label={p => <PeriodLabel period={p} />}
              item2id={p => p.id}
              error={validation.errors.period}
              onChange={p => onPropChange('period', p)}
              onBlur={() => onPropTouch('period')}
            />
          </Col>
        </FormGroup>
      )}
      {id && period && (
        <FormGroup row>
          <Col sm={12} md={3}>
            Období
          </Col>
          <Col sm={12} md={9} className="text-md-right">
            <PeriodLabel period={period} />
          </Col>
        </FormGroup>
      )}

      {/* Leader */}
      <FormGroup row>
        <Label sm={12} md={3}>
          Vedoucí
        </Label>
        <Col sm={12} md={9}>
          <ClientAutocompleteInput
            selected={leader}
            sokolRole={SokolRoleEnum.Trainer}
            disabled={!period}
            periodId={period?.id}
            onSelect={d => onPropChange('leader', d)}
            onBlur={() => onPropTouch('leader')}
          />
          <Input type="hidden" invalid={!!validation.errors.leader} />
          {validation.errors.leader && <FormFeedback>{validation.errors.leader}</FormFeedback>}
        </Col>
      </FormGroup>

      {/* Price - only for new records */}
      {!id && (
        <FormGroup row>
          <Label sm={12} md={3}>
            Cena
          </Label>
          <Col sm={12} md={9} className="text-md-right">
            <ValidatedInputGroup
              property="price"
              data={disciplineConfig}
              placeholder="Zadejte cenu"
              currency="Kč"
              normalizer={integerNormalizer2}
              validation={validation}
              onPropChange={onPropChange}
              onPropTouch={onPropTouch}
            />
          </Col>
        </FormGroup>
      )}

      {/*Kapacita*/}
      <FormGroup row>
        <Label sm={12} md={3}>
          Kapacita
        </Label>
        <Col sm={12} md={9} className="text-md-right">
          <ValidatedInputGroup
            property="capacity"
            data={disciplineConfig}
            placeholder="Zadejte kapacitu"
            normalizer={integerNormalizer2}
            alignRight={true}
            validation={validation}
            onPropChange={onPropChange}
            onPropTouch={onPropTouch}
          />
        </Col>
      </FormGroup>

      {/* Datum narozeni od*/}
      <FormGroup row>
        <Label sm={12} md={3}>
          Datum narození od
        </Label>
        <Col sm={12} md={9} className="text-md-right">
          <ValidatedInputGroup
            property="birthDateFrom"
            data={disciplineConfig}
            placeholder="Zadejte datum narození od"
            type="date"
            validation={validation}
            onPropChange={onPropChange}
            onPropTouch={onPropTouch}
          />
        </Col>
      </FormGroup>

      {/* Datum narozeni do*/}
      <FormGroup row>
        <Label sm={12} md={3}>
          Datum narození do
        </Label>
        <Col sm={12} md={9} className="text-md-right">
          <ValidatedInputGroup
            property="birthDateTo"
            data={disciplineConfig}
            placeholder="Zadejte datum narození do"
            type="date"
            validation={validation}
            onPropChange={onPropChange}
            onPropTouch={onPropTouch}
          />
        </Col>
      </FormGroup>
    </Form>
  );
}
